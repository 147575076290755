import React from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import styles from "./DaysGraph.module.scss";

const colors = ["#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600"];

const DaysGraph = ({ data, selectedBusinessUnits, variant = "bar", type }) => {
  const compare = (a, b) => {
    if (a.begin < b.begin) {
      return -1;
    }
    if (a.begin > b.begin) {
      return 1;
    }
    return 0;
  };
  console.log("DaysGraph ", data.array?.sort(compare));

  const renderAreas = () => {
    let totalKey = "";
    let taxKey = "";
    data.array?.sort(compare);
    return data?.businessUnitsNames?.map((name, index) => {
      totalKey = name + " promet";
      taxKey = name + " porez";

      const buSelected = selectedBusinessUnits.find((bu) => bu === name)
        ? true
        : false;
      if (buSelected)
        return (
          <>
            <Area
              type="monotone"
              dataKey={totalKey}
              dot={false}
              stroke={colors[index % 6]}
              key={totalKey}
              fillOpacity={1}
              strokeWidth={2.5}
              fill={"url(#colorUv" + (index % 5) + ")"}
            />
          </>
        );
      else return null;
    });
  };

  const renderBars = () => {
    let totalKey = "";
    let taxKey = "";
    return data?.businessUnitsNames?.map((name, index) => {
      totalKey = name + " promet";
      taxKey = name + " porez";

      const buSelected = selectedBusinessUnits.find((bu) => bu === name)
        ? true
        : false;
      if (buSelected)
        return (
          <>
            <Bar
              key={totalKey}
              dataKey={totalKey}
              stackId="a"
              radius={15}
              fill={"url(#colorUv" + (index % 5) + ")"}
            />
          </>
        );
      else return null;
    });
  };
  if (variant === "bar") {
    return (
      <>
        <div className={styles.graphDesktop}>
          <ResponsiveContainer
            width={"100%"}
            height="60%"
            minWidth={"100%"}
            minHeight={"100%"}
          >
            <BarChart
              width={1700}
              height={400}
              data={data.array}
              margin={{
                top: 0,
                right: 5,
                left: 5,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv0" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#2C73DE" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#2C73DE" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#58508d" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#58508d" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#bc5090" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#bc5090" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ff6361" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#ff6361" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ffa600" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#ffa600" stopOpacity={0.3} />
                </linearGradient>
              </defs>
              <XAxis dataKey={type === "hours" ? "hour" : "date"} />
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(value)
                }
              />
              <Tooltip
                formatter={(value) => new Intl.NumberFormat("en").format(value)}
              />
              {data && renderBars()}
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.graphSm}>
          <ResponsiveContainer
            width={"100%"}
            height="80%"
            minWidth={"100%"}
            minHeight={"100%"}
          >
            <BarChart
              width={300}
              height={200}
              data={data.array}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv0" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#2C73DE" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#2C73DE" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#58508d" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#58508d" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#bc5090" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#bc5090" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ff6361" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#ff6361" stopOpacity={0.3} />
                </linearGradient>
                <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ffa600" stopOpacity={0.8} />
                  <stop offset="98%" stopColor="#ffa600" stopOpacity={0.3} />
                </linearGradient>
              </defs>
              <XAxis dataKey={type === "hours" ? "hour" : "date"} />
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(value)
                }
              />
              {/* <Tooltip /> */}
              {data && renderBars()}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  } else
    return (
      <>
        <div className={styles.graphDesktop}>
          <ResponsiveContainer
            width={"100%"}
            height="80%"
            minWidth={"100%"}
            minHeight={"100%"}
          >
            <AreaChart
              width={300}
              height={200}
              data={data.array}
              margin={{
                top: 0,
                right: 5,
                left: 5,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv0" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#2C73DE" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#58508d" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#bc5090" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ff6361" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ffa600" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis dataKey={type === "hours" ? "hour" : "date"} />
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(value)
                }
              />
              <Tooltip />
              {data && renderAreas()}
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.graphSm}>
          <ResponsiveContainer
            width={"100%"}
            height="80%"
            minWidth={"100%"}
            minHeight={"100%"}
          >
            <AreaChart
              width={300}
              height={200}
              data={data.array}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv0" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#2C73DE" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#58508d" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#bc5090" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ff6361" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="2%" stopColor="#ffa600" stopOpacity={0.4} />
                  <stop offset="98%" stopColor="#ffffff" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis dataKey={type === "hours" ? "hour" : "date"} />
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(value)
                }
              />
              <Tooltip />
              {data && renderAreas()}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </>
    );
};

export default DaysGraph;
