import React, { useEffect, useState } from "react";
import styles from "./LoginScreen.module.scss";
import { LoginScreenSvg } from "../../assets";
import classNames from "classnames";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo, login, loginInfoReset } from "../../store/actions/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { isValidEmail } from "../../util/validations";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const { attempt, success, error } = authSelector.logInInfo || [];

  const userInfo = useSelector((state) => state.auth.user);

  const validateInput = () => {
    if (email === "") {
      toast.warning("Email cannot be empty");
      return false;
    } else if (!isValidEmail(email)) {
      toast.warning("Email wrong formatted");
      return false;
    }
    if (password === "") {
      toast.warning("Password cannot be empty!");
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const valid = validateInput();
    if (!valid) return;
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (success) {
      dispatch(fetchUserInfo());
    }
  }, [success, history, state, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(loginInfoReset());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success && userInfo.role) {
      const initialRoute =
        userInfo.role === "ADMIN" ? "/taxpayers" : "/dashboard";
      history.push(state?.from.pathname ? state?.from.pathname : initialRoute);
      dispatch(loginInfoReset());
      return <Redirect to={state?.from || "/"} />;
    }
  }, [success, history, userInfo, state, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.mobileContentWrap}>
        <div className={styles.formWrap}>
          <p className={styles.signInTitle}>Prijavite se</p>
          <div className={styles.inputWrapper}>
            <p>Email</p>
            <Input onChange={setEmail} value={email} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Password</p>
            <Input onChange={setPassword} type="password" value={password} />
          </div>
          <div className={classNames(styles.marginBottom, styles.fullWidth)}>
            <Button
              name={attempt ? "Prijavljivanje" : "Prijavite se"}
              onClick={onSubmit}
              showLoader={attempt}
              disabled={attempt}
              fullWidth={true}
            />
          </div>
          <div className={styles.fullWidth}>
            <p
              className={styles.forgotPassword}
              onClick={() => history.push("/forgot-password/email")}
            >
              Zaboravili ste lozniku?
            </p>
          </div>
          <div className={styles.fullWidth}>
            <p
              className={styles.forgotPassword}
              onClick={() => history.push("/register")}
            >
              Nemate nalog? Registrujte se.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.gridHalves}>
        <div className={classNames(styles.contentPart, styles.bgPrimary)}>
          <img src={LoginScreenSvg} alt="login" className={styles.image} />
        </div>
        <div className={styles.contentPart}>
          <div className={styles.formWrap}>
            <p className={styles.signInTitle}>Prijavite se</p>
            <div className={styles.inputWrapper}>
              <p>Email</p>
              <Input onChange={setEmail} value={email} />
            </div>
            <div className={styles.inputWrapper}>
              <p>Password</p>
              <Input onChange={setPassword} type="password" value={password} />
            </div>

            <div className={classNames(styles.fullWidth, styles.marginBottom)}>
              <Button
                name={attempt ? "Prijavljivanje" : "Prijavite se"}
                onClick={onSubmit}
                showLoader={attempt}
                disabled={attempt}
                fullWidth
              />
            </div>
            <div className={classNames(styles.fullWidth)}>
              <p
                className={styles.forgotPassword}
                onClick={() => history.push("/forgot-password/email")}
              >
                Zaboravili ste lozniku?
              </p>
            </div>
            <div className={classNames(styles.fullWidth)}>
              <p
                className={styles.forgotPassword}
                onClick={() => history.push("/register")}
              >
                Nemate nalog? Registrujte se.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
