import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { GET_ALL_TAX_LABELS, ADD_TAX_LABEL } from "../../urls";

class TaxLabelService {
  fetchTaxLabels = async () => {
    try {
      const resposne = await apiBase.get(GET_ALL_TAX_LABELS);
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching tax labels");
    }
  };

  addTaxLabel = async (taxLabel) => {
    try {
      const resposne = await apiBase.post(ADD_TAX_LABEL, taxLabel);
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error creating tax label");
    }
  };

  deleteTaxRate = async (id) => {
    try {
      const resposne = await apiBase.delete(ADD_TAX_LABEL + "/" + id);
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error deleting tax label");
    }
  };
}

export default new TaxLabelService();
