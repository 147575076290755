import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import styles from "./Analytics.module.scss";
import AsyncSelect from "react-select/async";
import fiscalDeviceService from "../../../apis/services/admin/fiscalDeviceService";
import _ from "lodash";
import analyticsService from "../../../apis/services/admin/analyticsService";
import moment from "moment";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ThreeDots } from "svg-loaders-react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

const Analytics = () => {
  const { id } = useParams();

  const minDate = moment()
    .tz("Europe/Belgrade")
    .set("year", 2022)
    .set("month", 3)
    .set("date", 1);
  const maxDate = moment().tz("Europe/Belgrade").add(1, "day");
  const [fiscaLDeviceOptions, setFiscalDeviceOptions] = useState([]);
  const [fiscalDevices, setFiscalDevices] = useState([]);
  const [date, setDate] = useState(new Date());
  const [fiscalDevice, setFiscalDevice] = useState(null);
  const [fiscalDeviceUptimeDownTime, setFiscalDeviceUptimeDowntime] =
    useState(null);
  const [busynessByHour, setBusynessByHour] = useState(null);
  const [totalInvoices, setTotalInvoices] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [busynessByHourAttempt, setBusynessByHourAttempt] = useState(false);
  const [
    vendingControllersUptimeDownTime,
    setVendingControllersUptimeDowntime,
  ] = useState(null);

  useEffect(() => {
    const fetchFiscalDevices = async () => {
      const response = await fiscalDeviceService.fetchFiscalDevices(1, 15, "");
      const { data } = response;
      setFiscalDevices(data?.data);
    };
    fetchFiscalDevices();
  }, []);

  useEffect(() => {
    const func = async () => {
      if (id) {
        const response = await fiscalDeviceService.fetchSignleFiscalDevice(id);
        const { data } = response;
        if (data) {
          setFiscalDevice({
            value: data?.fiscalDevice,
            label: data?.fiscalDevice?.name,
          });
        }
      }
    };
    func();
  }, [id]);

  const normalizeData = (data) => {
    return data.map((day) => {
      return {
        ...day,
        date: day.date,
        uptimeMinutes: Math.round(day.uptimeMilliseconds / (1000 * 60), 2),
        downtimeMinutes: Math.round(day.downtimeMilliseconds / (1000 * 60), 2),
      };
    });
  };

  useEffect(() => {
    const func = async () => {
      if (fiscalDevice) {
        const response = await analyticsService.getDeviceAnalytics(
          "FISCAL_DEVICE",
          moment().subtract(30, "days").tz("Europe/Belgrade").format("x"),
          moment().format("x"),
          fiscalDevice.value.id
        );
        const { data } = response;
        setFiscalDeviceUptimeDowntime(normalizeData(data.events));
        const vendingControllers = [];
        for (let vendingController of fiscalDevice.value.vendingControllers) {
          const { data } = await analyticsService.getDeviceAnalytics(
            "VENDING_CONTROLLER",
            moment().subtract(30, "days").tz("Europe/Belgrade").format("x"),
            moment().format("x"),
            vendingController.id
          );
          vendingControllers.push({
            entries: normalizeData(data.events),
            deviceName: vendingController.name,
          });
        }
        setVendingControllersUptimeDowntime(vendingControllers);
      }
    };
    func();
  }, [fiscalDevice]);

  useEffect(() => {
    const func = async () => {
      if (fiscalDevice) {
        setBusynessByHourAttempt(true);
        const response = await analyticsService.getBusynessReport(
          fiscalDevice.value.id,
          moment(new Date(date))
            .tz("Europe/Belgrade")
            .startOf("day")
            .format("x"),
          moment(new Date(date)).tz("Europe/Belgrade").endOf("day").format("x")
        );
        const { data } = response;
        setBusynessByHourAttempt(false);
        setBusynessByHour(data[0]?.hours);
        let total = 0;
        let totalAmt = 0;
        for (let hour of data[0]?.hours) {
          total += hour.invoicesAmount;
          totalAmt += hour.totalAmount;
        }
        setTotalInvoices(total);
        setTotalAmount(totalAmt);
      }
    };
    func();
  }, [fiscalDevice, date]);

  const onSearchFiscalDevices = _.debounce(async (searchTerm) => {
    const response = await fiscalDeviceService.fetchFiscalDevices(
      1,
      15,
      searchTerm
    );
    const { data } = response;
    const retItems = data?.data?.map((item) => {
      return {
        value: item,
        label: item.name,
      };
    });
    return retItems;
  }, 100);

  useEffect(() => {
    setFiscalDeviceOptions(
      fiscalDevices?.map((item) => {
        return {
          value: item,
          label: item.name,
        };
      })
    );
  }, [fiscalDevices]);

  const millisecondsToHuman = (duration) => {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = parseInt((duration / 1000) % 60),
      minutes = parseInt((duration / (1000 * 60)) % 60),
      hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const downtime = millisecondsToHuman(
        payload[1].payload.downtimeMilliseconds
      );

      const renderEndpoints = (endpoints) => {
        return endpoints.map((endpoint) => {
          return <p>{`${endpoint.offlineFrom} - ${endpoint.offlineTo}`}</p>;
        });
      };

      return (
        <div className={styles.customTooltip}>
          <p>{`Datum: ${label}`}</p>
          <p>{`Downtime: ${downtime}`}</p>
          {payload[1].payload.endpoints &&
            renderEndpoints(payload[1].payload.endpoints)}
        </div>
      );
    }

    return null;
  };

  const BusynessTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p>{`Sat: ${label}`}</p>
          <p>{`Broj računa: ${payload[0].payload.invoicesAmount}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderChart = (data) => {
    return (
      <>
        <div className={styles.graphWrap}>
          <ResponsiveContainer width={1200} height={300}>
            <BarChart
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <Tooltip content={CustomTooltip} />
              <XAxis dataKey="date" />
              <YAxis />
              <Bar dataKey="uptimeMinutes" stackId="a" fill="#94C973" />
              <Bar dataKey="downtimeMinutes" stackId="a" fill="#EE4B2B" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.graphSm}>
          <ResponsiveContainer
            width={"100%"}
            height="80%"
            minWidth={"100%"}
            minHeight={"100%"}
          >
            <BarChart
              data={data}
              width={500}
              height={300}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip content={CustomTooltip} />
              <XAxis dataKey="date" />
              <YAxis />
              <Bar dataKey="uptimeMinutes" stackId="a" fill="#94C973" />
              <Bar dataKey="downtimeMinutes" stackId="a" fill="#EE4B2B" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  };

  const renderBusynessChart = (data) => {
    return (
      <>
        <div
          className={classNames(
            styles.graphWrap,
            busynessByHourAttempt && styles.alignCenter
          )}
        >
          {busynessByHourAttempt ? (
            <ThreeDots fill="#cbc9c9" />
          ) : (
            <ResponsiveContainer width={1200} height={300}>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 5,
                  left: 0,
                  bottom: 5,
                }}
              >
                <Tooltip content={BusynessTooltip} />
                <XAxis dataKey="hour" />
                <YAxis />
                <Bar dataKey="invoicesAmount" stackId="a" fill="#94C973" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div
          className={classNames(
            styles.graphSm,
            busynessByHourAttempt && styles.alignCenterSm
          )}
        >
          {busynessByHourAttempt ? (
            <ThreeDots fill="#cbc9c9" />
          ) : (
            <ResponsiveContainer
              width={"100%"}
              height="80%"
              minWidth={"100%"}
              minHeight={"100%"}
            >
              <BarChart
                data={data}
                width={500}
                height={300}
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Tooltip content={BusynessTooltip} />
                <Tooltip />
                <XAxis dataKey="hour" />
                <YAxis />
                <Bar dataKey="invoicesAmount" stackId="a" fill="#94C973" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </>
    );
  };

  const renderDevices = (data) => {
    return data.map((d) => {
      return (
        <div className={styles.marginTop}>
          <p>{d.deviceName}</p>
          {renderChart(d.entries)}
        </div>
      );
    });
  };

  return (
    <DashboardLayout>
      <div className={styles.content}>
        <p className={styles.titleLg}>Analitika</p>
        <div className={styles.inputWrapperSm}>
          <p>Odaberi fiskalni uređaj</p>
          <AsyncSelect
            onInputChange={(e) => {
              onSearchFiscalDevices(e);
            }}
            loadOptions={onSearchFiscalDevices}
            defaultOptions={fiscaLDeviceOptions}
            onChange={(e) => {
              setFiscalDevice(e);
            }}
            value={fiscalDevice}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
              }),
              control: (provided) => ({
                ...provided,
                border: "1px solid #dbe2e6",
                height: "50px",
                borderRadius: "15px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid rgba(26, 90, 162, 1)",
                },
                "&:focus": {
                  border: "1px solid rgba(26, 90, 162, 1)",
                },
              }),
            }}
          />
        </div>

        {fiscalDevice && (
          <div className={classNames(styles.inputWrapperSm, styles.marginTop)}>
            <p>Odaberi datum</p>
            <input
              type="date"
              min={minDate.format("YYYY-MM-DD")}
              max={maxDate.format("YYYY-MM-DD")}
              className={styles.dateInput}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        )}
        <p className={styles.title}>
          {totalInvoices &&
            `Ukupno računa ${moment(date).format(
              "DD-MM-YYYY"
            )}: ${totalInvoices}`}
        </p>
        <p className={styles.title}>
          {totalInvoices &&
            `Ukupan promet ${moment(date).format(
              "DD-MM-YYYY"
            )}: ${totalAmount}`}
        </p>
        <div className={styles.graphs}>
          {busynessByHour && (
            <div>
              <p>Broj računa po satima</p>
              {renderBusynessChart(busynessByHour)}
            </div>
          )}
          {fiscalDeviceUptimeDownTime && (
            <div className={styles.marginTop}>
              <p>{fiscalDevice.value.name}</p>
              {renderChart(fiscalDeviceUptimeDownTime)}
            </div>
          )}
          {vendingControllersUptimeDownTime &&
            renderDevices(vendingControllersUptimeDownTime)}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Analytics;
