import fileDownload from "js-file-download";
import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import {
  DOWNLOAD_XLS,
  FETCH_FISCAL_DEVICES,
  FETCH_FISCAL_DEVICE_DOUBLE_INVOICES,
  FETCH_FISCAL_DEVICE_INVOICES,
  FETCH_SINGLE_FISCAL_DEVICE,
  SYNC_INVOICES,
  UPDATE_FISCAL_DEVICE,
} from "../../urls";

class FiscalDeviceService {
  fetchFiscalDevices = async (
    page,
    perPage,
    term = "",
    sortBy,
    order,
    filterOffline = false,
    filterNoRecentInvoices = false,
    offset
  ) => {
    try {
      const resposne = await apiBase.get(
        FETCH_FISCAL_DEVICES(
          page,
          perPage,
          term,
          sortBy,
          order,
          filterOffline,
          filterNoRecentInvoices,
          offset
        )
      );
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal devices");
    }
  };

  downloadXLs = async () => {
    try {
      const response = await apiBase.get(DOWNLOAD_XLS, {
        responseType: "blob",
      });
      const fileName = "Fiskalni.xlsx";
      fileDownload(response.data, fileName);
    } catch (err) {
      console.log("err ", err);
    }
  };

  syncInvoices = async (fiscalDeviceId, from) => {
    try {
      const response = await apiBase.get(SYNC_INVOICES(fiscalDeviceId, from));
      return response.data;
    } catch (err) {
      console.log("err");
      throw handleError(err, "Error syncing invoices");
    }
  };

  fetchFiscalDeviceDoubleInvoices = async (id, offset) => {
    try {
      const resposne = await apiBase.get(
        FETCH_FISCAL_DEVICE_DOUBLE_INVOICES(id, offset)
      );
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal device double invoices");
    }
  };

  fetchSignleFiscalDevice = async (id) => {
    try {
      const resposne = await apiBase.get(FETCH_SINGLE_FISCAL_DEVICE(id));
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal device");
    }
  };

  fetchFiscalDeviceInvoices = async (
    id,
    page,
    perPage,
    term,
    sortBy,
    order
  ) => {
    try {
      const response = await apiBase.get(
        FETCH_FISCAL_DEVICE_INVOICES(id, page, perPage, term, sortBy, order)
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching invoices");
    }
  };

  deleteFiscalDevice = async (id) => {
    try {
      const resposne = await apiBase.delete(FETCH_SINGLE_FISCAL_DEVICE(id));
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error deleting fiscal device");
    }
  };

  updateFiscalDevice = async (fiscalDevice) => {
    try {
      const resposne = await apiBase.put(UPDATE_FISCAL_DEVICE, {
        fiscalDevice,
      });
      if (resposne.status !== 201) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error updating fiscal device");
    }
  };
}

export default new FiscalDeviceService();
