import React from "react";
import styles from "./MobileTableGraph.module.scss";
import classNames from "classnames";
import SecondaryButton from "../../secondaryButton/SecondaryButton";

const MobileTableGraph = ({ data, onExport }) => {
  const renderBuRowWithReport = () => {
    return data.businessUnitsNames?.map((name, index) => {
      let totalKey = name + " promet";
      let taxKey = name + " porez";
      return (
        <div className={styles.graphEntry} key={name}>
          <p>{name.replaceAll('"', "")}</p>
          <div className={styles.card}>{renderReport(totalKey, taxKey)}</div>
        </div>
      );
    });
  };

  const compare = (a, b) => {
    if (a.begin < b.begin) {
      return 1;
    }
    if (a.begin > b.begin) {
      return -1;
    }
    return 0;
  };

  const renderReport = (totalKey, taxKey) => {
    return data.array
      .slice(0)
      .reverse()
      .sort(compare)
      .map((item, index) => {
        let date;
        switch (item.intervalType) {
          case "months":
            date = item.month + " " + item.year;
            break;
          case "days":
            date = item.date;
            break;
          case "hours":
            date = item.hour;
            break;
          default:
        }
        return (
          <div
            key={totalKey}
            className={classNames(
              index % 2 === 0 ? styles.rowEven : styles.rowOdd
            )}
          >
            <div className={styles.rowCard}>
              <p className={styles.rowCardTitle}>
                {item.intervalType === "hours" ? "Sat" : "Datum"}
              </p>
              <p className={styles.rowCardValue}>{date}</p>
            </div>
            <div className={styles.rowCard}>
              <p className={styles.rowCardTitle}>Promet</p>
              <p className={styles.rowCardValue}>{item[totalKey]}</p>
            </div>
            <div className={styles.rowCard}>
              <p className={styles.rowCardTitle}>Porez</p>
              <p className={styles.rowCardValue}>{item[taxKey]}</p>
            </div>
          </div>
        );
      });
  };

  return (
    <div className={styles.tableWrap}>
      <>
        {renderBuRowWithReport()}
      </>
    </div>
  );
};

export default MobileTableGraph;
