import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessUnitReportCard from "../../components/businessUnitReportCard/BusinessUnitReportCard";
import MonthGraph from "../../components/graphs/monthGraph/MonthGraph";
import DaysGraph from "../../components/graphs/daysGraph/DaysGraph";
import DashboardLayout from "../../components/layout/DashboardLayout";
import {
  fetchAllBusinessUnits24hReport,
  fetchAllBusinessUnitsCurrentDayReport,
  fetchAllBusinessUnitsCurrentMonthDaysReprot,
  fetchAllBusinessUnitsLast30DaysReprot,
  fetchAllBusinessUnitsLast6MonthsReports,
  fetchAllBusinessUnitsLast7DaysReprot,
  fetchAllBusinessUnitsYearReports,
} from "../../store/actions/user/userReport";
import styles from "./Dashboard.module.scss";
import { ThreeDots } from "svg-loaders-react";
import classNames from "classnames";
import TableGraph from "../../components/graphs/tableGraph/TableGraph";
import { isMobile } from "react-device-detect";
import MobileTableGraph from "../../components/graphs/mobileTableGraph/MobileTableGraph";
import BusinessUnitReportModal from "../../components/modals/businessUnitReportModal/BusinessUnitReportModal";
import moment from "moment";
import userReportService from "../../apis/services/user/userReportService";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import { XlsIcon, ChevronDown } from "../../assets";

const colors = ["#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600"];

const reportOptions = [
  { value: "last12Months", label: "12 meseci", labelSm: "12m" },
  { value: "last6Months", label: "6 meseci", labelSm: "6m" },
  { value: "last7Days", label: "7 dana", labelSm: "7d" },
  { value: "last30Days", label: "30 dana", labelSm: "30d" },
  { value: "currentMonthDays", label: "Tekući mesec", labelSm: "1m" },
  { value: "last24Hours", label: "24h", labelSm: "24h" },
];

const viewOptions = ["Tabelarno", "Graficki"];

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
  const allBusinessUnitsCurrentReport = useSelector(
    (state) => state.businessUnitReports.allBusinessUnitsCurrentReport
  );
  const allBusinessUnitsYearReports = useSelector(
    (state) => state.businessUnitReports.allBusinessUnitsYearReports
  );

  const allBusinessUnitsLast7DaysReport = useSelector(
    (state) => state.businessUnitReports.allBusinessUnitsLast7DaysReport
  );

  const allBusinessUnitsLast30DaysReport = useSelector(
    (state) => state.businessUnitReports.allBusinessUnitsLast30DaysReport
  );

  const allBusinessUnitsLast6MonthsReport = useSelector(
    (state) => state.businessUnitReports.allBusinessUnitsLast6MonthsReport
  );

  const allBusinessUnitsCurrentMonthDaysReport = useSelector(
    (state) => state.businessUnitReports.allBusinessUnitsCurrentMonthDaysReport
  );

  const allBusinessUnits24hReport = useSelector(
    (state) => state.businessUnitReports.allBusinessUnits24hReport
  );

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [selectedRange, setSelectedRange] = useState(reportOptions[0]);
  const [rangePickerVisible, setRangePickerVisible] = useState(false);
  const [selectedViewOption, setSelectedViewOption] = useState("Graficki");

  useEffect(() => {
    if (user?.companies) {
      dispatch(fetchAllBusinessUnitsYearReports());
      dispatch(fetchAllBusinessUnitsCurrentDayReport());
    }
  }, [user]);

  useEffect(() => {
    switch (true) {
      case selectedRange?.value === "last7Days" &&
        allBusinessUnitsLast7DaysReport.data.length === 0:
        dispatch(fetchAllBusinessUnitsLast7DaysReprot());
        break;
      case selectedRange?.value === "last12Months" &&
        allBusinessUnitsYearReports.data.length === 0:
        dispatch(fetchAllBusinessUnitsYearReports());
        break;
      case selectedRange?.value === "last6Months" &&
        allBusinessUnitsLast6MonthsReport.data.length === 0:
        dispatch(fetchAllBusinessUnitsLast6MonthsReports());
        break;
      case selectedRange?.value === "last30Days" &&
        allBusinessUnitsLast30DaysReport.data.length === 0:
        dispatch(fetchAllBusinessUnitsLast30DaysReprot());
        break;
      case selectedRange?.value === "currentMonthDays" &&
        allBusinessUnitsCurrentMonthDaysReport.data.length === 0:
        dispatch(fetchAllBusinessUnitsCurrentMonthDaysReprot());
        break;
      case selectedRange?.value === "last24Hours" &&
        allBusinessUnits24hReport.data.length === 0:
        dispatch(fetchAllBusinessUnits24hReport());
        break;
      default:
        return null;
    }
  }, [selectedRange]);

  const onExport = async () => {
    let dayBegin;
    let dayEnd;
    let type;
    switch (true) {
      case selectedRange?.value === "last7Days":
        dayBegin = moment()
          .tz("Europe/Belgrade")
          .startOf("day")
          .subtract(7, "days")
          .format("x");
        dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
        type = "days";
        break;
      case selectedRange?.value === "last12Months":
        dayBegin = moment()
          .tz("Europe/Belgrade")
          .startOf("day")
          .subtract(1, "year")
          .format("x");
        dayEnd = moment().tz("Europe/Belgrade").endOf("months").format("x");
        type = "months";
        break;
      case selectedRange?.value === "last6Months":
        dayBegin = moment()
          .tz("Europe/Belgrade")
          .startOf("day")
          .subtract(6, "months")
          .format("x");
        dayEnd = moment().tz("Europe/Belgrade").endOf("months").format("x");
        type = "months";
        break;
      case selectedRange?.value === "last30Days":
        dayBegin = moment()
          .tz("Europe/Belgrade")
          .startOf("day")
          .subtract(1, "month")
          .format("x");
        dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
        type = "days";
        break;
      case selectedRange?.value === "currentMonthDays":
        dayBegin = moment().tz("Europe/Belgrade").startOf("month").format("x");
        dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
        type = "days";
        break;
      default:
        dayBegin = moment().tz("Europe/Belgrade").startOf("month").format("x");
        dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
        type = "days";
        break;
    }
    try {
      await userReportService.exportAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        type
      );
    } catch (err) { }
  };

  const renderReportOptions = () => {
    return (
      <div className={styles.optionsTooltip}>
        {reportOptions.map((option, index) => (
          <div
            className={classNames(
              styles.tooltipOption,
              index === 0 && styles.tooltipOptionTop,
              index === reportOptions.length - 1 && styles.tooltipOptionBottom
            )}
            onClick={(e) => {
              setSelectedRange(option);
              e.stopPropagation();
              setRangePickerVisible(false);
            }}
          >
            <p className={styles.reportOptionText}>{option.label}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderBusinessUnits = () => {
    return allBusinessUnitsCurrentReport.data?.report?.map(
      (businessUnitReport) => {
        return (
          <BusinessUnitReportCard
            businessUnitName={
              businessUnitReport?.companyBusinessUnit?.address.name
            }
            total={Math.round(businessUnitReport.timeValues[0]?.totalAmount, 2)}
            taxTotal={Math.round(
              businessUnitReport.timeValues[0]?.totalTaxAmount,
              2
            )}
            onClick={() => {
              setSelectedBusinessUnit(businessUnitReport?.companyBusinessUnit);
            }}
            address={businessUnitReport?.companyBusinessUnit?.name}
          />
        );
      }
    );
  };

  useEffect(() => {
    if (allBusinessUnitsCurrentReport?.data?.report) {
      if (allBusinessUnitsCurrentReport.data.report[0]) {
        setSelectedBusinessUnits([
          ...selectedBusinessUnits,
          allBusinessUnitsCurrentReport.data.report[0].companyBusinessUnit
            .address.name,
        ]);
      }
    }
  }, [allBusinessUnitsCurrentReport]);

  const businessUnitsHeder = () => {
    return allBusinessUnitsCurrentReport?.data?.report?.map(
      (businessUnitReport, index) => {
        const buSelected = selectedBusinessUnits.find(
          (bu) => bu === businessUnitReport.companyBusinessUnit.address.name
        )
          ? true
          : false;
        return (
          <div
            className={styles.businessUnitWrap}
            onClick={() => {
              const found = selectedBusinessUnits.find(
                (bu) =>
                  bu === businessUnitReport.companyBusinessUnit.address.name
              );
              if (found) {
                const newSelectedBusinessUnits = selectedBusinessUnits.filter(
                  (bu) => bu !== found
                );
                setSelectedBusinessUnits(newSelectedBusinessUnits);
              } else {
                setSelectedBusinessUnits([
                  ...selectedBusinessUnits,
                  businessUnitReport.companyBusinessUnit.address.name,
                ]);
              }
            }}
          >
            <div
              className={classNames(
                !buSelected ? styles.cirleUnactive : styles.circle
              )}
              style={{ backgroundColor: buSelected && colors[index % 5] }}
            ></div>
            <p
              style={{ color: !buSelected && "#ecebeb", whiteSpace: "nowrap" }}
            >
              {businessUnitReport.companyBusinessUnit.address.name.replaceAll(
                '"',
                ""
              )}
            </p>
          </div>
        );
      }
    );
  };

  const graphViewOptions = () => {
    return viewOptions.map((option) => (
      <div
        className={classNames(
          styles.graphViewWrap,
          option === selectedViewOption && styles.graphViewWrapSelected
        )}
        onClick={() => {
          setSelectedViewOption(option);
        }}
      >
        <p
          className={classNames(
            styles.viewOption,
            option === selectedViewOption && styles.viewOptionSelected
          )}
        >
          {option}
        </p>
      </div>
    ));
  };

  const graphCardHeader = (renderHeader = true) => {
    return (
      <>
        <div className={styles.graphTitleWrap}>
          <div className={styles.leftWrap}>
            {selectedRange?.value !== "last24Hours" &&
              <div
                className={styles.exportBtnWrap}
                onClick={onExport}
              ><div
                className={styles.exportBt}
              >
                  <p>{isMobile ? "Izvezi " : "Izvezi izveštaj "}</p>
                  <img src={XlsIcon} alt="xls" className={styles.xlsIcon} />
                </div>
              </div>}
          </div>
          <div className={styles.rightWrap}>
            <div className={styles.graphViewOptionsWrap}>
              {graphViewOptions()}
            </div>
            {<div
              className={styles.rangePicker}
              onClick={(e) => {
                setRangePickerVisible(true);
                e.stopPropagation();
              }}
            >
              <div className={styles.wrap1}>
                <p className={styles.selectedReportOption}>
                  {isMobile ? selectedRange.labelSm : selectedRange.label}
                </p>
                {/* <img src={ChevronDown} alt="xls" className={styles.chevron} /> */}

              </div>
              {rangePickerVisible && renderReportOptions()}
            </div>}
          </div>
        </div>
        {renderHeader && (
          <div className={styles.graphHeader}>{businessUnitsHeder()}</div>
        )}
      </>
    );
  };

  const renderGraphCard = () => {
    let data = null;
    let attempt = null;
    switch (true) {
      case selectedRange.value === "last7Days" ||
        selectedRange.value === "last30Days" ||
        selectedRange.value === "currentMonthDays" ||
        selectedRange.value === "last24Hours":
        if (selectedRange.value === "last7Days") {
          attempt = allBusinessUnitsLast7DaysReport.attempt;
          data = allBusinessUnitsLast7DaysReport.data;
        } else if (selectedRange.value === "last30Days") {
          attempt = allBusinessUnitsLast30DaysReport.attempt;
          data = allBusinessUnitsLast30DaysReport.data;
        } else if (selectedRange.value === "currentMonthDays") {
          attempt = allBusinessUnitsCurrentMonthDaysReport.attempt;
          data = allBusinessUnitsCurrentMonthDaysReport.data;
        } else if (selectedRange.value === "last24Hours") {
          attempt = allBusinessUnits24hReport.attempt;
          data = allBusinessUnits24hReport.data;
        }
        return (
          <div
            className={classNames(
              isMobile && selectedViewOption !== "Graficki"
                ? styles.graphCardMobile
                : styles.graphCard
            )}
          >
            <>
              {selectedViewOption === "Graficki" && graphCardHeader()}
              {attempt && <div className={styles.graphCardCenter}><ThreeDots fill="#cbc9c9" /></div>}
              {!attempt && selectedViewOption === "Graficki" && (
                <div className={styles.graphWrap}>
                  <DaysGraph
                    data={data}
                    selectedBusinessUnits={selectedBusinessUnits}
                    variant={
                      selectedRange.value === "last24Hours" ? "bar" : "line"
                    }
                    type={
                      selectedRange.value === "last24Hours" ? "hours" : "days"
                    }
                  />
                </div>
              )}
            </>

            {!attempt && selectedViewOption === "Tabelarno" && !isMobile && (
              <>
                {graphCardHeader(false)}
                <TableGraph data={data} variant={"lines"} />
              </>
            )}
            {!attempt && selectedViewOption === "Tabelarno" && isMobile && (
              <>
                {graphCardHeader(false)}
                <MobileTableGraph data={data} onExport={onExport} />
              </>
            )}
          </div>
        );

      case selectedRange.value === "last12Months" ||
        selectedRange.value === "last6Months":
        attempt = false;
        data = [];
        if (selectedRange.value === "last12Months") {
          attempt = allBusinessUnitsYearReports.attempt;
          data = allBusinessUnitsYearReports.data;
        } else if (selectedRange.value === "last6Months") {
          attempt = allBusinessUnitsLast6MonthsReport.attempt;
          data = allBusinessUnitsLast6MonthsReport.data;
        }
        return (
          <div
            className={classNames(
              isMobile && selectedViewOption !== "Graficki"
                ? styles.graphCardMobile
                : styles.graphCard
            )}
          >
            <>
              {selectedViewOption === "Graficki" && graphCardHeader()}
              {attempt && <div className={styles.graphCardCenter}><ThreeDots fill="#cbc9c9" /></div>}
              {!attempt && selectedViewOption === "Graficki" && (
                <div className={styles.graphWrap}>
                  <MonthGraph
                    data={data}
                    selectedBusinessUnits={selectedBusinessUnits}
                    variant={"lines"}
                  />
                </div>
              )}
            </>

            {!attempt && selectedViewOption === "Tabelarno" && !isMobile && (
              <>
                {graphCardHeader(false)}
                <TableGraph data={data} variant={"lines"} onExport={onExport} />
              </>
            )}
            {!attempt && selectedViewOption === "Tabelarno" && isMobile && (
              <>
                {graphCardHeader(false)}
                <MobileTableGraph data={data} onExport={onExport} />
              </>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <BusinessUnitReportModal
        modalOpen={selectedBusinessUnit !== null}
        onClose={() => setSelectedBusinessUnit(null)}
        businessUnit={selectedBusinessUnit}
      />
      <div
        className={styles.content}
        onClick={() => {
          setRangePickerVisible(false);
        }}
      >
        <div className={styles.topPart}>
          <p className={styles.title}>Poslovni prostori</p>
        </div>
        <div className={styles.dashboardContent}>
          <div className={styles.dashboardList}>{renderBusinessUnits()}</div>
          {renderGraphCard(false)}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
