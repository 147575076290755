import {
  FETCH_FISCAL_DEVICE_LOGS_ATTEMPT,
  FETCH_FISCAL_DEVICE_LOGS_SUCCESS,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  fiscalDeviceLogs: {
    attempt: false,
    success: false,
    data: [],
    page: 1,
    perPage: 15,
    total: null,
    term: "",
    filterLogTypes: null,
    sortBy: "timestamp",
    order: "DESC",
  },
};

const fiscalDeviceLogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FISCAL_DEVICE_LOGS_ATTEMPT:
      return {
        ...state,
        fiscalDeviceLogs: {
          ...state.fiscalDeviceLogs,
          attempt: true,
        },
      };
    case FETCH_FISCAL_DEVICE_LOGS_SUCCESS:
      const {
        page,
        perPage,
        search,
        data,
        total,
        order,
        sortBy,
        term,
        filterLogTypes,
      } = action.payload.data;
      return {
        ...state,
        fiscalDeviceLogs: {
          ...state.fiscalDeviceLogs,
          page: +page,
          perPage: perPage,
          search: search,
          data: [...data],
          attempt: false,
          success: true,
          error: false,
          total: total,
          order,
          sortBy,
          term,
          filterLogTypes,
        },
      };
    default:
      return state;
  }
};

export default fiscalDeviceLogsReducer;
