import {
  ADD_TAX_LABEL_ATTEMPT,
  ADD_TAX_LABEL_ERROR,
  ADD_TAX_LABEL_RESET_STATE,
  ADD_TAX_LABEL_SUCCESS,
  DELETE_TAX_LABEL_ATTEMPT,
  DELETE_TAX_LABEL_ERROR,
  DELETE_TAX_LABEL_RESET_STATE,
  DELETE_TAX_LABEL_SUCCESS,
  GET_ALL_TAX_LABELS,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  allTaxRates: [],
  addTaxLabelInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  deleteTaxLabelInfo: {
    attempt: false,
    success: false,
    error: null,
  },
};

const taxReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TAX_LABELS: {
      const { taxLabels } = action.payload.data;
      return {
        ...state,
        allTaxRates: taxLabels,
      };
    }
    case ADD_TAX_LABEL_ATTEMPT:
      return {
        ...state,
        addTaxLabelInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case ADD_TAX_LABEL_ERROR:
      return {
        ...state,
        addTaxLabelInfo: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case ADD_TAX_LABEL_SUCCESS:
      return {
        ...state,
        addTaxLabelInfo: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case ADD_TAX_LABEL_RESET_STATE:
      return {
        ...state,
        addTaxLabelInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };

    case DELETE_TAX_LABEL_ATTEMPT:
      return {
        ...state,
        deleteTaxLabelInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case DELETE_TAX_LABEL_ERROR:
      return {
        ...state,
        deleteTaxLabelInfo: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case DELETE_TAX_LABEL_SUCCESS:
      return {
        ...state,
        deleteTaxLabelInfo: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case DELETE_TAX_LABEL_RESET_STATE:
      return {
        ...state,
        deleteTaxLabelInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default taxReducer;
