import {
  FETCH_BUSINESS_UNIT_CURRENT_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_RESET_STATE,
  FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_24H_REPORT_SUCCESS,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  businessUnitCurrentReports: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnitsYearReports: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnitsCurrentReport: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnitsLast7DaysReport: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnitsLast30DaysReport: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnitsLast6MonthsReport: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnitsCurrentMonthDaysReport: {
    attempt: false,
    data: [],
    error: null,
  },
  allBusinessUnits24hReport: {
    attempt: false,
    data: [],
    error: null,
  },
};

const businessUnitReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_UNIT_CURRENT_REPORT_SUCCESS:
      const data = action.payload;
      const businessUnits = state.businessUnitCurrentReports.data;
      let found = false;
      businessUnits.forEach((item) => {
        if (item.businessUnit.id === data.businessUnit.id) {
          found = true;
        }
      });
      if (!found) {
        businessUnits.push(data);
      }
      return {
        ...state,
        businessUnitCurrentReports: {
          attempt: false,
          data: businessUnits,
          error: null,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnitsYearReports: {
          ...state.allBusinessUnitsYearReports,
          data: action.payload,
          attempt: false,
          error: null,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ATTEMPT:
      return {
        ...state,
        allBusinessUnitsYearReports: {
          ...state.allBusinessUnitsYearReports,
          attempt: true,
          error: null,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ERROR:
      return {
        ...state,
        allBusinessUnitsYearReports: {
          ...state.allBusinessUnitsYearReports,
          data: null,
          attempt: true,
          error: action.payload,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnitsCurrentReport: {
          ...state.allBusinessUnitsCurrentReport,
          data: action.payload,
          attempt: false,
          error: null,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ATTEMPT:
      return {
        ...state,
        allBusinessUnitsLast7DaysReport: {
          ...state.allBusinessUnitsLast7DaysReport,
          attempt: true,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnitsLast7DaysReport: {
          ...state.allBusinessUnitsLast7DaysReport,
          data: action.payload,
          attempt: false,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ATTEMPT:
      return {
        ...state,
        allBusinessUnitsLast30DaysReport: {
          ...state.allBusinessUnitsLast30DaysReport,
          attempt: true,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnitsLast30DaysReport: {
          ...state.allBusinessUnitsLast30DaysReport,
          data: action.payload,
          attempt: false,
        },
      };

    case FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ATTEMPT:
      return {
        ...state,
        allBusinessUnitsCurrentMonthDaysReport: {
          ...state.allBusinessUnitsCurrentMonthDaysReport,
          attempt: true,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnitsCurrentMonthDaysReport: {
          ...state.allBusinessUnitsCurrentMonthDaysReport,
          data: action.payload,
          attempt: false,
        },
      };

    case FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ATTEMPT:
      return {
        ...state,
        allBusinessUnitsLast6MonthsReport: {
          ...state.allBusinessUnitsLast6MonthsReport,
          attempt: true,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnitsLast6MonthsReport: {
          ...state.allBusinessUnitsLast6MonthsReport,
          data: action.payload,
          attempt: false,
        },
      };

    case FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ATTEMPT:
      return {
        ...state,
        allBusinessUnits24hReport: {
          ...state.allBusinessUnits24hReport,
          attempt: true,
        },
      };
    case FETCH_ALL_BUSINESS_UNITS_24H_REPORT_SUCCESS:
      return {
        ...state,
        allBusinessUnits24hReport: {
          ...state.allBusinessUnits24hReport,
          data: action.payload,
          attempt: false,
        },
      };
    default:
      return state;
  }
};

export default businessUnitReducer;
