import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import styles from "./ExportInvoicesModal.module.scss";
import Button from "../../button/Button";
import classNames from "classnames";
import moment from "moment";
import { CloseIcon } from "../../../assets";
import fiscalDeviceService from "../../../apis/services/user/fiscalDeviceService";
import { toast } from "react-toastify";

const ExportInvoicesModal = ({ modalOpen, onClose, fiscalDeviceId }) => {
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const minDate = moment()
    .tz("Europe/Belgrade")
    .set("year", 2022)
    .set("month", 3)
    .set("date", 1);
  const maxDate = moment().tz("Europe/Belgrade").add(1, "day");
  const [attempt, setAttempt] = useState(false);

  const exportInvoices = async () => {
    try {
      const dateFrom = moment(new Date(dateBegin)).tz("Europe/Belgrade");
      const dateTo = moment(new Date(dateEnd)).tz("Europe/Belgrade");
      const diff = dateFrom.diff(dateTo, "months", true);
      if (diff < -2 || diff > 2) {
        toast.warn("Suzite interval");
        return;
      }
      setAttempt(true);
      await fiscalDeviceService.downloadInvoicesXls(
        fiscalDeviceId,
        dateFrom.format("x"),
        dateTo.format("x")
      );
      setAttempt(false);
    } catch (err) {
      console.log("err ", err);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        onClose();
        setDateBegin(null);
        setDateEnd(null);
      }}
    >
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <div className={styles.header}>
            <div className={styles.closeIconWrap}>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => {
                  onClose();
                  setDateBegin(null);
                  setDateEnd(null);
                }}
                className={styles.closeIcon}
              />
            </div>
            <p className={styles.title}>{`Izvoz računa`}</p>
          </div>
          <div className={styles.content}>
            <div className={styles.intervalRevenueReport}>
              <p className={styles.title}>Odabir datuma</p>
              <div
                className={classNames(styles.datePickersWrap, styles.marginTop)}
              >
                <div className={styles.inputWrapperSm}>
                  <p>Početak</p>
                  <input
                    type="datetime-local"
                    className={styles.dateInput}
                    min={minDate.format("YYYY-MM-DDTHH:mm:ss")}
                    max={maxDate.format("YYYY-MM-DDTHH:mm:ss")}
                    onChange={(e) => {
                      setDateBegin(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.inputWrapperSm}>
                  <p>Kraj</p>
                  <input
                    type="datetime-local"
                    min={minDate.format("YYYY-MM-DDTHH:mm:ss")}
                    max={maxDate.format("YYYY-MM-DDTHH:mm:ss")}
                    className={styles.dateInput}
                    onChange={(e) => {
                      setDateEnd(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  name={attempt ? "Izvoz u toku" : "Izvezi račune"}
                  onClick={() => {
                    if (dateBegin === null || dateEnd === null || attempt) {
                      return;
                    }
                    exportInvoices();
                  }}
                  showLoader={attempt}
                  disabled={dateBegin === null || dateEnd === null || attempt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExportInvoicesModal;
