import React from "react";
import { usePagination } from "./usePagination";
import styles from "./PaginationFooter.module.scss";
import classNames from "classnames";
import { ChevronDown } from "../../assets";
import { isMobile } from "react-device-detect";

const PaginationFooter = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: isMobile ? 1 : 5,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={styles.paginationContainer}>
      <li
        className={classNames(
          styles.arrow,
          currentPage === 1 && styles.disabled
        )}
        onClick={() => currentPage !== 1 && onPrevious()}
      >
        <img src={ChevronDown} className={styles.chevronLeft} alt="left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        return (
          <li
            key={pageNumber}
            className={classNames(
              styles.paginationItem,
              pageNumber === currentPage && styles.selected
            )}
            onClick={() => {
              onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classNames(
          styles.arrow,
          currentPage === lastPage && styles.disabled
        )}
        onClick={() => currentPage !== lastPage && onNext()}
      >
        <img src={ChevronDown} className={styles.chevronRight} alt="left" />
      </li>
    </ul>
  );
};

export default PaginationFooter;
