import React, { useEffect, useState } from "react";
import styles from "./UpdateVendingControllerModal.module.scss";
import { Modal } from "@material-ui/core";
import Input from "../../input/Input";
import Button from "../../button/Button";
import { fetchFiscalDevices } from "../../../store/actions/admin/fiscalDevice";
import AsyncSelect from "react-select/async";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { updateVendingController } from "../../../store/actions/admin/vendingControllers";
import fiscalDeviceService from "../../../apis/services/admin/fiscalDeviceService";

const UpdateVendingControllerModal = ({
  modalOpen,
  onClose,
  vendignController,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [fiscalDevice, setFiscalDevice] = useState(null);
  const [version, setVersion] = useState(null);
  const [fiscalDevices, setFiscalDevices] = useState([]);
  const [fiscaLDeviceOptions, setFiscalDeviceOptions] = useState([]);

  useEffect(() => {
    if (vendignController) {
      setName(vendignController.name);
      setFiscalDevice({
        value: vendignController?.fiscalDevice,
        label: vendignController?.fiscalDevice?.name,
      });
      setSerialNumber(vendignController.serialNumber);
      setVersion(vendignController.version);
    }
  }, [vendignController]);

  useEffect(() => {
    dispatch(fetchFiscalDevices(1, 15, ""));
  }, [dispatch]);

  useEffect(() => {
    const fetchFiscalDevices = async () => {
      const response = await fiscalDeviceService.fetchFiscalDevices(1, 15, "");
      const { data } = response;
      setFiscalDevices(data?.data);
    };
    fetchFiscalDevices();
  }, []);

  const onUpdateVendingController = () => {
    vendignController.fiscalDevice = fiscalDevice?.value;
    vendignController.name = name;
    vendignController.serialNumber = serialNumber;
    vendignController.version = version;
    dispatch(updateVendingController({ vendingController: vendignController }));
  };

  useEffect(() => {
    setFiscalDeviceOptions(
      fiscalDevices?.map((item) => {
        return {
          value: item,
          label: item.name,
        };
      })
    );
  }, [fiscalDevices]);

  const onSearchFiscalDevices = _.debounce(async (searchTerm) => {
    const response = await fiscalDeviceService.fetchFiscalDevices(
      1,
      15,
      searchTerm
    );
    const { data } = response;
    const retItems = data?.data?.map((item) => {
      return {
        value: item,
        label: item.name,
      };
    });
    return retItems;
  }, 100);

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <div className={styles.inputWrapper}>
            <p>Ime</p>
            <Input onChange={setName} value={name} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Serijski broj</p>
            <Input onChange={setSerialNumber} value={serialNumber} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Verzija</p>
            <Input onChange={setVersion} value={version} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Fiskalni uređaj</p>
            <AsyncSelect
              onInputChange={(e) => {
                onSearchFiscalDevices(e);
              }}
              loadOptions={onSearchFiscalDevices}
              defaultOptions={fiscaLDeviceOptions}
              onChange={(e) => {
                setFiscalDevice(e);
              }}
              value={fiscalDevice}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button name={"Sačuvaj"} onClick={onUpdateVendingController} />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateVendingControllerModal;
