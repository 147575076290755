import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditIcon } from "../../../../assets";
import InfiniteScrollList from "../../../../components/infiniteScrollList/InfiniteScrollList";
import Input from "../../../../components/input/Input";
import { fetchPendingVendingControllers } from "../../../../store/actions/admin/vendingControllers";
import styles from "./PendingVendingControllers.module.scss";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

const PendingVendingControllers = () => {
  const dispatch = useDispatch();
  const pendingVendingControllers = useSelector(
    (state) => state.vendingControllers.pendingVendingControllers
  );
  const history = useHistory();

  const { page, perPage, term } = pendingVendingControllers || [];
  const [searchTerm, setSearchTerm] = useState("");
  const searchVendingControllers = _.debounce((searchTerm) => {
    dispatch(fetchPendingVendingControllers(1, 15, searchTerm));
  }, 600);

  useEffect(() => {
    searchVendingControllers(searchTerm);
  }, [searchTerm, dispatch]);

  useEffect(() => {
    dispatch(fetchPendingVendingControllers(page, perPage, term));
  }, [dispatch]);

  const loadMore = () => {
    dispatch(fetchPendingVendingControllers(page + 1, perPage, searchTerm));
  };

  const renderItem = (vendingController) => {
    return (
      <div
        className={styles.itemWrap}
        onClick={() => {
          history.push(`/vending-controllers/${vendingController.id}`);
        }}
      >
        <div>
          <div className={styles.itemInfoWrapper}>
            <a
              href={`https://sdf.rs/vending-controllers/${vendingController.id}`}
              target="_blank"
              className={styles.aTag}
            >
              <p
                className={classNames(styles.configLabel, styles.clickable)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {vendingController.macAddress}
              </p>
            </a>{" "}
          </div>
          <div className={styles.itemInfoWrapper}>
            <p className={styles.itemTitle}>{vendingController.serialNumber}</p>
          </div>
        </div>
        <img src={EditIcon} alt="edit" />
      </div>
    );
  };
  return (
    <div className={styles.content}>
      <div className={styles.topPart}>
        <p className={styles.title}>Pending</p>
        <div className={styles.flexEnd}>
          <div className={styles.inputWrapper}>
            <Input
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={searchTerm === "" && "Search"}
            />
          </div>
        </div>
      </div>
      <div className={styles.listWrapper}>
        <InfiniteScrollList
          resource={pendingVendingControllers}
          renderItem={renderItem}
          loadMore={loadMore}
        />
      </div>
    </div>
  );
};

export default PendingVendingControllers;
