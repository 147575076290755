import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "@material-ui/core";
import Input from "../../input/Input";
import Button from "../../button/Button";
import styles from "./UpdateFiscalDeviceModal.module.scss";
import { updateFiscalDevice } from "../../../store/actions/admin/fiscalDevice";
import { Switch } from "@material-ui/core";

const UpdateFiscalDeviceModal = ({
  modalOpen,
  onClose,
  fiscalDevice,
  attempt,
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const [posNumber, setPosNumber] = useState(null);
  const [invoiceTypeTraining, setInvoiceTypeTraining] = useState(null);
  const [useVSDC, setUseVSDC] = useState(false);
  const [pin, setPin] = useState(null);
  const [pac, setPac] = useState(null);
  const [version, setVersion] = useState(null);

  useEffect(() => {
    if (fiscalDevice) {
      setName(fiscalDevice.name);
      setPosNumber(fiscalDevice.posNumber);
      setInvoiceTypeTraining(fiscalDevice.invoiceTypeTraining);
      setUseVSDC(fiscalDevice.useVSDC);
      setPin(fiscalDevice.pin);
      setPac(fiscalDevice.pac);
      setVersion(fiscalDevice.version);
    }
  }, [fiscalDevice]);

  const onUpdateFiscalDevice = () => {
    fiscalDevice.name = name;
    fiscalDevice.posNumber = posNumber;
    fiscalDevice.invoiceTypeTraining = invoiceTypeTraining;
    fiscalDevice.pin = pin;
    fiscalDevice.pac = pac;
    fiscalDevice.useVSDC = useVSDC;
    fiscalDevice.version = version;
    dispatch(updateFiscalDevice(fiscalDevice));
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <form autoComplete={false}>
            <div className={styles.inputWrapper}>
              <p>Ime</p>
              <Input onChange={setName} value={name} />
            </div>
            <div className={styles.inputWrapper}>
              <p>ESIR broj</p>
              <Input onChange={setPosNumber} value={posNumber} />
            </div>
            <div className={styles.inputWrapper}>
              <p>Obuka</p>
              <Switch
                color="primary"
                checked={invoiceTypeTraining}
                onChange={(event) => {
                  setInvoiceTypeTraining(event.target.checked);
                }}
              />
            </div>
            <div className={styles.inputWrapper}>
              <p>Korisiti virtuelni procesor</p>
              <Switch
                color="primary"
                checked={useVSDC}
                onChange={(event) => {
                  setUseVSDC(event.target.checked);
                }}
              />
            </div>
            <div className={styles.inputWrapper}>
              <p>PIN</p>
              <Input onChange={setPin} type="text" value={pin} />
            </div>
            <div className={styles.inputWrapper}>
              <p>PAC</p>
              <Input onChange={setPac} type="text" value={pac} />
            </div>
            <div className={styles.inputWrapper}>
              <p>Verzija</p>
              <Input onChange={setVersion} type="text" value={version} />
            </div>
          </form>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            name={"Sačuvaj"}
            onClick={onUpdateFiscalDevice}
            disabled={attempt}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateFiscalDeviceModal;
