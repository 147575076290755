import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import {
  ADD_ITEM_TO_VENDING_CONTROLLER,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE,
  DELETE_ITEM,
  FETCH_ACTIVE_VENDING_CONTROLLERS,
  FETCH_PENDING_VENDING_CONTROLLERS,
  GET_VENDING_CONTROLLER,
  GET_VENDING_CONTROLLER_INVOICES,
  GET_VENDING_CONTROLLER_LAST_INVOICE,
  UPDATE_ITEM,
  UPDATE_VENDING_CONTROLLER,
} from "../../urls";

class VendingControllersService {
  fetchPendingControllers = async (page, perPage, term) => {
    try {
      const resposne = await apiBase.get(
        FETCH_PENDING_VENDING_CONTROLLERS(page, perPage, term)
      );
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal devices");
    }
  };

  fetchActiveControllers = async (page, perPage, term, filterOffline) => {
    try {
      const resposne = await apiBase.get(
        FETCH_ACTIVE_VENDING_CONTROLLERS(page, perPage, term, filterOffline)
      );
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal devices");
    }
  };

  updateVendingController = async (vendingController) => {
    try {
      const response = await apiBase.put(
        UPDATE_VENDING_CONTROLLER,
        vendingController
      );
      if (response.status !== 201) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal devices");
    }
  };

  addVendingControllerToFiscalDevice = async ({ id, fiscalDeviceId }) => {
    try {
      const response = await apiBase.post(
        ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE(id),
        { fiscalDeviceId }
      );
      if (response.status !== 201) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(
        err,
        "Error adding vending controller to fiscal device"
      );
    }
  };

  addItemToVendingController = async ({ id, item }) => {
    try {
      const response = await apiBase.post(
        ADD_ITEM_TO_VENDING_CONTROLLER(id),
        item
      );
      if (response.status !== 201) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error adding item");
    }
  };

  deleteItem = async ({ id, itemId }) => {
    try {
      const response = await apiBase.delete(DELETE_ITEM(id, itemId));
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error deleting item");
    }
  };

  updateItem = async ({ id, item }) => {
    try {
      const response = await apiBase.put(UPDATE_ITEM(id), { item });
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error updating item");
    }
  };

  getVendingController = async ({ id }) => {
    try {
      const response = await apiBase.get(GET_VENDING_CONTROLLER(id));
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching vending controller");
    }
  };

  getLastSignedInvoice = async ({ id, captchaData }) => {
    try {
      const response = await apiBase.get(
        GET_VENDING_CONTROLLER_LAST_INVOICE(id, captchaData)
      );
      if (response.status !== 200) throw new Error();
      const { data, message } = response.data;
      return { data, message };
    } catch (err) {
      throw handleError(err, "Error fetching invoice");
    }
  };

  getVendingControllerInvoices = async ({
    id,
    page,
    perPage,
    term = " ",
    sortBy,
    order,
  }) => {
    try {
      const resposne = await apiBase.get(
        GET_VENDING_CONTROLLER_INVOICES(id, page, perPage, term, sortBy, order)
      );
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching invoices");
    }
  };
}

export default new VendingControllersService();
