import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import Dashboard from "./screens/dashboard/Dashboard";
import SplashScreen from "./screens/splash/SplashScreen";
import { Fragment, useEffect, useState } from "react";
import LoginScreen from "./screens/login/LoginScreen";
import RegisterScreen from "./screens/register/RegisterScreen";
import CompanyInformationScreen from "./screens/companyInformation/CompanyInformationScreen";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "./store/actions/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmailScren from "./screens/verifyEmail/VerifyEmailScren";
import ForgotPassword from "./screens/forgotPassword/enterEmail/ForgotPassword";
import EnterPasswordResetCode from "./screens/forgotPassword/enterCode/EnterPasswordResetCode";
import NewPassword from "./screens/forgotPassword/newPassword/NewPassword";
import Taxpayers from "./screens/admin/taxpayers/Taxpayers";
import BusinessUnits from "./screens/admin/businessUnits/BusinessUnits";
import FiscalDevices from "./screens/admin/fiscalDevices/FiscalDevices";
import UserFiscalDevices from "./screens/userScreens/fiscalDevices/FiscalDevices";
import VendingControllers from "./screens/admin/vendingControllers/VendingControllers";
import InvoiceValidationScreen from "./screens/InvoiceValidationScreen/InvoiceValidationScreen";
import FiscalDeviceScreen from "./screens/admin/fiscalDevice/FiscalDeviceScreen";
import UserFiscalDeviceScreen from "./screens/userScreens/fiscalDevice/FiscalDeviceScreen";

import TaxRatesScreen from "./screens/admin/taxRates/TaxRatesScreen";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UsersScreen from "./screens/admin/usersScreen/UsersScreen";
import SingleUserScreen from "./screens/admin/singleUser/SingleUserScreen";
import Analytics from "./screens/admin/analytics/Analytics";

function App({ minimumLoadingTime }) {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(
    minimumLoadingTime <= 0
  );

  const history = useHistory();

  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(
      () => setMinimumDurationPassed(true),
      minimumLoadingTime
    );
    return () => clearTimeout(timer);
  }, [minimumLoadingTime]);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn === false) {
      if (
        !location.pathname.includes("/invoices/validation/") &&
        !location.pathname.includes("/register") &&
        !location.pathname.includes("/company") &&
        !location.pathname.includes("/verify") &&
        !location.pathname.includes("/forgot-password/email") &&
        !location.pathname.includes("/forgot-password/code") &&
        !location.pathname.includes("/forgot-password/new-password")
      )
        history.push("/login");
    }
  }, [user, loggedIn, location.pathname]);

  return (
    <Fragment>
      {minimumDurationPassed && loggedIn !== null ? (
        <div className="App">
          <ToastContainer newestOnTop={true} />
          <Switch>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <Route path="/register">
              <RegisterScreen />
            </Route>
            <Route path="/company">
              <CompanyInformationScreen />
            </Route>
            <Route path="/verify">
              <VerifyEmailScren />
            </Route>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/taxpayers">
              <Taxpayers />
            </PrivateRoute>
            <PrivateRoute exact path="/users">
              <UsersScreen />
            </PrivateRoute>
            <PrivateRoute exact path="/users/:id">
              <SingleUserScreen />
            </PrivateRoute>
            <Route exact path="/forgot-password/email">
              <ForgotPassword />
            </Route>
            <Route exact path="/forgot-password/code">
              <EnterPasswordResetCode />
            </Route>
            <Route exact path="/forgot-password/new-password">
              <NewPassword />
            </Route>
            <Route exact path="/bussiness-units">
              <BusinessUnits />
            </Route>
            <Route exact path="/fiscal-devices">
              {user?.role === "ADMIN" ? (
                <FiscalDevices />
              ) : (
                <UserFiscalDevices />
              )}
            </Route>
            {user?.role === "ADMIN" && (
              <Route exact path={"/analytics"}>
                <Analytics />
              </Route>
            )}
            {user?.role === "ADMIN" && (
              <Route exact path={"/analytics/:id"}>
                <Analytics />
              </Route>
            )}
            {user?.role === "ADMIN" ? (
              <Route path="/fiscal-devices/:id/">
                <FiscalDeviceScreen />
              </Route>
            ) : (
              <Route exact path="/fiscal-devices/:id">
                <UserFiscalDeviceScreen />
              </Route>
            )}
            <Route path="/vending-controllers/">
              <VendingControllers />
            </Route>
            <Route path="/invoices/validation/:id">
              <InvoiceValidationScreen />
            </Route>
            <Route path="/tax-rates">
              <TaxRatesScreen />
            </Route>
            <PrivateRoute path="/">
              {user?.role === "ADMIN" ? (
                <Redirect to="/fiscal-devices" />
              ) : (
                <Redirect to="/dashboard" />
              )}
            </PrivateRoute>
          </Switch>
        </div>
      ) : (
        <SplashScreen />
      )}
    </Fragment>
  );
}

export default App;
