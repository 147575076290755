import React, { useEffect, useRef, useState } from "react";
import styles from "./DropdownButton.module.scss";
import { ChevronDown } from "../../assets";
import classNames from "classnames";

const DropdownButton = ({ buttonName, actions, disabled }) => {
  const [actionsExpanded, setActionsExpanded] = useState(false);

  const wrapperRef = useRef();

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;

    if (wrap && !wrap.contains(event.target)) {
      setActionsExpanded(false);
    }
  };

  const renderActions = () => {
    return actions.map((action, index) => (
      <div
        className={classNames(
          styles.item,
          index === 0 && styles.borderTop,
          index === actions.length - 1 && styles.borderBottom
        )}
        onClick={action.onClick}
      >
        <p>{action.name}</p>
      </div>
    ));
  };

  return (
    <div className={styles.dropdownWrap} ref={wrapperRef}>
      <div
        className={classNames(styles.wrap, disabled && styles.disabled)}
        onClick={() => {
          if (!disabled) setActionsExpanded(!actionsExpanded);
        }}
      >
        <p className={styles.buttonText}>{buttonName}</p>
        <img
          src={ChevronDown}
          className={actionsExpanded && styles.chevronUp}
          alt="down"
        />
      </div>
      {actionsExpanded && <div className={styles.menu}>{renderActions()}</div>}
    </div>
  );
};

export default DropdownButton;
