import fileDownload from "js-file-download";
import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import {
  EXPORT_ALL_BUSINESS_UNITS_REPORTS_CACHED,
  FETCH_ALL_BUSINESS_UNITS_REPORTS,
  FETCH_ALL_BUSINESS_UNITS_REPORTS_CACHED,
  FETCH_BUSINESS_UNIT_REPORT_USER,
  FETCH_FISCAL_DEVICE_REPORT_USER,
} from "../../urls";

class UserReportService {
  fetchBusinessUnitDayReport = async (
    businessUnitId,
    from,
    to,
    type,
    includeDays
  ) => {
    try {
      const response = await apiBase.get(
        FETCH_BUSINESS_UNIT_REPORT_USER(
          businessUnitId,
          from,
          to,
          type,
          includeDays
        )
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching reports");
    }
  };

  fetchAllBusinessUnitsReport = async (from, to, type, includeDays) => {
    try {
      const response = await apiBase.get(
        FETCH_ALL_BUSINESS_UNITS_REPORTS_CACHED(from, to, type, includeDays)
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching reports");
    }
  };

  exportAllBusinessUnitsReport = async (from, to, type, includeDays) => {
    try {
      const response = await apiBase.get(
        EXPORT_ALL_BUSINESS_UNITS_REPORTS_CACHED(from, to, type, includeDays),
        {
          responseType: "blob",
        }
      );
      const fileName = "Izvestaj.xlsx";
      fileDownload(response.data, fileName);
    } catch (err) {
      console.log("err ", err);
    }
  };

  fetchFiscalDeviceDayReport = async (
    fiscalDeviceId,
    from,
    to,
    type,
    includeDays
  ) => {
    try {
      const response = await apiBase.get(
        FETCH_FISCAL_DEVICE_REPORT_USER(
          fiscalDeviceId,
          from,
          to,
          type,
          includeDays
        )
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching reports");
    }
  };
}

export default new UserReportService();
