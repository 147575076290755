import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CodeInput from "../../../components/codeInput/CodeInput";
import styles from "./EnterPasswordResetCode.module.scss";
import { toast } from "react-toastify";
import {
  resetPasswordVerifyInfo,
  verifyPasswordResetCode,
} from "../../../store/actions/auth";

const EnterPasswordResetCode = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyPasswordResetCodeInfo = useSelector(
    (state) => state.auth.verifyPasswordResetCodeInfo
  );
  const { success, attempt, error } = verifyPasswordResetCodeInfo || [];
  const requestPasswordResetInfo = useSelector(
    (state) => state.auth.requestPasswordResetInfo
  );
  const { email } = requestPasswordResetInfo || [];

  const onComplete = (code) => {
    dispatch(verifyPasswordResetCode({ code, email }));
  };

  useEffect(() => {
    if (success) {
      history.push("/forgot-password/new-password");
    }
  }, [success, dispatch, history]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetPasswordVerifyInfo());
    }
  }, [error, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.gridHalves}>
        <div className={styles.contentWrapper}>
          <p className={styles.title}>Unesite kod</p>
          <p>{`Unesite kod koji ste dobili na email`}</p>
          <div className={styles.formWrapper}>
            <CodeInput
              onComplete={(code) => {
                onComplete(code);
              }}
            />
            <p className={styles.sendAgain} onClick={() => {}}>
              Posalji ponovo
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterPasswordResetCode;
