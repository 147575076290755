import vendingControllersService from "../../../apis/services/admin/vendingControllersService";
import {
  ADD_ITEM_TO_VENDING_CONTROLLER_ATTEMPT,
  ADD_ITEM_TO_VENDING_CONTROLLER_ERROR,
  ADD_ITEM_TO_VENDING_CONTROLLER_SUCCESS,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ATTEMPT,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ERROR,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_SUCCESS,
  FETCH_PENDING_VENDING_CONTROLLERS_ATTEMPT,
  FETCH_PENDING_VENDING_CONTROLLERS_ERROR,
  FETCH_PENDING_VENDING_CONTROLLERS_SUCCESS,
  FETCH_ACTIVE_VENDING_CONTROLLERS_ATTEMPT,
  FETCH_ACTIVE_VENDING_CONTROLLERS_SUCCESS,
  FETCH_ACTIVE_VENDING_CONTROLLERS_ERROR,
  GET_VENDING_CONTROLLER_ATTEMPT,
  GET_VENDING_CONTROLLER_SUCCESS,
  GET_VENDING_CONTROLLER_ERROR,
  GET_VENDING_CONTROLLER_RESET_STATE,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_RESET,
  ADD_ITEM_TO_VENDING_CONTROLLER_RESET,
  DELETE_ITEM_ATTEMPT,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_ERROR,
  DELETE_ITEM_RESET_STATE,
  UPDATE_VC_ATTEMPT,
  UPDATE_VC_SUCCESS,
  UPDATE_VC_ERROR,
  UPDATE_VC_RESET_STATE,
  UPDATE_ITEM_ATTEMPT,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_ERROR,
  UPDATE_ITEM_RESET_STATE,
  GET_VENDING_CONTROLLER_INVOICES_ATTEMPT,
  GET_VENDING_CONTROLLER_INVOICES_SUCCESS,
  GET_VENDING_CONTROLLER_INVOICES_ERROR,
} from "../../actionTypes/actionTypes";

export const fetchPendingVendingControllers = (page, perPage, term) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_PENDING_VENDING_CONTROLLERS_ATTEMPT });
      const { data } = await vendingControllersService.fetchPendingControllers(
        page,
        perPage,
        term
      );
      dispatch({
        type: FETCH_PENDING_VENDING_CONTROLLERS_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      dispatch({ type: FETCH_PENDING_VENDING_CONTROLLERS_ERROR, payload: err });
    }
  };
};

export const fetchActiveVendingControllers = (
  page,
  perPage,
  term,
  filterOffline
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ACTIVE_VENDING_CONTROLLERS_ATTEMPT });
      const { data } = await vendingControllersService.fetchActiveControllers(
        page,
        perPage,
        term,
        filterOffline
      );
      dispatch({
        type: FETCH_ACTIVE_VENDING_CONTROLLERS_SUCCESS,
        payload: { data: { ...data, filterOffline } },
      });
    } catch (err) {
      dispatch({ type: FETCH_ACTIVE_VENDING_CONTROLLERS_ERROR, payload: err });
    }
  };
};

export const addVendingControllerToFiscalDevice = ({ id, fiscalDeviceId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ATTEMPT });
      const { data } =
        await vendingControllersService.addVendingControllerToFiscalDevice({
          id,
          fiscalDeviceId,
        });
      dispatch({
        type: ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ERROR,
        payload: err,
      });
    }
  };
};

export const addItemToVendingController = ({ id, item }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_ITEM_TO_VENDING_CONTROLLER_ATTEMPT });
      const { data } =
        await vendingControllersService.addItemToVendingController({
          id,
          item,
        });
      dispatch({ type: ADD_ITEM_TO_VENDING_CONTROLLER_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: ADD_ITEM_TO_VENDING_CONTROLLER_ERROR, payload: err });
    }
  };
};

export const addItemToVendingControllerReset = () => {
  return async (dispatch) => {
    dispatch({ type: ADD_ITEM_TO_VENDING_CONTROLLER_RESET });
  };
};

export const addVendingControllerToFiscalDeviceResetState = () => {
  return async (dispatch) => {
    dispatch({ type: ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_RESET });
  };
};

export const getVendingController = ({ id }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VENDING_CONTROLLER_ATTEMPT });
      const { data } = await vendingControllersService.getVendingController({
        id,
      });
      dispatch({ type: GET_VENDING_CONTROLLER_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: GET_VENDING_CONTROLLER_ERROR, payload: err });
    }
  };
};

export const vendingControllerResetInfo = () => {
  return async (dispatch) =>
    dispatch({ type: GET_VENDING_CONTROLLER_RESET_STATE });
};

export const deleteItem = ({ id, itemId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ITEM_ATTEMPT });
      const { data } = await vendingControllersService.deleteItem({
        id,
        itemId,
      });
      dispatch({ type: DELETE_ITEM_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: DELETE_ITEM_ERROR, payload: err });
    }
  };
};

export const updateItem = ({ id, item }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ITEM_ATTEMPT });
      const { data } = await vendingControllersService.updateItem({
        id,
        item,
      });
      dispatch({ type: UPDATE_ITEM_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: UPDATE_ITEM_ERROR, payload: err });
    }
  };
};

export const updateVendingController = (vendingController) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VC_ATTEMPT });
      const { data } = await vendingControllersService.updateVendingController(
        vendingController
      );
      dispatch({ type: UPDATE_VC_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: UPDATE_VC_ERROR, payload: err });
    }
  };
};

export const getVendingControllerInvoices = ({
  id,
  page,
  perPage,
  term,
  sortBy = "",
  order = "",
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VENDING_CONTROLLER_INVOICES_ATTEMPT });
      const { data } =
        await vendingControllersService.getVendingControllerInvoices({
          id,
          page,
          perPage,
          term,
          sortBy,
          order,
        });
      dispatch({
        type: GET_VENDING_CONTROLLER_INVOICES_SUCCESS,
        payload: { data: { ...data, order, sortBy } },
      });
    } catch (err) {
      dispatch({ type: GET_VENDING_CONTROLLER_INVOICES_ERROR, payload: err });
    }
  };
};

export const updateVendingControllerResetInfo = () => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_VC_RESET_STATE });
  };
};

export const deleteItemResetState = () => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ITEM_RESET_STATE });
  };
};

export const updateItemResetState = () => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_ITEM_RESET_STATE });
  };
};
