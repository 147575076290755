import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  deleteUser,
  deleteUserResetState,
  fetchSigngleUser,
  updateUserResetState,
} from "../../../store/actions/admin/users";
import styles from "./SingleUserScreen.module.scss";
import { CloseIcon, EditIcon, DeleteIcon } from "../../../assets";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import classNames from "classnames";
import UpdateUserModal from "../../../components/modals/updateUserModal/UpdateUserModal";
import AreYouSureModal from "../../../components/modals/areYouSureModal/AreYouSureModal";
import { toast } from "react-toastify";

const SingleUserScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const user = useSelector((state) => state.users.singleUser);

  const history = useHistory();

  const updateUserInfo = useSelector((state) => state.users.updateUserInfo);
  const deleteUserInfo = useSelector((state) => state.users.deleteUserInfo);

  useEffect(() => {
    dispatch(fetchSigngleUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (updateUserInfo.success) {
      toast.info("Sacuvano");
      dispatch(updateUserResetState());
      setUpdateUserModalOpen(false);
    }
  }, [updateUserInfo]);

  useEffect(() => {
    if (deleteUserInfo.success) {
      toast.info("Korisnik obrisan");
      dispatch(deleteUserResetState());
      setDeleteUserModalOpen(false);
      history.goBack();
    }
  }, [deleteUserInfo]);

  const renderUserInfo = () => {
    return (
      <>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Ime:</p>
          </div>
          <div className={classNames(styles.configValue)}>
            <p>{user?.data?.firstName}</p>
          </div>
        </div>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Prezime:</p>
          </div>
          <div className={classNames(styles.configValue)}>
            <p>{user?.data?.lastName}</p>
          </div>
        </div>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Email:</p>
          </div>
          <div className={classNames(styles.configValue)}>
            <p>{user?.data?.email}</p>
          </div>
        </div>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Firma:</p>
          </div>
          <div className={classNames(styles.configValue)}>
            <p>{user?.data?.company?.name}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <DashboardLayout>
      <UpdateUserModal
        modalOpen={updateUserModalOpen}
        onClose={() => setUpdateUserModalOpen(false)}
        user={user?.data}
      />
      <AreYouSureModal
        onConfirm={() => {
          dispatch(deleteUser(user?.data?.id));
        }}
        modalOpen={deleteUserModalOpen}
        closeModal={() => setDeleteUserModalOpen(false)}
      />
      <div className={styles.content}>
        <div className={styles.titleWrap}>
          <img
            src={CloseIcon}
            alt="close"
            onClick={() => {
              history.goBack();
            }}
            className={styles.closeIcon}
          />
          {user?.data && (
            <p
              className={styles.title}
            >{`${user?.data?.firstName} ${user?.data?.lastName}`}</p>
          )}
        </div>
        <div className={styles.gridHalves}>
          <div className={classNames(styles.sectionMax, styles.maxHeight)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Informacije o korisniku</p>
              <div className={styles.actionsWrap}>
                <img
                  src={DeleteIcon}
                  alt="delete"
                  className={styles.deleteButton}
                  onClick={() => {
                    setDeleteUserModalOpen(true);
                  }}
                />
                <img
                  src={EditIcon}
                  alt="edit"
                  className={styles.editButton}
                  onClick={() => {
                    setUpdateUserModalOpen(true);
                    // setUpdateFiscalDeviceModalVisible(true);
                  }}
                />
              </div>
            </div>
            <div className={classNames(styles.card)}>
              <div className={styles.cardContent}>{renderUserInfo()}</div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SingleUserScreen;
