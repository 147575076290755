import {
  FETCH_FISCAL_DEVICES_ATTEMPT,
  FETCH_FISCAL_DEVICES_ERROR,
  FETCH_FISCAL_DEVICES_SUCCESS,
  FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT,
  FETCH_SINGLE_FISCAL_DEVICE_ERROR,
  FETCH_SINGLE_FISCAL_DEVICE_RESET_STATE,
  FETCH_SINGLE_FISCAL_DEVICE_SUCCESS,
  UPDATE_FISCAL_DEVICE_RESET_STATE,
  UPDATE_FISCAL_DEVICE_ATTEMPT,
  UPDATE_FISCAL_DEVICE_ERROR,
  UPDATE_FISCAL_DEVICE_SUCCESS,
  DELETE_SINGLE_FISCAL_DEVICE_ATTEMPT,
  DELETE_SINGLE_FISCAL_DEVICE_SUCCESS,
  DELETE_SINGLE_FISCAL_DEVICE_ERROR,
  DELETE_SINGLE_FISCAL_DEVICE_RESET_STATE,
  GET_FISCAL_DEVICE_INVOICES_ATTEMPT,
  GET_FISCAL_DEVICE_INVOICES_ERROR,
  GET_FISCAL_DEVICE_INVOICES_SUCCESS,
  SELECT_FISCAL_DEVICE,
  SELECT_ALL_FISCAL_DEVICES,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  fiscalDevices: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    page: 1,
    perPage: 15,
    total: null,
    term: "",
    filterOffline: false,
    filterNoRecentInvoices: false,
    offset: 180,
  },
  selectedFiscalDevices: {
    data: [],
    selectAll: false,
  },
  unSelectedFiscalDevices: {
    data: [],
  },
  deleteFiscalDeviceInfo: {
    attempt: false,
    error: null,
    success: false,
  },
  singleFiscalDevice: {
    data: null,
    attempt: false,
    success: false,
    error: null,
  },
  updateFiscalDeviceInfo: {
    attempt: false,
    error: null,
    success: false,
  },
  fiscalDeviceInvoices: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    page: 1,
    perPage: 15,
    total: null,
    term: "",
  },
};

const fiscalDeviceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FISCAL_DEVICES_ATTEMPT: {
      return {
        ...state,
        fiscalDevices: {
          ...state.fiscalDevices,
          attempt: true,
        },
      };
    }
    case FETCH_FISCAL_DEVICES_ERROR: {
      return {
        ...state,
        fiscalDevices: {
          ...state.fiscalDevices,
          attempt: false,
          error: action.payload.message,
        },
      };
    }
    case FETCH_FISCAL_DEVICES_SUCCESS: {
      const {
        page,
        perPage,
        search,
        data,
        total,
        order,
        sortBy,
        term,
        filterOffline,
        filterNoRecentInvoices,
        offset,
      } = action.payload.data;

      let selectedFiscalDevices = [...state.selectedFiscalDevices.data];
      let unSelectedFiscalDevices = [...state.unSelectedFiscalDevices.data];

      if (state.selectedFiscalDevices.selectAll) {
        data.forEach((device) => {
          if (
            !selectedFiscalDevices.find((e) => e.id === device.id) &&
            !unSelectedFiscalDevices.find((e) => e.id === device.id)
          ) {
            selectedFiscalDevices.push(device);
          }
        });
      }

      return {
        ...state,
        fiscalDevices: {
          ...state.fiscalDevices,
          page: page,
          perPage: perPage,
          search: search,
          data: [...data],
          attempt: false,
          success: true,
          error: false,
          total: total,
          order,
          sortBy,
          term,
          filterOffline,
          filterNoRecentInvoices,
          offset,
        },
        selectedFiscalDevices: {
          ...state.selectedFiscalDevices,
          data: selectedFiscalDevices,
        },
      };
    }

    case SELECT_FISCAL_DEVICE: {
      const newSelectedFiscalDevices = [...state.selectedFiscalDevices.data];
      const newUnselectedFiscalDevices = [
        ...state.unSelectedFiscalDevices.data,
      ];
      const found = newSelectedFiscalDevices.find(
        (e) => e.id === action.payload.data.id
      );
      if (found) {
        newSelectedFiscalDevices.splice(
          newSelectedFiscalDevices.indexOf(found),
          1
        );
        newUnselectedFiscalDevices.push(found);
      } else {
        newSelectedFiscalDevices.push(action.payload.data);
        newUnselectedFiscalDevices.splice(
          newUnselectedFiscalDevices.indexOf(action.payload.data),
          1
        );
      }
      return {
        ...state,
        selectedFiscalDevices: {
          ...state.selectedFiscalDevices,
          data: [...newSelectedFiscalDevices],
        },
        unSelectedFiscalDevices: {
          ...state.unSelectedFiscalDevices,
          data: newUnselectedFiscalDevices,
        },
      };
    }

    case SELECT_ALL_FISCAL_DEVICES: {
      const select = action.payload.data;
      return {
        ...state,
        selectedFiscalDevices: {
          ...state.selectedFiscalDevices,
          data: !select ? [] : state.fiscalDevices.data.map((device) => device),
          selectAll: select,
          unSelectedFiscalDevices: {
            ...state.unSelectedFiscalDevices,
            data: select ? [] : state.unSelectedFiscalDevices.data,
          },
        },
      };
    }

    case FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT: {
      return {
        ...state,
        singleFiscalDevice: {
          data: null,
          attempt: true,
          success: false,
          error: null,
        },
      };
    }

    case FETCH_SINGLE_FISCAL_DEVICE_SUCCESS: {
      return {
        ...state,
        singleFiscalDevice: {
          data: action.payload?.data?.fiscalDevice,
          attempt: false,
          success: true,
          error: null,
        },
      };
    }

    case FETCH_SINGLE_FISCAL_DEVICE_ERROR: {
      return {
        ...state,
        singleFiscalDevice: {
          data: null,
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    }

    case FETCH_SINGLE_FISCAL_DEVICE_RESET_STATE: {
      return {
        ...state,
        singleFiscalDevice: {
          data: null,
          attempt: false,
          success: false,
          error: null,
        },
      };
    }

    case UPDATE_FISCAL_DEVICE_ATTEMPT: {
      return {
        ...state,
        updateFiscalDeviceInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    }

    case UPDATE_FISCAL_DEVICE_SUCCESS: {
      return {
        ...state,
        updateFiscalDeviceInfo: {
          attempt: false,
          success: true,
          error: null,
        },
        singleFiscalDevice: {
          data: action.payload?.data?.fiscalDevice,
        },
      };
    }

    case UPDATE_FISCAL_DEVICE_ERROR: {
      return {
        ...state,
        updateFiscalDeviceInfo: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    }

    case UPDATE_FISCAL_DEVICE_RESET_STATE: {
      return {
        ...state,
        updateFiscalDeviceInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    }

    case DELETE_SINGLE_FISCAL_DEVICE_ATTEMPT: {
      return {
        ...state,
        deleteFiscalDeviceInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    }

    case DELETE_SINGLE_FISCAL_DEVICE_SUCCESS: {
      return {
        ...state,
        deleteFiscalDeviceInfo: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    }

    case DELETE_SINGLE_FISCAL_DEVICE_ERROR: {
      return {
        ...state,
        deleteFiscalDeviceInfo: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    }

    case DELETE_SINGLE_FISCAL_DEVICE_RESET_STATE: {
      return {
        ...state,
        deleteFiscalDeviceInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    }

    case GET_FISCAL_DEVICE_INVOICES_ATTEMPT:
      return {
        ...state,
        fiscalDeviceInvoices: {
          ...state.fiscalDeviceInvoices,
          attempt: true,
        },
      };

    case GET_FISCAL_DEVICE_INVOICES_ERROR:
      return {
        ...state,
        fiscalDeviceInvoices: {
          ...state.fiscalDeviceInvoices,
          error: action.payload.message,
        },
      };

    case GET_FISCAL_DEVICE_INVOICES_SUCCESS:
      const { page, perPage, search, data, total, order, sortBy } =
        action.payload.data;
      return {
        ...state,
        fiscalDeviceInvoices: {
          ...state.fiscalDeviceInvoices,
          page: page,
          perPage: perPage,
          search: search,
          data: [...data],
          attempt: false,
          success: true,
          error: false,
          total: total,
          order,
          sortBy,
        },
      };

    default:
      return state;
  }
};

export default fiscalDeviceReducer;
