import React from "react";
import { Modal } from "@material-ui/core";
import styles from "./BaseModal.module.scss";

const BaseModal = ({ children, modalOpen, onClose }) => {
  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>{children}</div>
      </div>
    </Modal>
  );
};

export default BaseModal;
