import fiscalDeviceService from "../../../apis/services/admin/fiscalDeviceService";
import {
  DELETE_SINGLE_FISCAL_DEVICE_ATTEMPT,
  DELETE_SINGLE_FISCAL_DEVICE_ERROR,
  DELETE_SINGLE_FISCAL_DEVICE_RESET_STATE,
  DELETE_SINGLE_FISCAL_DEVICE_SUCCESS,
  FETCH_FISCAL_DEVICES_ATTEMPT,
  FETCH_FISCAL_DEVICES_ERROR,
  FETCH_FISCAL_DEVICES_SUCCESS,
  FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT,
  FETCH_SINGLE_FISCAL_DEVICE_ERROR,
  FETCH_SINGLE_FISCAL_DEVICE_SUCCESS,
  GET_FISCAL_DEVICE_INVOICES_ATTEMPT,
  GET_FISCAL_DEVICE_INVOICES_ERROR,
  GET_FISCAL_DEVICE_INVOICES_SUCCESS,
  SELECT_ALL_FISCAL_DEVICES,
  SELECT_FISCAL_DEVICE,
  UPDATE_FISCAL_DEVICE_ATTEMPT,
  UPDATE_FISCAL_DEVICE_ERROR,
  UPDATE_FISCAL_DEVICE_RESET_STATE,
  UPDATE_FISCAL_DEVICE_SUCCESS,
} from "../../actionTypes/actionTypes";

export const fetchFiscalDevices = (
  page,
  perPage,
  term,
  sortBy = "",
  order = "",
  filterOffline = false,
  filterNoRecentInvoices = false,
  offset
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_FISCAL_DEVICES_ATTEMPT });
      const { data } = await fiscalDeviceService.fetchFiscalDevices(
        page,
        perPage,
        term,
        sortBy,
        order,
        filterOffline,
        filterNoRecentInvoices,
        offset
      );
      dispatch({
        type: FETCH_FISCAL_DEVICES_SUCCESS,
        payload: {
          data: {
            ...data,
            order,
            sortBy,
            term,
            filterOffline,
            filterNoRecentInvoices,
            offset,
          },
        },
      });
    } catch (err) {
      dispatch({ type: FETCH_FISCAL_DEVICES_ERROR, payload: err });
    }
  };
};

export const selectFiscalDevice = (fiscalDevice) => {
  return async (dispatch) => {
    dispatch({
      type: SELECT_FISCAL_DEVICE,
      payload: {
        data: fiscalDevice,
      },
    });
  };
};

export const selectAllFiscalDevices = (select) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_ALL_FISCAL_DEVICES, payload: { data: select } });
  };
};

export const getFiscalDeviceInvoices = (
  id,
  page,
  perPage,
  term,
  sortBy = "",
  order = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FISCAL_DEVICE_INVOICES_ATTEMPT });
      const { data } = await fiscalDeviceService.fetchFiscalDeviceInvoices(
        id,
        page,
        perPage,
        term,
        sortBy,
        order
      );
      dispatch({
        type: GET_FISCAL_DEVICE_INVOICES_SUCCESS,
        payload: { data: { ...data, order, sortBy } },
      });
    } catch (err) {
      dispatch({ type: GET_FISCAL_DEVICE_INVOICES_ERROR, payload: err });
    }
  };
};

export const fetchSigngleFiscalDevice = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT });
      const { data } = await fiscalDeviceService.fetchSignleFiscalDevice(id);
      dispatch({ type: FETCH_SINGLE_FISCAL_DEVICE_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: FETCH_SINGLE_FISCAL_DEVICE_ERROR, payload: err });
    }
  };
};

export const deleteFiscalDevice = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SINGLE_FISCAL_DEVICE_ATTEMPT });
      const { data } = await fiscalDeviceService.deleteFiscalDevice(id);
      dispatch({
        type: DELETE_SINGLE_FISCAL_DEVICE_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      dispatch({ type: DELETE_SINGLE_FISCAL_DEVICE_ERROR, payload: err });
    }
  };
};

export const deleteFiscalDeviceResetState = () => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SINGLE_FISCAL_DEVICE_RESET_STATE });
  };
};

export const updateFiscalDevice = (fiscalDevice) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_FISCAL_DEVICE_ATTEMPT });
      const { data } = await fiscalDeviceService.updateFiscalDevice(
        fiscalDevice
      );
      dispatch({ type: UPDATE_FISCAL_DEVICE_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: UPDATE_FISCAL_DEVICE_ERROR, payload: err });
    }
  };
};

export const updateFiscalDeviceResetState = () => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_FISCAL_DEVICE_RESET_STATE });
  };
};
