import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScrollList from "../../infiniteScrollList/InfiniteScrollList";
import Input from "../../input/Input";
import styles from "./ConfigureVendingControllerModal.module.scss";
import _ from "lodash";
import { fetchFiscalDevices } from "../../../store/actions/admin/fiscalDevice";
import classNames from "classnames";
import Button from "../../button/Button";

const ConfigureVendingControllerModal = ({
  modalOpen,
  onClose,
  onChoose,
  vendingControllerId,
  attempt,
  vendingController,
}) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const dispatch = useDispatch();
  const fiscalDevices = useSelector(
    (state) => state.fiscalDevices.fiscalDevices
  );
  const { page, perPage } = fiscalDevices || [];

  const [searchTerm, setSearchTerm] = useState("");

  const searchTaxpayers = _.debounce((searchTerm) => {
    dispatch(fetchFiscalDevices(1, 15, searchTerm));
  }, 600);

  useEffect(() => {
    if (vendingController?.fiscalDevice) {
      setSelectedDevice(vendingController.fiscalDevice);
    }
  }, [vendingController]);

  useEffect(() => {
    dispatch(fetchFiscalDevices(1, 15, ""));
  }, [dispatch]);

  useEffect(() => {
    searchTaxpayers(searchTerm);
  }, [searchTerm, dispatch]);

  const loadMore = () => {
    dispatch(fetchFiscalDevices(page + 1, perPage, searchTerm));
  };

  const renderItem = (fiscalDevice) => {
    return (
      <div
        className={classNames(
          styles.itemWrap,
          fiscalDevice?.id === selectedDevice?.id && styles.selected
        )}
        onClick={() => {
          if (fiscalDevice !== selectedDevice) setSelectedDevice(fiscalDevice);
          else setSelectedDevice(null);
        }}
      >
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Fiskalni uređaj:</p>
          </div>
          <div className={styles.configValue}>
            <p>{fiscalDevice.name}</p>
          </div>
        </div>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Poslovni prostor:</p>
          </div>
          <div className={styles.configValue}>
            <p>{fiscalDevice.businessUnit?.name}</p>
          </div>
        </div>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>Firma:</p>
          </div>
          <div className={styles.configValue}>
            <p>{fiscalDevice.businessUnit?.company?.name}</p>
          </div>
        </div>
        <div className={styles.configRow}>
          <div className={styles.configLabel}>
            <p className={styles.textBold}>PIB:</p>
          </div>
          <div className={styles.configValue}>
            <p>{fiscalDevice.businessUnit?.company?.PIB}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <p className={styles.title}>Fiskalni uređaji</p>
          <div className={styles.inputWrapper}>
            <Input
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={searchTerm === "" && "Search"}
            />
          </div>
          <InfiniteScrollList
            resource={fiscalDevices}
            loadMore={() => {
              loadMore();
            }}
            renderItem={renderItem}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            name={"Odaberi"}
            onClick={() => {
              onChoose(selectedDevice.id, vendingControllerId);
            }}
            disabled={selectedDevice === null || attempt ? true : false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfigureVendingControllerModal;
