import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScrollList from "../../../components/infiniteScrollList/InfiniteScrollList";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import { fetchTaxpayers } from "../../../store/actions/admin/taxPayers";
import styles from "./Taxpayers.module.scss";
import Input from "../../../components/input/Input";
import _ from "lodash";

const Taxpayers = () => {
  const dispatch = useDispatch();
  const taxpayers = useSelector((state) => state.taxpayers.taxpayers);
  const { page, perPage } = taxpayers || [];

  const [searchTerm, setSearchTerm] = useState("");

  const searchTaxpayers = _.debounce((searchTerm) => {
    dispatch(fetchTaxpayers(1, 15, searchTerm));
  }, 600);

  useEffect(() => {
    searchTaxpayers(searchTerm);
  }, [searchTerm, dispatch]);

  const loadMore = () => {
    dispatch(fetchTaxpayers(page + 1, perPage, searchTerm));
  };

  const renderTaxPayerItem = (taxpayer) => {
    return (
      <div className={styles.taxPayerItemWrap}>
        <div className={styles.basicInfo}>
          <p className={styles.taxpayerName}>{taxpayer.name}</p>
          <p className={styles.taxpayerName}>{taxpayer.PIB}</p>
          <p className={styles.taxPayerAddress}>{taxpayer.address.name}</p>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <div className={styles.dashboardContent}>
        <div className={styles.topPart}>
          <p className={styles.title}>Obveznici</p>
          <div className={styles.flexEnd}>
            <div className={styles.inputWrapper}>
              <Input
                onChange={(e) => {
                  setSearchTerm(e);
                }}
                value={searchTerm}
                placeholder={searchTerm === "" && "Search"}
              />
            </div>
          </div>
        </div>
        <div className={styles.listWrapper}>
          <InfiniteScrollList
            resource={taxpayers}
            loadMore={() => {
              loadMore();
            }}
            renderItem={renderTaxPayerItem}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Taxpayers;
