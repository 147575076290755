import React from "react";
import { Logo, LogoWhite } from "../../assets";
import styles from "./SplashScreen.module.scss";

function SplashScreen() {
  return (
    <div className={styles.pulseFrame}>
      <img className={styles.pulse} src={LogoWhite} alt="pulse-logo" />
    </div>
  );
}

export default SplashScreen;
