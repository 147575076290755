import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { FETCH_ALL_USERS, SINGLE_USER, UPDATE_SINGLE_USER } from "../../urls";

class UsersService {
  fetchUsers = async (page, perPage, term = "") => {
    try {
      const response = await apiBase.get(FETCH_ALL_USERS(page, perPage, term));
      const { data } = response.data;
      return { data }
    } catch (err) {
      throw handleError(err, "Error fetching users")
    }
  }

  fetchSignleUser = async (id) => {
    try {
      const resposne = await apiBase.get(SINGLE_USER(id));
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching user");
    }
  };

  updateUser = async (user) => {
    try {
      const resposne = await apiBase.put(UPDATE_SINGLE_USER, {
        user,
      });
      if (resposne.status !== 201) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error updating user");
    }
  };

  deleteUser = async (id) => {
    try {
      const resposne = await apiBase.delete(SINGLE_USER(id))
      if (resposne.status !== 201) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error deleting user");
    }
  };


}

export default new UsersService()