import classNames from "classnames";
import React from "react";
import Loader from "../loader/Loader";
import styles from "./Button.module.scss";

const Button = ({ onClick, name, disabled, large, fullWidth, showLoader }) => {
  return (
    <div
      className={classNames(
        styles.buttonWrap,
        fullWidth && styles.buttonWrapLg,
        large && styles.large,
        disabled && styles.buttonDisabled
      )}
      onClick={onClick}
    >
      {showLoader && <Loader size="2.2em" white />}
      <p className={classNames(styles.buttonName, large && styles.largeFont)}>
        {name}
      </p>
    </div>
  );
};

export default Button;
