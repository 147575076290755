import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import CodeInput from "../../components/codeInput/CodeInput";
import {
  login,
  resendCode,
  resendCodeInfoReset,
  verifyEmail,
  verifyEmailInfoReset,
} from "../../store/actions/auth";
import styles from "./VerifyEmailScren.module.scss";

const VerifyEmailScren = () => {
  const history = useHistory();
  const verifyEmailInfo = useSelector((state) => state.auth.verifyEmailInfo);
  const { success, error, attempt } = verifyEmailInfo || [];
  const signUpData = useSelector((state) => state.auth.signUpData);
  const { email, password } = signUpData || [];
  const resendCodeInfo = useSelector((state) => state.auth.resendCodeInfo);

  const dispatch = useDispatch();

  const onComplete = (code) => {
    dispatch(verifyEmail({ code, email }));
  };

  const resend = () => {
    dispatch(resendCode({ email }));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(verifyEmailInfoReset());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(login({ email, password }));
      dispatch(verifyEmailInfoReset());
      history.push("/dashboard");
    }
  }, [success, history, dispatch]);

  useEffect(() => {
    if (resendCodeInfo.success) {
      toast.info("Code resent");
      dispatch(resendCodeInfoReset());
    }
  }, [resendCodeInfo.success, dispatch]);

  useEffect(() => {
    if (resendCodeInfo.error) {
      toast.error(resendCodeInfo.error);
      dispatch(resendCodeInfoReset());
    }
  }, [resendCodeInfo.error, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.gridHalves}>
        <div className={styles.contentWrapper}>
          <p className={styles.title}>Potvrdite email</p>
          <p>{`Unesite kod koji ste dobili na email ${email}`}</p>
          <div className={styles.formWrapper}>
            <CodeInput onComplete={onComplete} disabled={attempt === true} />
            <p className={styles.sendAgain} onClick={resend}>
              Posalji ponovo
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailScren;
