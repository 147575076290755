import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import styles from "./FiscalDevices.module.scss";
import _ from "lodash";
import Input from "../../../components/input/Input";
import {
  fetchFiscalDevices,
  selectAllFiscalDevices,
  selectFiscalDevice,
} from "../../../store/actions/admin/fiscalDevice";
import moment from "moment-timezone";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import ResourceList from "../../../components/resourceList/ResourceList";
import { isMobile } from "react-device-detect";
import { FilterIcon } from "../../../assets";
import { CloseIcon } from "../../../assets";
import { Switch } from "@material-ui/core";
import SecondaryButton from "../../../components/secondaryButton/SecondaryButton";
import fiscalDeviceService from "../../../apis/services/admin/fiscalDeviceService";
import Checkbox from "../../../components/checkbox/Checkbox";
import DropdownButton from "../../../components/dropdownButton/DropdownButton";
import UpdateJarVersionModal from "../../../components/modals/updateJarVersionModal/UpdateJarVersionModal";
import fiscalDeviceUpdateService from "../../../apis/services/admin/fiscalDeviceUpdateService";
import { toast } from "react-toastify";

const HEADER_VALUES = [
  { label: "NAZIV", key: "name", sortable: true },
  { label: "FIRMA", key: "companyName", sortable: true },
  { label: "POSLOVNI PROSTOR", key: "businessUnit", sortable: true },
  { label: "ONLINE", key: "lastOnlineAt", sortable: true },
  { label: "KREIRAN", key: "createdAt", sortable: true },
  { label: "VERZIJA", key: "version", sortable: true },
  { label: "RAČUN", key: "lastInvoiceTime", sortable: true },
];

const FiscalDevices = () => {
  const dispatch = useDispatch();
  const fiscalDevices = useSelector(
    (state) => state.fiscalDevices.fiscalDevices
  );
  const {
    page,
    perPage,
    sortBy,
    order,
    term,
    filterOffline: filterOfflineStore,
    filterNoRecentInvoices: filterNoRecentInvoicesStore,
    offset: offsetStore,
  } = fiscalDevices || [];
  const selectedFiscalDevices = useSelector(
    (state) => state.fiscalDevices.selectedFiscalDevices.data
  );
  const selectAll = useSelector(
    (state) => state.fiscalDevices.selectedFiscalDevices.selectAll
  );
  const [updateJarVersionModalOpen, setUpdateJarVersionModalOpen] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState(term);
  const history = useHistory();
  const [filterOffline, setFilterOffline] = useState(filterOfflineStore);
  const [filterNoRecentInvoices, setFilterNoRecentInvoices] = useState(
    filterNoRecentInvoicesStore
  );

  const [offset, setOffset] = useState(offsetStore);
  const [filterOpen, setFilterOpen] = useState(false);
  const searchFicalDevices = _.debounce((searchTerm) => {
    dispatch(
      fetchFiscalDevices(
        page,
        perPage,
        searchTerm,
        sortBy,
        order,
        filterOffline,
        filterNoRecentInvoices,
        offset
      )
    );
  }, 600);

  useEffect(() => {
    dispatch(
      fetchFiscalDevices(
        page,
        perPage,
        searchTerm,
        sortBy,
        order,
        filterOffline,
        filterNoRecentInvoices,
        offset
      )
    );
  }, [filterOffline, filterNoRecentInvoices]);

  const onLoadPage = (page) => {
    dispatch(
      fetchFiscalDevices(
        page,
        perPage,
        searchTerm,
        sortBy,
        order,
        filterOffline,
        filterNoRecentInvoices,
        offset
      )
    );
  };

  const onSort = (sortBy, order) => {
    dispatch(
      fetchFiscalDevices(
        page,
        perPage,
        searchTerm,
        sortBy,
        order,
        filterOffline,
        filterNoRecentInvoices,
        offset
      )
    );
  };

  const onSubmitUpdate = async ({ jarFileUrl, fiscalDeviceIds, date }) => {
    try {
      fiscalDeviceUpdateService.createUpdate({
        jarFileUrl,
        date,
        fiscalDeviceIds,
      });
      toast.info("Update kreiran");
      setUpdateJarVersionModalOpen(false);
    } catch (err) {}
  };

  const onSelectFiscalDevice = (fiscalDevice) => {
    history.push(`/fiscal-devices/${fiscalDevice.id}/info`);
  };

  const onSelectFiscalDeviceCheckbox = (fiscalDeviceId) => {
    dispatch(selectFiscalDevice(fiscalDeviceId));
  };

  const isFiscalDeviceSelected = (fiscalDevice) => {
    const found = selectedFiscalDevices.find(
      (element) => element.id === fiscalDevice.id
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const dropdownActions = [
    {
      name: "Update",
      onClick: () => {
        setUpdateJarVersionModalOpen(true);
      },
    },
    {
      name: "Delete",
      onClick: () => {},
    },
  ];

  const getDeviceParamValue = (
    device,
    key,
    lastTimeOnline,
    createdAt,
    isOnline,
    lastInvoiceTime
  ) => {
    switch (key) {
      case HEADER_VALUES[0].key:
        return device.name;
      case HEADER_VALUES[1].key:
        return device.businessUnit.company.name;
      case HEADER_VALUES[2].key:
        return device.businessUnit.address.name;
      case HEADER_VALUES[3].key:
        return (
          <div className={styles.cell}>
            <div
              className={classNames(isOnline ? styles.online : styles.ofline)}
            />
            <p>{lastTimeOnline}</p>
          </div>
        );
      case HEADER_VALUES[4].key:
        return createdAt;
      case HEADER_VALUES[5].key:
        return device.version;
      case HEADER_VALUES[6].key:
        return lastInvoiceTime;
      default:
        return "";
    }
  };

  const renderResourceListRow = (device, index) => {
    const lastTimeOnline = device.lastOnlineAt
      ? moment(device.lastOnlineAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    const lastInvoiceTime = device.lastInvoiceTime
      ? moment(device.lastInvoiceTime)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    const isOnline =
      new Date().getTime() - device.lastOnlineAt > 300 * 1000 ? false : true;

    const createdAt = device.createdAt
      ? moment(device.createdAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    if (isMobile) {
      return (
        <div
          className={styles.card}
          onClick={() => onSelectFiscalDevice(device)}
        >
          {HEADER_VALUES.map((value) => (
            <div className={styles.rowCard}>
              <p className={styles.rowCardTitle}>
                {value.label.charAt(0).toUpperCase() +
                  value.label.slice(1).toLowerCase()}
              </p>
              <p className={styles.rowCardValue}>
                {getDeviceParamValue(
                  device,
                  value.key,
                  lastTimeOnline,
                  createdAt,
                  isOnline,
                  lastInvoiceTime
                )}
              </p>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={device.id}
          onClick={() => onSelectFiscalDevice(device)}
        >
          <div className={styles.cell}>
            {true && (
              <div className={styles.checkBoxWrap}>
                <Checkbox
                  onCheck={(e) => {
                    e.stopPropagation();
                    onSelectFiscalDeviceCheckbox(device);
                  }}
                  checked={isFiscalDeviceSelected(device)}
                />
              </div>
            )}
            <a
              href={`https://sdf.rs/fiscal-devices/${device.id}`}
              target="_blank"
              className={styles.aTag}
            >
              <p
                className={classNames(styles.configLabel, styles.clickable)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {device.name}
              </p>
            </a>
          </div>
          <div className={styles.cell}>
            <p>{device.businessUnit.company.name}</p>
          </div>
          <div className={styles.cell}>
            <p>{device.businessUnit?.address?.name}</p>
          </div>
          <div className={styles.cell}>
            <div
              className={classNames(isOnline ? styles.online : styles.ofline)}
            />
            <p>{lastTimeOnline}</p>
          </div>
          <div className={styles.cell}>
            <p>{createdAt}</p>
          </div>
          <div className={styles.cell}>
            <p>{device.version}</p>
          </div>
          <div className={styles.cell}>
            <p>{lastInvoiceTime}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <DashboardLayout>
      <>
        <UpdateJarVersionModal
          modalOpen={updateJarVersionModalOpen}
          onClose={() => {
            setUpdateJarVersionModalOpen(false);
          }}
          selectedFiscalDevices={selectedFiscalDevices}
          onRemoveDeviceFromList={(device) => {
            dispatch(selectFiscalDevice(device));
          }}
          onSubmit={onSubmitUpdate}
        />
        <div
          className={classNames(
            filterOpen ? styles.filterWrap : styles.filterWrapClosed
          )}
        >
          <div className={styles.filterHeader}>
            <div
              className={styles.closeIconWrap}
              onClick={() => {
                setFilterOpen(false);
              }}
            >
              <img src={CloseIcon} alt="close" className={styles.closeIcon} />
            </div>
            <p className={styles.filters}>Filteri</p>
          </div>
          <div className={styles.filterContent}>
            <div className={styles.inputWrapper}>
              <p>Prikaži samo offline uređaje</p>
              <Switch
                color="primary"
                checked={filterOffline}
                onChange={(event) => {
                  setFilterOffline(event.target.checked);
                  setFilterOpen(false);
                }}
              />
            </div>
            <div className={styles.inputWrapper}>
              <p>Prikaži samo bez skorasnjih računa</p>
              <Switch
                color="primary"
                checked={filterNoRecentInvoices}
                onChange={(event) => {
                  setFilterNoRecentInvoices(event.target.checked);
                  setFilterOpen(false);
                }}
              />
              <p>Vreme bez računa (minut)</p>
              <Input
                name="Offset"
                value={offset}
                onChange={setOffset}
                type="number"
              />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.content,
            isMobile && styles.contentMobile
          )}
        >
          <div className={styles.topPart}>
            <div className={styles.header}>
              <p className={isMobile ? styles.titleMobile : styles.title}>
                Fiskalni uređaji
              </p>
              {isMobile && (
                <SecondaryButton
                  name="Izvezi u XLS"
                  onClick={() => {
                    fiscalDeviceService.downloadXLs();
                  }}
                />
              )}
            </div>

            <div className={styles.flexEnd}>
              {!isMobile && (
                <>
                  <div className={styles.downloadBtnWrap}>
                    <DropdownButton
                      buttonName={"Akcije"}
                      actions={dropdownActions}
                      disabled={selectedFiscalDevices.length === 0}
                    />
                  </div>
                  <div className={styles.downloadBtnWrap}>
                    <SecondaryButton
                      name="Izvezi u XLS"
                      onClick={() => {
                        fiscalDeviceService.downloadXLs();
                      }}
                    />
                  </div>
                </>
              )}
              <div
                className={classNames(
                  filterOffline || filterNoRecentInvoices
                    ? styles.filterIconActive
                    : styles.filterIconWrap
                )}
              >
                <img
                  src={FilterIcon}
                  className={styles.filterIcon}
                  fill={"red"}
                  alt="left"
                  onClick={() => {
                    setFilterOpen(true);
                  }}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Input
                  onChange={(e) => {
                    setSearchTerm(e);
                    searchFicalDevices(e);
                  }}
                  onFocus={() => {
                    dispatch(
                      fetchFiscalDevices(
                        1,
                        perPage,
                        searchTerm,
                        sortBy,
                        order,
                        filterOffline,
                        filterNoRecentInvoices,
                        offset
                      )
                    );
                  }}
                  value={searchTerm}
                  placeholder={searchTerm === "" && "Search"}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.listWrapper,
              isMobile && styles.listWrapperMobile
            )}
          >
            <ResourceList
              resource={fiscalDevices}
              selectable={true}
              onSelectAll={() => {
                dispatch(selectAllFiscalDevices(!selectAll));
              }}
              selectAll={selectAll}
              headerValues={HEADER_VALUES}
              onLoadPage={onLoadPage}
              onSort={onSort}
              rowRender={renderResourceListRow}
              minHeight
              showLoader={true}
            />
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default FiscalDevices;
