import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./InvoiceValidationScreen.module.scss";
import { PDFReader } from "react-read-pdf";
import { isBrowser, isMobile } from "react-device-detect";
import Button from "../../components/button/Button";
import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import vendingControllersService from "../../apis/services/admin/vendingControllersService";
import ReCAPTCHA from "react-google-recaptcha"

const InvoiceValidationScreen = () => {
  const { id } = useParams();

  const [previewUrl, setPreviewUrl] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [captchaData, setCaptchaData] = useState(null)
  const [invoiceAlreadyDownloaded, setInvoiceAlreadyDownloaded] =
    useState(false);

  const onDownload = async (url) => {
    const response = await axios.get(url, { responseType: "blob" });
    const fileName = invoiceNumber ? `${invoiceNumber}.pdf` : "racun.pdf";
    fileDownload(response.data, fileName);
    toast.info("Račun preuzet");
  };

  useEffect(() => {
    (async () => {
      if (id && captchaData)
        try {
          const { data } = await vendingControllersService.getLastSignedInvoice(
            { id, captchaData }
          );
          if (data) {
            setPreviewUrl(data.convertedUrlPreview);
            setDownloadUrl(data.convertedUrlDownloadable);
            setInvoiceNumber(data.invoice?.invoiceNumber);
          } else {
            setInvoiceAlreadyDownloaded(true);
          }
        } catch (e) {
          console.log(e);
        }
    })();
  }, [id, captchaData]);

  return (
    <div className={styles.screen}>
      <ReCAPTCHA
        sitekey="6LeADXIkAAAAACb0BOQ0LGSNBkytTTq7LVm2V1yE"
        onChange={(e) => {
          setCaptchaData(e)
        }}
      />
      {captchaData && <div className={styles.previewWrap}>
        {previewUrl && isMobile && (
          <div className={styles.scrollView}>
            <PDFReader url={previewUrl} width={380} />
            <div className={styles.buttonWrap}>
              <Button
                name={"Preuzmi račun"}
                onClick={() => onDownload(downloadUrl)}
                large={false}
              />
            </div>
          </div>
        )}
        {invoiceAlreadyDownloaded && isBrowser && (
          <p className={styles.invoiceDownloaded}>Račun je preuzet</p>
        )}
        {invoiceAlreadyDownloaded && isMobile && (
          <p className={styles.invoiceDownloadedLg}>Račun je preuzet</p>
        )}
        {previewUrl && isBrowser && (
          <div className={styles.scrollView}>
            <iframe className={styles.iframe} src={previewUrl}></iframe>
          </div>
        )}
      </div>}
    </div>
  );
};

export default InvoiceValidationScreen;
