import fileDownload from "js-file-download";
import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import {
  EXPORT_FISCAL_DEVICE_INVOICES,
  FETCH_FISCAL_DEVICES,
  FETCH_FISCAL_DEVICE_INVOICES,
  FETCH_SINGLE_FISCAL_DEVICE,
  FETCH_SINGLE_USER_FISCAL_DEVICE,
  FETCH_USER_FISCAL_DEVICES,
  FETCH_USER_FISCAL_DEVICE_INVOICES,
  UPDATE_FISCAL_DEVICE,
} from "../../urls";

class FiscalDeviceService {
  fetchFiscalDevices = async (page, perPage, term = "", sortBy, order) => {
    try {
      const resposne = await apiBase.get(
        FETCH_USER_FISCAL_DEVICES(page, perPage, term, sortBy, order)
      );
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal devices");
    }
  };

  downloadInvoicesXls = async (id, from, to) => {
    try {
      const response = await apiBase.get(
        EXPORT_FISCAL_DEVICE_INVOICES(id, from, to),
        {
          responseType: "blob",
        }
      );
      const fileName = "Racuni.xlsx";
      fileDownload(response.data, fileName);
    } catch (err) {
      console.log("err ", err);
    }
  };

  fetchSignleFiscalDevice = async (id) => {
    try {
      const resposne = await apiBase.get(FETCH_SINGLE_USER_FISCAL_DEVICE(id));
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal device");
    }
  };

  fetchFiscalDeviceInvoices = async (
    id,
    page,
    perPage,
    term,
    sortBy,
    order
  ) => {
    try {
      const response = await apiBase.get(
        FETCH_USER_FISCAL_DEVICE_INVOICES(
          id,
          page,
          perPage,
          term,
          sortBy,
          order
        )
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching invoices");
    }
  };
}

export default new FiscalDeviceService();
