import classNames from "classnames";
import React from "react";
import styles from "./Loader.module.scss";

function Loader({ size, white }) {
  return (
    <div
      className={classNames(white ? styles.loader : styles.spinner)}
      style={{ height: size, width: size }}
    >
      Loading...
    </div>
  );
}

export function LoadingContainer({ white }) {
  return (
    <div className={styles.loaderContainer}>
      <Loader white={white} />
    </div>
  );
}

Loader.defaultProps = {
  white: false,
};

LoadingContainer.defaultProps = {
  white: false,
};

export default Loader;
