import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import styles from "./TaxRatesScreen.module.scss";
import { EditIcon, DeleteIcon } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  addTaxLabelResetState,
  deleteTaxLabelResetState,
  deleteTaxRate,
  getAllTaxLabels,
} from "../../../store/actions/admin/tax";
import AddTaxRateModal from "../../../components/modals/addTaxRateModal/AddTaxRateModal";
import { toast } from "react-toastify";
import AreYouSureModal from "../../../components/modals/areYouSureModal/AreYouSureModal";

const TaxRatesScreen = () => {
  const dispatch = useDispatch();
  const taxRates = useSelector((state) => state.tax.allTaxRates);
  const addTaxLabelInfo = useSelector((state) => state.tax.addTaxLabelInfo);
  const deleteTaxLabelInfo = useSelector(
    (state) => state.tax.deleteTaxLabelInfo
  );

  const [addTaxLabelModalVisible, setAddTaxLabelModalVisible] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllTaxLabels());
  }, []);

  useEffect(() => {
    if (deleteTaxLabelInfo.success) {
      toast.info("Obrisano");
      dispatch(deleteTaxLabelResetState());
      setConfirmModalOpen(false);
      dispatch(getAllTaxLabels());
    }
  }, [deleteTaxLabelInfo]);

  useEffect(() => {
    if (addTaxLabelInfo.success) {
      toast.info("Sacuvano");
      dispatch(addTaxLabelResetState());
      setAddTaxLabelModalVisible(false);
      dispatch(getAllTaxLabels());
    }
  }, [addTaxLabelInfo]);

  const onConfirmItemDelete = () => {
    dispatch(deleteTaxRate(itemForDelete.id));
  };

  const renderTaxRates = () => {
    return (
      <>
        <div className={styles.tableHeader}>
          <div className={styles.headerItem}>
            <p>Labela</p>
          </div>
          <div className={styles.headerItem}>
            <p>Procenat</p>
          </div>
          <div className={styles.headerItem}></div>
        </div>
        <div className={styles.cardContent}>
          {taxRates?.map((taxRate, index) => {
            return (
              <div
                className={classNames(
                  styles.tableRow,
                  styles.borderTop,
                  index === taxRates?.length - 1 && styles.borderBottom
                )}
              >
                <div className={styles.tableCell}>
                  <p>{taxRate.label}</p>
                </div>
                <div className={styles.tableCell}>
                  <p>{taxRate.rate}</p>
                </div>
                <div className={styles.tableCell}>
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    className={styles.editButton}
                    onClick={() => {
                      setItemForDelete(taxRate);
                      setConfirmModalOpen(true);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <DashboardLayout>
      <AddTaxRateModal
        modalOpen={addTaxLabelModalVisible}
        onClose={() => setAddTaxLabelModalVisible(false)}
        attempt={addTaxLabelInfo.attempt}
      />
      <AreYouSureModal
        modalOpen={confirmModalOpen}
        closeModal={() => {
          setConfirmModalOpen(false);
        }}
        onConfirm={onConfirmItemDelete}
      />
      <div className={styles.content}>
        <div className={styles.titleWrap}>
          <p className={styles.title}>Poreske stope</p>
        </div>
        <div className={classNames(styles.gridHalves)}>
          <div className={classNames(styles.sectionMax, styles.maxHeight)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Informacije o uređaju</p>
              <img
                src={EditIcon}
                alt="edit"
                className={styles.editButton}
                onClick={() => {
                  setAddTaxLabelModalVisible(true);
                }}
              />
            </div>
            <div className={classNames(styles.card)}>
              <div className={styles.cardContent}>{renderTaxRates()}</div>
            </div>
          </div>
          <div
            className={classNames(styles.sectionMax, styles.maxHeight)}
          ></div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TaxRatesScreen;
