import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { CREATE_FISCAL_DEVICE_UPDATE } from "../../urls";

class FiscalDeviceUpdateService {
  createUpdate = async ({ jarFileUrl, date, fiscalDeviceIds }) => {
    try {
      const response = await apiBase.post(CREATE_FISCAL_DEVICE_UPDATE, {
        jarFileUrl,
        date,
        fiscalDeviceIds,
      });
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error creating fiscal device update");
    }
  };
}

export default new FiscalDeviceUpdateService();
