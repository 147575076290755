import taxPayersService from "../../../apis/services/admin/taxPayersService";
import {
  FETCH_TAXPAYERS_ATTEMPT,
  FETCH_TAXPAYERS_ERROR,
  FETCH_TAXPAYERS_SUCCESS,
} from "../../actionTypes/actionTypes";

export const fetchTaxpayers = (page, perPage, term) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_TAXPAYERS_ATTEMPT });
      const { data } = await taxPayersService.fetchTaxpayers(
        page,
        perPage,
        term
      );
      dispatch({ type: FETCH_TAXPAYERS_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: FETCH_TAXPAYERS_ERROR, payload: err });
    }
  };
};
