export const translateMonthName = (monthName) => {
  switch (monthName) {
    case "January":
      return "Januar";
    case "February":
      return "Februar";
    case "March":
      return "Mart";
    case "April":
      return "April";
    case "May":
      return "Maj";
    case "June":
      return "Jun";
    case "July":
      return "Jul";
    case "August":
      return "Avgust";
    case "September":
      return "Septembar";
    case "October":
      return "Oktobar";
    case "November":
      return "Novembar";
    case "December":
      return "Decembar";
    default:
      return null;
  }
};

export const renameDuplicateBusinessUnitsNames = (report) => {
  const businessUnitsUsedNames = [];
  report.forEach((report) => {
    const businessUnitName = report.companyBusinessUnit.address.name;
    businessUnitsUsedNames.push(businessUnitName);
  });
  const uniqueBusinessUnitsNames = [...new Set(businessUnitsUsedNames)];
  uniqueBusinessUnitsNames.forEach((uniqueBusinessUnitsName) => {
    const repetitions = [];
    let numberOfExistingNames = 0;
    report.forEach((report) => {
      const businessUnitName = report.companyBusinessUnit.address.name;
      if (repetitions.indexOf(businessUnitName) !== -1) {
        numberOfExistingNames += 1;
        report.companyBusinessUnit.address.name =
          report.companyBusinessUnit.address.name +
          "(" +
          numberOfExistingNames +
          ")";
      } else {
        repetitions.push(businessUnitName);
      }
    });
  });
};

export const getBusinessUnitsGraphReport = (data) => {
  const array = [];
  const businessUnitsNames = [];
  renameDuplicateBusinessUnitsNames(data.report);
  data.report.forEach((report, index) => {
    const dataKey = report.companyBusinessUnit.address.name;
    businessUnitsNames.push(dataKey);
    report.timeValues.forEach((timeValue) => {
      timeValue[dataKey + " promet"] = Math.round(timeValue.totalAmount, 2);
      timeValue[dataKey + " porez"] = Math.round(timeValue.totalTaxAmount, 2);
      const prepArrayEntry = array.find(
        (entry) => entry.begin === timeValue.begin
      );
      if (prepArrayEntry) {
        prepArrayEntry[dataKey + " promet"] = Math.round(
          timeValue.totalAmount,
          2
        );
        prepArrayEntry[dataKey + " porez"] = Math.round(
          timeValue.totalTaxAmount,
          2
        );
      } else {
        timeValue.month = translateMonthName(timeValue.month);
        array.push(timeValue);
      }
    });
  });
  return {
    array,
    businessUnitsNames,
  };
};
