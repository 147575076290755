import {
  FETCH_ACTIVE_VENDING_CONTROLLERS_ATTEMPT,
  FETCH_ACTIVE_VENDING_CONTROLLERS_ERROR,
  FETCH_ACTIVE_VENDING_CONTROLLERS_SUCCESS,
  FETCH_PENDING_VENDING_CONTROLLERS_ATTEMPT,
  FETCH_PENDING_VENDING_CONTROLLERS_ERROR,
  FETCH_PENDING_VENDING_CONTROLLERS_SUCCESS,
  GET_VENDING_CONTROLLER_ATTEMPT,
  GET_VENDING_CONTROLLER_ERROR,
  GET_VENDING_CONTROLLER_RESET_STATE,
  GET_VENDING_CONTROLLER_SUCCESS,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_SUCCESS,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ATTEMPT,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ERROR,
  ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_RESET,
  ADD_ITEM_TO_VENDING_CONTROLLER_ATTEMPT,
  ADD_ITEM_TO_VENDING_CONTROLLER_ERROR,
  ADD_ITEM_TO_VENDING_CONTROLLER_SUCCESS,
  ADD_ITEM_TO_VENDING_CONTROLLER_RESET,
  DELETE_ITEM_ATTEMPT,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_ERROR,
  DELETE_ITEM_RESET_STATE,
  UPDATE_VC_SUCCESS,
  UPDATE_VC_ATTEMPT,
  UPDATE_VC_ERROR,
  UPDATE_VC_RESET_STATE,
  UPDATE_ITEM_ATTEMPT,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_ERROR,
  UPDATE_ITEM_RESET_STATE,
  GET_VENDING_CONTROLLER_INVOICES_ATTEMPT,
  GET_VENDING_CONTROLLER_INVOICES_ERROR,
  GET_VENDING_CONTROLLER_INVOICES_SUCCESS,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  pendingVendingControllers: {
    data: [],
    page: 1,
    perPage: 15,
    term: "",
    total: null,
  },
  activeVendingControllers: {
    data: [],
    page: 1,
    perPage: 15,
    term: "",
    total: null,
  },
  addItemToVendingControllerInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  addVendingControllerToFiscalDevice: {
    attempt: false,
    success: false,
    error: null,
  },
  vendingController: {
    attempt: false,
    error: null,
    data: null,
    success: false,
  },
  deleteItemInfo: {
    attempt: false,
    error: null,
    success: false,
  },
  updateItemInfo: {
    attempt: false,
    error: null,
    success: false,
  },
  updateVendingControllerInfo: {
    attempt: false,
    error: null,
    success: false,
  },
  vendingControllerInvoices: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    page: 1,
    perPage: 15,
    total: null,
    term: "",
  },
};

const vendingControllersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PENDING_VENDING_CONTROLLERS_ATTEMPT: {
      return {
        ...state,
        pendingVendingControllers: {
          ...state.pendingVendingControllers,
          attempt: true,
        },
      };
    }
    case FETCH_PENDING_VENDING_CONTROLLERS_ERROR: {
      return {
        ...state,
        pendingVendingControllers: {
          ...state.pendingVendingControllers,
          attempt: false,
          error: action.payload.message,
        },
      };
    }
    case FETCH_PENDING_VENDING_CONTROLLERS_SUCCESS: {
      const { page, perPage, search, data, total } = action.payload.data;
      return {
        ...state,
        pendingVendingControllers: {
          ...state.pendingVendingControllers,
          data:
            page === 1
              ? [...data]
              : [...state.pendingVendingControllers.data, ...data],
          page: page,
          perPage: perPage,
          search: search,
          total: total,
        },
      };
    }
    case FETCH_ACTIVE_VENDING_CONTROLLERS_ATTEMPT: {
      return {
        ...state,
        activeVendingControllers: {
          ...state.activeVendingControllers,
          attempt: true,
        },
      };
    }
    case FETCH_ACTIVE_VENDING_CONTROLLERS_ERROR: {
      return {
        ...state,
        activeVendingControllers: {
          ...state.activeVendingControllers,
          attempt: false,
          error: action.payload.message,
        },
      };
    }
    case FETCH_ACTIVE_VENDING_CONTROLLERS_SUCCESS: {
      const { page, perPage, search, data, total } = action.payload.data;
      return {
        ...state,
        activeVendingControllers: {
          ...state.activeVendingControllers,
          data:
            page === 1
              ? [...data]
              : [...state.activeVendingControllers.data, ...data],
          page: page,
          perPage: perPage,
          search: search,
          total: total,
        },
      };
    }
    case GET_VENDING_CONTROLLER_ATTEMPT: {
      return {
        ...state,
        vendingController: {
          ...state.vendingController,
          attempt: true,
        },
      };
    }

    case GET_VENDING_CONTROLLER_ERROR: {
      return {
        ...state,
        vendingController: {
          ...state.vendingController,
          error: action.payload.message,
          attempt: false,
        },
      };
    }

    case GET_VENDING_CONTROLLER_SUCCESS: {
      return {
        ...state,
        vendingController: {
          ...state.vendingController,
          error: null,
          attempt: false,
          data: action.payload.vendingController,
        },
      };
    }
    case GET_VENDING_CONTROLLER_RESET_STATE: {
      return {
        ...state,
        vendingController: {
          error: null,
          data: null,
          attempt: false,
          success: false,
        },
      };
    }
    case ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_SUCCESS: {
      return {
        ...state,
        vendingController: {
          data: action.payload.vendingController,
          attempt: false,
          error: null,
          success: true,
        },
        addVendingControllerToFiscalDevice: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    }

    case UPDATE_VC_SUCCESS: {
      return {
        ...state,
        vendingController: {
          data: action.payload.vendingController,
          attempt: false,
          error: null,
          success: true,
        },
        updateVendingControllerInfo: {
          success: true,
          error: null,
          attempt: false,
        },
      };
    }

    case UPDATE_VC_ATTEMPT: {
      return {
        ...state,
        updateVendingControllerInfo: {
          success: false,
          error: null,
          attempt: true,
        },
      };
    }

    case UPDATE_VC_ERROR: {
      return {
        ...state,
        updateVendingControllerInfo: {
          success: false,
          error: action.payload,
          attempt: false,
        },
      };
    }

    case UPDATE_VC_RESET_STATE: {
      return {
        ...state,
        updateVendingControllerInfo: {
          success: false,
          error: null,
          attempt: false,
        },
      };
    }

    case ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ATTEMPT: {
      return {
        ...state,
        addVendingControllerToFiscalDevice: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    }

    case ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ERROR: {
      return {
        ...state,
        addVendingControllerToFiscalDevice: {
          attempt: false,
          success: false,
          error: action.payload.message,
        },
      };
    }

    case ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_RESET: {
      return {
        ...state,
        addVendingControllerToFiscalDevice: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    }

    case ADD_ITEM_TO_VENDING_CONTROLLER_ATTEMPT: {
      return {
        ...state,
        addItemToVendingControllerInfo: {
          attempt: true,
          error: null,
          success: false,
        },
      };
    }

    case ADD_ITEM_TO_VENDING_CONTROLLER_ERROR: {
      return {
        ...state,
        addItemToVendingControllerInfo: {
          attempt: false,
          error: action.payload.message,
          success: false,
        },
      };
    }

    case ADD_ITEM_TO_VENDING_CONTROLLER_SUCCESS: {
      const { vendingController } = action.payload;
      return {
        ...state,
        addItemToVendingControllerInfo: {
          attempt: false,
          error: null,
          success: true,
        },
        vendingController: {
          attempt: false,
          error: null,
          data: vendingController,
          success: false,
        },
      };
    }

    case ADD_ITEM_TO_VENDING_CONTROLLER_RESET: {
      return {
        ...state,
        addItemToVendingControllerInfo: {
          attempt: false,
          error: null,
          success: false,
        },
      };
    }

    case DELETE_ITEM_ATTEMPT: {
      return {
        ...state,
        deleteItemInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    }

    case DELETE_ITEM_SUCCESS: {
      const { vendingController } = action.payload;
      return {
        ...state,
        deleteItemInfo: {
          attempt: false,
          success: true,
          error: null,
        },
        vendingController: {
          attempt: false,
          error: null,
          data: vendingController,
          success: false,
        },
      };
    }

    case DELETE_ITEM_ERROR: {
      return {
        ...state,
        deleteItemInfo: {
          attempt: false,
          success: true,
          error: action.payload.message,
        },
      };
    }

    case DELETE_ITEM_RESET_STATE: {
      return {
        ...state,
        deleteItemInfo: {
          attempt: false,
          error: null,
          success: false,
        },
      };
    }

    case UPDATE_ITEM_ATTEMPT: {
      return {
        ...state,
        updateItemInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    }

    case UPDATE_ITEM_SUCCESS: {
      const { vendingController } = action.payload;
      return {
        ...state,
        updateItemInfo: {
          attempt: false,
          success: true,
          error: null,
        },
        vendingController: {
          attempt: false,
          error: null,
          data: vendingController,
          success: false,
        },
      };
    }

    case UPDATE_ITEM_ERROR: {
      return {
        ...state,
        updateItemInfo: {
          attempt: false,
          success: true,
          error: action.payload.message,
        },
      };
    }

    case UPDATE_ITEM_RESET_STATE: {
      return {
        ...state,
        updateItemInfo: {
          attempt: false,
          error: null,
          success: false,
        },
      };
    }

    case GET_VENDING_CONTROLLER_INVOICES_ATTEMPT:
      return {
        ...state,
        vendingControllerInvoices: {
          ...state.vendingControllerInvoices,
          attempt: true,
        },
      };

    case GET_VENDING_CONTROLLER_INVOICES_ERROR:
      return {
        ...state,
        vendingControllerInvoices: {
          ...state.vendingControllerInvoices,
          error: action.payload.message,
        },
      };

    case GET_VENDING_CONTROLLER_INVOICES_SUCCESS:
      const { page, perPage, search, data, total, order, sortBy } =
        action.payload.data;
      return {
        ...state,
        vendingControllerInvoices: {
          ...state.vendingControllerInvoices,
          page: page,
          perPage: perPage,
          search: search,
          data: [...data],
          attempt: false,
          success: true,
          error: false,
          total: total,
          order,
          sortBy,
        },
      };

    default:
      return state;
  }
};

export default vendingControllersReducer;
