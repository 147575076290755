import React from "react";
import { connect, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ children, pinRequired, redirectToPin, ...rest }) {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return loggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(PrivateRoute);
