import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Switch as Switch2 } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CloseIcon } from "../../../assets";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import ActiveVendingControllers from "./active/ActiveVendingControllers";
import PendingVendingControllers from "./pending/PendingVendingControllers";
import SingleVendingController from "./single/SingleVendingController";
import styles from "./VendingControllers.module.scss";

const VendingControllers = () => {
  const { url } = useRouteMatch();

  const LINKS = [
    { name: "Pending", to: `pending` },
    { name: "Aktivni", to: `active` },
  ];

  const activeVendingControllers = useSelector(
    (state) => state.vendingControllers.activeVendingControllers
  );

  const { filterOffline: filterOfflineStore } = activeVendingControllers || [];

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOffline, setFilterOffline] = useState(filterOfflineStore);

  const { pathname } = useLocation();

  const openFilter = () => setFilterOpen(true);

  return (
    <DashboardLayout>
      <>
        <div
          className={classNames(
            filterOpen ? styles.filterWrap : styles.filterWrapClosed
          )}
        >
          <div className={styles.filterHeader}>
            <div
              className={styles.closeIconWrap}
              onClick={() => {
                setFilterOpen(false);
              }}
            >
              <img src={CloseIcon} alt="close" className={styles.closeIcon} />
            </div>
            <p className={styles.filters}>Filteri</p>
          </div>
          <div className={styles.filterContent}>
            <div className={styles.inputWrapper}>
              <p>Prikaži samo offline uređaje</p>
              <Switch2
                color="primary"
                checked={filterOffline}
                onChange={(event) => {
                  setFilterOffline(event.target.checked);
                  setFilterOpen(false);
                }}
              />
            </div>
          </div>
        </div>
        {pathname.includes("pending") || pathname.includes("active") ? (
          <div className={styles.tabHeader}>
            {LINKS.map((link) => {
              return (
                <Link
                  to={{ pathname: `${url}/${link.to}` }}
                  className={classNames(
                    pathname.includes(link.to) ? styles.selected : styles.link
                  )}
                >
                  <p>{link.name}</p>
                </Link>
              );
            })}
          </div>
        ) : (
          <div />
        )}
        <Switch>
          <Route exact path={`${url}/pending`}>
            <PendingVendingControllers />
          </Route>
          <Route exact path={`${url}/active`}>
            <ActiveVendingControllers
              openFilter={openFilter}
              filterOffline={filterOffline}
            />
          </Route>
          <Route exact path={`${url}/:id`}>
            <SingleVendingController />
          </Route>
        </Switch>
      </>
    </DashboardLayout>
  );
};

export default VendingControllers;
