import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EditIcon, DeleteIcon, CloseIcon } from "../../../../assets";
import AddItemModal from "../../../../components/modals/addItemModal/AddItemModal";
import AreYouSureModal from "../../../../components/modals/areYouSureModal/AreYouSureModal";
import ConfigureVendingControllerModal from "../../../../components/modals/configureVendingController/ConfigureVendingControllerModal";
import UpdateVendingControllerModal from "../../../../components/modals/updateVendingControllerModal/UpdateVendingControllerModal";
import ResourceList from "../../../../components/resourceList/ResourceList";
import { getAllTaxLabels } from "../../../../store/actions/admin/tax";
import {
  addItemToVendingController,
  addItemToVendingControllerReset,
  addVendingControllerToFiscalDevice,
  addVendingControllerToFiscalDeviceResetState,
  deleteItem,
  deleteItemResetState,
  getVendingController,
  getVendingControllerInvoices,
  updateItem,
  updateItemResetState,
  updateVendingControllerResetInfo,
} from "../../../../store/actions/admin/vendingControllers";
import styles from "./SingleVendingController.module.scss";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

const HEADER_VALUES = [
  { label: "BROJ RAČUNA", key: "invoiceNumber", sortable: true },
  { label: "VREME NA PFR SERVERU", key: "sdcDateTime", sortable: true },
  { label: "UKUPAN IZNOS", key: "totalAmount", sortable: true },
  { label: "UKUPAN IZNOS POREZA", key: "taxTotalAmount", sortable: false },
  { label: "RAČUN" },
  // { label: "MAK ADRESA", key: "macAddress", sortable: true },
  // { label: "ONLINE", key: "lastOnlineAt", sortable: true },
];

const ITEM_HEADER_VALUES = [
  { label: "NAZIV", key: "name" },
  { label: "GTIN", key: "gtin" },
  { label: "JEDINICNA CENA", key: "unitPrice" },
  { label: "PORESKE LABELE", key: "taxLabels" },
  { label: "IDENTIFIKATOR", key: "identifier" },
  { label: "", key: "actions" },
];

const SingleVendingController = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [updateVendingControllerModalOpen, setUpdateVendingControllerOpen] =
    useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [itemForEdit, setItemForEdit] = useState(null);
  const onConfirmDelete = () => {
    dispatch(deleteItem({ id: data?.id, itemId: itemForDelete.id }));
  };

  const history = useHistory();

  const invoices = useSelector(
    (state) => state.vendingControllers.vendingControllerInvoices
  );

  const vendingController = useSelector(
    (state) => state.vendingControllers.vendingController
  );
  const { data } = vendingController || [];

  const vendingToFiscalDevice = useSelector(
    (state) => state.vendingControllers.addVendingControllerToFiscalDevice
  );

  const addItemToVendingControllerInfo = useSelector(
    (state) => state.vendingControllers.addItemToVendingControllerInfo
  );

  const deleteItemInfo = useSelector(
    (state) => state.vendingControllers.deleteItemInfo
  );

  const updateItemInfo = useSelector(
    (state) => state.vendingControllers.updateItemInfo
  );

  const updateVendingControllerInfo = useSelector(
    (state) => state.vendingControllers.updateVendingControllerInfo
  );

  const [searchTerm, setSearchTerm] = useState("");

  const onLoadPage = (page) => {
    dispatch(
      getVendingControllerInvoices({
        id,
        page,
        perPage: invoices.perPage,
        term: searchTerm,
        sortBy: "",
        order: "",
      })
    );
  };

  const onSort = (sortBy, order) => {
    dispatch(
      getVendingControllerInvoices({
        id: id,
        page: invoices.page,
        perPage: invoices.perPage,
        term: searchTerm,
        sortBy,
        order,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getVendingControllerInvoices({ id, page: 1, perPage: 10, term: "" })
    );
  }, [id, dispatch]);

  useEffect(() => {
    if (updateVendingControllerInfo.success) {
      toast.info("Sacuvano");
      setUpdateVendingControllerOpen(false);
      dispatch(updateVendingControllerResetInfo());
    }
  }, [updateVendingControllerInfo.success]);

  useEffect(() => {
    if (updateVendingControllerInfo.error) {
      toast.warn("Greska");
      dispatch(updateVendingControllerResetInfo());
    }
  }, [updateVendingControllerInfo.error]);

  useEffect(() => {
    if (deleteItemInfo.error) {
      toast.error(deleteItemInfo.error);
      dispatch(deleteItemResetState());
    }
  }, [deleteItemInfo.error, dispatch]);

  useEffect(() => {
    if (deleteItemInfo.success) {
      toast.info("Stavka obrisana");
      dispatch(deleteItemResetState());
      setConfirmModalOpen(false);
    }
  }, [deleteItemInfo.success, dispatch]);

  useEffect(() => {
    if (updateItemInfo.success) {
      toast.info("Stavka izmenjena");
      dispatch(updateItemResetState());
      setAddItemModalOpen(false);
      setItemForEdit(null);
    }
  }, [updateItemInfo.success]);

  const handleAddItem = ({ item }) => {
    dispatch(addItemToVendingController({ id: data?.id, item }));
  };

  const handleUpdateItem = ({ item }) => {
    dispatch(updateItem({ id: data?.id, item }));
  };

  useEffect(() => {
    if (id) dispatch(getVendingController({ id }));
  }, [id, dispatch]);

  const onChoose = (fiscalDeviceId, id) => {
    dispatch(addVendingControllerToFiscalDevice({ id, fiscalDeviceId }));
  };

  useEffect(() => {
    dispatch(getAllTaxLabels());
  }, [dispatch]);

  useEffect(() => {
    if (addItemToVendingControllerInfo.error) {
      toast.error(addItemToVendingControllerInfo.error);
      dispatch(addItemToVendingControllerReset());
    }
  }, [addItemToVendingControllerInfo.error, dispatch]);

  useEffect(() => {
    if (addItemToVendingControllerInfo.success) {
      toast.info("Stavka dodata");
      dispatch(addItemToVendingControllerReset());
      setAddItemModalOpen(false);
    }
  }, [addItemToVendingControllerInfo.success, dispatch]);

  useEffect(() => {
    if (vendingToFiscalDevice.success) {
      toast.info("Fiskalni uređaj dodat");
      setConfigModalOpen(false);
      dispatch(addVendingControllerToFiscalDeviceResetState());
    }
  }, [vendingToFiscalDevice.success, dispatch]);

  useEffect(() => {
    if (vendingToFiscalDevice.error) {
      toast.error(vendingToFiscalDevice.error);
    }
  }, [vendingToFiscalDevice.error]);

  const renderAdditionalInfo = () => {
    const lastTimeOnline = data?.lastOnlineAt
      ? moment(data?.lastOnlineAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    const isOnline =
      new Date().getTime() - vendingController.lastOnlineAt > 120 * 1000
        ? false
        : true;
    if (data?.fiscalDevice) {
      return (
        <>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Ime:</p>
            </div>
            <div className={classNames(styles.configValue, styles.touchable)}>
              <p>{data?.name}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Serijski broj:</p>
            </div>
            <div className={classNames(styles.configValue, styles.touchable)}>
              <p>{data?.serialNumber}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Mac adresa:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data?.macAddress}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Firma:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data.fiscalDevice.businessUnit.company.name}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Poslovni prostor:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data.fiscalDevice.businessUnit.name}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Fiskalni uređaj:</p>
            </div>

            <div className={styles.configValue}>
              <Link
                className={styles.link}
                to={"/fiscal-devices/" + data?.fiscalDevice.id}
              >
                <p className={styles.clickable}>{data.fiscalDevice.name}</p>
              </Link>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Zanji put online:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(isOnline ? styles.online : styles.ofline)}
              />
              <p>{lastTimeOnline}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Verzija:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data?.version}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status E:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data?.status1}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status I:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data?.status2}</p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Mac adresa:</p>
            </div>
            <div className={styles.configValue}>
              <p>{data?.macAddress}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Serijski broj:</p>
            </div>
            <div className={classNames(styles.configValue, styles.touchable)}>
              <p>{data?.serialNumber}</p>
            </div>
          </div>
        </>
      );
    }
  };

  const renderResourceListRow = (invoice, index) => {
    let totalTaxAmount = 0;
    invoice?.taxItems.forEach((taxItem) => {
      totalTaxAmount += taxItem.amount;
    });

    const localDt = invoice.sdcDateTimeTimestamp
      ? moment(invoice.sdcDateTimeTimestamp)
          .tz(moment.tz.guess())
          .format("HH:mm:ss DD-MM-YYYY")
      : moment
          .utc(invoice.createdAt)
          .tz(moment.tz.guess())
          .format("HH:mm:ss DD-MM-YYYY");

    if (isMobile) {
      return (
        <div className={styles.listCard}>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Broj računa</p>
            <p className={styles.rowCardValue}>{invoice.invoiceNumber}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Vreme na PFR serveru</p>
            <p className={styles.rowCardValue}>{localDt}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Ukupan iznos</p>
            <p className={styles.rowCardValue}>{invoice.totalAmount}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Porez</p>
            <p className={styles.rowCardValue}>{totalTaxAmount}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Zurnal</p>
            <a
              href={invoice.convertedUrlPreview}
              target="_blank"
              className={styles.journalLink}
            >
              Prikaži zurnal
            </a>
          </div>
        </div>
      );
    } else
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={invoice.id}
          onClick={() => {}}
        >
          <div className={styles.cell}>
            <p>{invoice.invoiceNumber}</p>
          </div>
          <div className={styles.cell}>
            <p>{localDt}</p>
          </div>
          <div className={styles.cell}>
            <p>{invoice.totalAmount}</p>
          </div>
          <div className={styles.cell}>
            <p>{totalTaxAmount}</p>
          </div>
          <div className={styles.cell}>
            <a
              href={invoice.convertedUrlPreview}
              target="_blank"
              className={styles.journalLink}
            >
              Prikaži zurnal
            </a>
          </div>
        </div>
      );
  };

  const renderItemsListRow = (item, index) => {
    if (isMobile) {
      return (
        <div className={styles.listCard}>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Ime</p>
            <p className={styles.rowCardValue}>{item.name}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>GTIN</p>
            <p className={styles.rowCardValue}>{item.gtin}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Jedinicna cena</p>
            <p className={styles.rowCardValue}>{item.unitPrice}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Poreske labele</p>
            <p className={styles.rowCardValue}>{JSON.stringify(item.labels)}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Identifikator</p>
            <p className={styles.rowCardValue}>{item.identifier}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Akcije</p>
            <div className={styles.actionsWrap}>
              <img
                src={DeleteIcon}
                alt="delete"
                className={styles.deleteIcon}
                onClick={() => {
                  setItemForDelete(item);
                  setConfirmModalOpen(true);
                }}
              />
              <img
                src={EditIcon}
                alt="edit"
                className={styles.editButton}
                onClick={() => {
                  setItemForEdit(item);
                  setAddItemModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={item.id}
        >
          <div className={styles.cell}>
            <p>{item.name}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.gtin}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.unitPrice}</p>
          </div>
          <div className={styles.cell}>
            <p>{JSON.stringify(item.labels)}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.identifier}</p>
          </div>
          <div className={styles.cell}>
            <div className={styles.actionsWrap}>
              <img
                src={DeleteIcon}
                alt="delete"
                className={styles.deleteIcon}
                onClick={() => {
                  setItemForDelete(item);
                  setConfirmModalOpen(true);
                }}
              />
              <img
                src={EditIcon}
                alt="edit"
                className={styles.editButton}
                onClick={() => {
                  setItemForEdit(item);
                  setAddItemModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      );
  };

  return (
    <div className={styles.content}>
      <AreYouSureModal
        modalOpen={confirmModalOpen}
        closeModal={() => {
          setConfirmModalOpen(false);
        }}
        onConfirm={onConfirmDelete}
      />
      <ConfigureVendingControllerModal
        modalOpen={configModalOpen}
        onClose={() => setConfigModalOpen(false)}
        onChoose={onChoose}
        vendingControllerId={data?.id}
        attempt={vendingToFiscalDevice?.attempt}
        vendingController={data}
      />
      <AddItemModal
        modalOpen={addItemModalOpen}
        onClose={() => {
          setAddItemModalOpen(false);
          setItemForEdit(null);
        }}
        itemForEdit={itemForEdit}
        handleAddItem={handleAddItem}
        handleUpdateItem={handleUpdateItem}
      />
      <UpdateVendingControllerModal
        modalOpen={updateVendingControllerModalOpen}
        onClose={() => setUpdateVendingControllerOpen(false)}
        vendignController={vendingController?.data}
      />
      <div className={styles.titleWrap}>
        <img
          src={CloseIcon}
          alt="close"
          onClick={() => {
            history.goBack();
          }}
          className={styles.closeIcon}
        />
        <p className={isMobile ? styles.titleMobile : styles.title}>
          Vending kontroler
        </p>
      </div>
      <div className={classNames(styles.gridHalves, styles.marginBottom)}>
        <div className={classNames(styles.sectionMax)}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>Informacije o kontroleru</p>
            <img
              src={EditIcon}
              alt="edit"
              className={styles.editButton}
              onClick={() => {
                // setConfigModalOpen(true);
                setUpdateVendingControllerOpen(true);
              }}
            />
          </div>
          <div className={classNames(styles.card)}>
            <div className={styles.cardContent}>{renderAdditionalInfo()}</div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.section,
          styles.marginTop,
          styles.itemsWrap
        )}
        p
      >
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Stavke</p>
          <img
            src={EditIcon}
            alt="edit"
            className={styles.editButton}
            onClick={() => setAddItemModalOpen(true)}
          />
        </div>
        {data?.items && (
          <div>
            <ResourceList
              rowRender={renderItemsListRow}
              onSort={() => {}}
              onLoadPage={() => {}}
              headerValues={ITEM_HEADER_VALUES}
              resource={{ data: data?.items }}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.section)}>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>Računi</p>
        </div>
        <div>
          <ResourceList
            rowRender={renderResourceListRow}
            onSort={onSort}
            onLoadPage={onLoadPage}
            headerValues={HEADER_VALUES}
            resource={invoices}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleVendingController;
