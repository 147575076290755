import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Button from "../../button/Button";
import Input from "../../input/Input";
import SelectItem from "../../selectItem/SelectItem";
import BaseModal from "../baseModal/BaseModal";
import styles from "./UpdateJarVersionModal.module.scss";
import moment from "moment";

const UpdateJarVersionModal = ({
  modalOpen,
  onClose,
  selectedFiscalDevices,
  onRemoveDeviceFromList,
  onSubmit,
}) => {
  const [singleDate, setSingleDate] = useState(null);
  const [jarFileUrl, setJarFileUrl] = useState(null);
  useEffect(() => {
    if (selectedFiscalDevices.length === 0) onClose();
  }, [selectedFiscalDevices]);

  const renderSelectedFiscalDevices = () => {
    return selectedFiscalDevices.map((device) => (
      <div className={styles.itemWrap}>
        <SelectItem
          key={device.id}
          title={device.name}
          onDelete={() => {
            onRemoveDeviceFromList(device);
          }}
        />
      </div>
    ));
  };
  return (
    <BaseModal modalOpen={modalOpen} onClose={onClose}>
      <div className={styles.content}>
        <div>
          <div className={styles.listWrap}>{renderSelectedFiscalDevices()}</div>
          <div className={classNames(styles.inputWrapper, styles.marginTop)}>
            <p>Datum</p>
            <input
              type="datetime-local"
              id="startDate"
              name="startDate"
              className={styles.dateInput}
              onChange={(e) => setSingleDate(e.target.value)}
            />
          </div>
          <div className={classNames(styles.inputWrapper, styles.marginTop)}>
            <p>Jar fajl link</p>
            <Input name="link" value={jarFileUrl} onChange={setJarFileUrl} />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            name={"Sačuvaj"}
            onClick={() => {
              onSubmit({
                jarFileUrl,
                fiscalDeviceIds: selectedFiscalDevices.map(
                  (device) => device.id
                ),
                date: moment(new Date(singleDate))
                  .tz("Europe/Belgrade")
                  .format("DD-MM-YYYY HH:mm:ss"),
              });
            }}
            disabled={false}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateJarVersionModal;
