import React, { useEffect, useState } from "react";
import styles from "./ResourceList.module.scss";
import classNames from "classnames";
import PaginationFooter from "../paginationFooter/PaginationFooter";
import { DownIcon } from "../../assets";
import { isMobile } from "react-device-detect";
import Checkbox from "../checkbox/Checkbox";
import { ThreeDots } from "svg-loaders-react";

const ResourceList = ({
  selectable,
  onSelectAll,
  selectAll,
  resource,
  headerValues,
  onLoadPage,
  onSort,
  rowRender,
  minHeight,
  showLoader,
}) => {
  const { page, perPage, total, data, sortBy, order, attempt } = resource || [];
  const [sortOptions, setSortOptions] = useState({
    sortBy: sortBy,
    order: order,
  });

  const handleSort = (sortBy) => {
    if (sortBy !== sortOptions.sortBy) {
      setSortOptions({ sortBy: sortBy, order: "ASC" });
    } else {
      if (sortOptions.order === "ASC") {
        setSortOptions({ sortBy: sortOptions.sortBy, order: "DESC" });
      } else if (sortOptions.order === "DESC") {
        setSortOptions({ sortBy: sortOptions.sortBy, order: "ASC" });
      }
    }
  };

  useEffect(() => {
    if (onSort) {
      onSort(sortOptions.sortBy, sortOptions.order);
    }
  }, [sortOptions]);

  const renderContent = () => {
    return data.map((item, index) => {
      return rowRender(item, index);
    });
  };

  const renderHeaderCells = () => {
    return headerValues.map((value, index) => {
      return (
        <>
          {index === 0 && selectable && (
            <div className={classNames(styles.checkboxWrap)}>
              <Checkbox onCheck={onSelectAll} checked={selectAll} />
            </div>
          )}
          <div
            className={classNames(
              styles.cell,
              value.size === "lg" && styles.cellLg,
              value.sortable && styles.sortable
            )}
            onClick={() => {
              if (value.sortable) handleSort(value.key);
            }}
          >
            <p
              className={classNames(
                sortOptions.sortBy === value.key && styles.sortActive,
                styles.headerTitle
              )}
            >
              {value.label}
            </p>
            {value.sortable && (
              <img
                src={DownIcon}
                alt={"down"}
                className={
                  sortOptions.sortBy === value.key
                    ? sortOptions.order === "ASC"
                      ? styles.downIcon
                      : styles.upIcon
                    : styles.downIcon
                }
              />
            )}
          </div>
        </>
      );
    });
  };

  let resourceListStyle = styles.rows;

  if (isMobile && total < 5) {
    resourceListStyle = styles.rowsMobileSm;
  }
  if (isMobile && total > 5) {
    resourceListStyle = styles.rowsMobile;
  }

  return (
    <div className={styles.content}>
      {!isMobile && <div className={styles.header}>{renderHeaderCells()}</div>}
      <div
        className={classNames(
          resourceListStyle,
          minHeight && !isMobile && styles.minHeight,
          minHeight && isMobile && styles.minHeightSm,
          attempt && showLoader && styles.disableScroll
        )}
      >
        <>
          {attempt && showLoader && (
            <div className={styles.loader}>
              <ThreeDots fill="#cbc9c9" />
            </div>
          )}
          {renderContent()}
        </>
      </div>
      <div
        className={classNames(
          styles.footer,
          isMobile && styles.footerTransparent
        )}
      >
        {page && perPage && (
          <PaginationFooter
            currentPage={page}
            pageSize={perPage}
            totalCount={total}
            onPageChange={onLoadPage}
          />
        )}
      </div>
    </div>
  );
};

export default ResourceList;
