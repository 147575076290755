import React from "react";
import { useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { MenuIcon } from "../../../assets";
import { ChevronDown } from "../../../assets";
import classNames from "classnames";

const Header = ({ onAccountClick, openSideBar, showAccountSettings }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className={styles.header}>
      <img
        src={MenuIcon}
        alt={"menu"}
        className={styles.menuIcon}
        onClick={(e) => {
          openSideBar();
          e.stopPropagation();
        }}
      />
      <div className={styles.rowAlign}>
        <p
          className={classNames(styles.email, styles.disableTextSelect)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onAccountClick();
          }}
        >
          {user.email}&nbsp;
        </p>
        <img
          src={ChevronDown}
          className={showAccountSettings && styles.chevronUp}
          alt="down"
        />
      </div>
    </div>
  );
};

export default Header;
