import React from "react";
import { Modal } from "@material-ui/core";
import styles from "./AreYouSure.module.scss";
import Button from "../../button/Button";

const AreYouSureModal = ({ modalOpen, closeModal, onConfirm }) => {
  return (
    <Modal open={modalOpen} onClose={closeModal}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <p className={styles.modalText}>Da li ste sigurni?</p>
          <div className={styles.buttonsWrapper}>
            <Button name={"Potvrdi"} onClick={onConfirm} />
            <p
              className={styles.cancel}
              onClick={() => {
                closeModal();
              }}
            >
              Odustani
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AreYouSureModal;
