import usersService from "../../../apis/services/admin/usersService";
import {
  DELETE_USER_ATTEMPT,
  DELETE_USER_ERROR,
  DELETE_USER_RESET_STATE,
  DELETE_USER_SUCCESS,
  FETCH_ALL_USERS_ATTEMPT,
  FETCH_ALL_USERS_ERROR,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_SINGLE_USER_ATTEMPT,
  FETCH_SINGLE_USER_ERROR,
  FETCH_SINGLE_USER_SUCCESS,
  UPDATE_SINGLE_USER_ATTEMPT,
  UPDATE_SINGLE_USER_ERROR,
  UPDATE_SINGLE_USER_RESET_STATE,
  UPDATE_SINGLE_USER_SUCCESS
} from "../../actionTypes/actionTypes"

export const fetchAllUsers = (page, perPage, term) => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_ALL_USERS_ATTEMPT });
      const { data } = await usersService.fetchUsers(page, perPage, term)
      dispatch({ type: FETCH_ALL_USERS_SUCCESS, payload: { data } })
    } catch (err) {
      dispatch({ type: FETCH_ALL_USERS_ERROR, payload: err })
    }
  }
}

export const fetchSigngleUser = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_SINGLE_USER_ATTEMPT });
      const { data } = await usersService.fetchSignleUser(id);
      dispatch({ type: FETCH_SINGLE_USER_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: FETCH_SINGLE_USER_ERROR, payload: err });
    }
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SINGLE_USER_ATTEMPT });
      const { data } = await usersService.updateUser(
        user
      );
      dispatch({ type: UPDATE_SINGLE_USER_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: UPDATE_SINGLE_USER_ERROR, payload: err });
    }
  };
};

export const updateUserResetState = () => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SINGLE_USER_RESET_STATE });
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_ATTEMPT });
      const { data } = await usersService.deleteUser(
        id
      );
      dispatch({ type: DELETE_USER_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: DELETE_USER_ERROR, payload: err });
    }
  };
};

export const deleteUserResetState = () => {
  return async (dispatch) => {
    dispatch({ type: DELETE_USER_RESET_STATE });
  };
};