import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import styles from "./AddItemModal.module.scss";
import Input from "../../input/Input";
import Select from "react-select";
import { useSelector } from "react-redux";
import Button from "../../button/Button";
import { toast } from "react-toastify";

const AddItemModal = ({
  modalOpen,
  onClose,
  handleAddItem,
  itemForEdit,
  handleUpdateItem,
}) => {
  const [name, setName] = useState("");
  const [gtin, setGtin] = useState(null);
  const [unitPrice, setUnitPrice] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [options, setOptions] = useState([]);
  const [labels, setLabels] = useState([]);
  const allTaxLabels = useSelector((state) => state.tax.allTaxRates);

  useEffect(() => {
    if (itemForEdit) {
      setName(itemForEdit.name);
      setGtin(itemForEdit.gtin);
      setUnitPrice(itemForEdit.unitPrice);
      setIdentifier(itemForEdit.identifier);
      setLabels(
        itemForEdit.labels.map((l) => {
          return {
            label: l,
            value: l,
          };
        })
      );
    }
  }, [itemForEdit]);

  useEffect(() => {
    const opt = allTaxLabels.map((tax) => {
      return { value: tax.label, label: tax.label };
    });
    setOptions(opt);
  }, [allTaxLabels]);

  const validateInput = () => {
    if (name === "") {
      toast.warning("Name cannot be empty");
      return false;
    }
    if (unitPrice === "") {
      toast.warning("Unit price cannot be empty!");
      return false;
    }
    if (identifier === "") {
      toast.warning("Identifier cannot be empty");
      return false;
    }
    if (labels.length === 0) {
      toast.warn("Lables array cannot be empty");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateInput()) return;
    const item = {
      name,
      gtin,
      unitPrice,
      identifier,
      labels: labels.map((tax) => tax.label),
    };

    if (itemForEdit) {
      item.id = itemForEdit.id;
      handleUpdateItem({ item });
    } else {
      handleAddItem({ item });
    }
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          {itemForEdit ? <p>Izmeni stavku</p> : <p>Dodaj stavku</p>}
          <div className={styles.form}>
            <div className={styles.inputWrapper}>
              <p>Ime</p>
              <Input name={"Ime"} onChange={setName} value={name} />
            </div>
            <div className={styles.inputWrapper}>
              <p>GTIN</p>
              <Input name={"Gtin"} onChange={setGtin} value={gtin} />
            </div>
            <div className={styles.inputWrapper}>
              <p>Cena</p>
              <Input
                name={"Cena"}
                onChange={setUnitPrice}
                value={unitPrice}
                type="number"
              />
            </div>
            <div className={styles.inputWrapper}>
              <p>Indentifikator</p>
              <Input
                name={"Indetifikator"}
                onChange={setIdentifier}
                value={identifier}
              />
            </div>
            <div className={styles.inputWrapper}>
              <p>Labele</p>
              <Select
                options={options}
                isMulti
                value={labels}
                onChange={(e) => {
                  setLabels(e);
                }}
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              name={itemForEdit ? "Sačuvaj" : "Dodaj"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddItemModal;
