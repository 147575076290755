import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import SideBar from "./sideBar/SideBar";
import styles from "./DashboardLayout.module.scss";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Button from "../../components/button/Button";
import { useHistory } from "react-router-dom";

const DashboardLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const logoutUser = useSelector((state) => state.auth.logout);

  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const history = useHistory();

  const [sideBarOpen, setSideBarOpen] = useState(false);

  const logout = () => {
    localStorage.setItem("AUTH_TOKEN", null);
    localStorage.setItem("REFRESH_TOKEN", null);
    history.push("/login");
  };

  useEffect(() => {
    console.log("logout ", logoutUser);
    if (logoutUser) {
      logout();
    }
  }, [logoutUser]);

  return (
    <div
      className={classNames(
        sideBarOpen ? styles.containerSideBarOpen : styles.container
      )}
      onClick={(e) => {
        setShowAccountSettings(false);
        setSideBarOpen(false);
        e.stopPropagation();
      }}
    >
      <SideBar open={sideBarOpen} />
      <Header
        showAccountSettings={showAccountSettings}
        openSideBar={() => {
          setSideBarOpen(true);
        }}
        onAccountClick={() => {
          showAccountSettings
            ? setShowAccountSettings(false)
            : setShowAccountSettings(true);
        }}
      />
      <div
        className={classNames(
          styles.accountSettings,
          !showAccountSettings && styles.hidden
        )}
      >
        <div className={styles.avatar}>
          <p className={styles.avatarInner}>
            {user.firstName?.substr(0, 1).toUpperCase()}
          </p>
        </div>
        <div className={styles.fullNameWrap}>
          <p className={styles.fullName}>
            {user.firstName + " " + user.lastName}
          </p>
        </div>
        <p className={styles.email}>{user.email}</p>
        <Button name="Odjavi se" onClick={logout} />
      </div>
      {children}
    </div>
  );
};

export default DashboardLayout;
