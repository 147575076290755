// Auth
export const LOGIN_URL = "/auth/login";
export const FETCH_USER_INFO_URL = "/auth/user-info";
export const SIGN_UP_URL = "/auth/sign-up";
export const COMPANY_INFORMATION = "/users/info";
export const REFRESH_TOKEN = "/auth/refresh-token";
export const VERIFY_EMAIL = "/auth/verify/email";
export const RESEND_CODE = "/auth/resend-code/email";
export const REQUEST_PASSWORD_RESET = "/auth/reset-password";
export const VERIFY_PASSWORD_RESET_CODE = "/auth/reset-password/verify";
export const UPDATE_PASSWORD = "/auth/reset-password/password";

//taxpayers
export const FETCH_TAXPAYERS = (page, perPage, term) =>
  `/companies?page=${page}&perPage=${perPage}&term=${term}`;

//fiscal device
export const FETCH_FISCAL_DEVICES = (
  page,
  perPage,
  term,
  sortBy,
  order,
  filterOffline = false,
  filterNoRecentInvoices = false,
  offset = 180
) =>
  `/fiscal-devices?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}&filterOffline=${filterOffline}&filterNoRecentInvoices=${filterNoRecentInvoices}&offset=${offset}`;
export const FETCH_SINGLE_FISCAL_DEVICE = (id) => `/fiscal-devices/${id}`;
export const UPDATE_FISCAL_DEVICE = "/fiscal-devices";
export const DOWNLOAD_XLS = "/fiscal-devices/download";
export const FETCH_FISCAL_DEVICE_INVOICES = (
  id,
  page,
  perPage,
  term,
  sortBy,
  order
) =>
  `/fiscal-devices/${id}/invoices?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}`;
export const SYNC_INVOICES = (fiscalDeviceId, from) =>
  `fiscal-devices/${fiscalDeviceId}/sync-missing-invoices?from=${from}`;
//user fiscal devices
export const FETCH_USER_FISCAL_DEVICES = (page, perPage, term, sortBy, order) =>
  `/user/fiscal-devices?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}`;
export const FETCH_SINGLE_USER_FISCAL_DEVICE = (id) =>
  `/user/fiscal-devices/${id}`;
export const FETCH_USER_FISCAL_DEVICE_INVOICES = (
  id,
  page,
  perPage,
  term,
  sortBy,
  order
) =>
  `/user/fiscal-devices/${id}/invoices?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}`;

export const EXPORT_FISCAL_DEVICE_INVOICES = (id, from, to) =>
  `/user/fiscal-devices/${id}/export-invoices?from=${from}&to=${to}`;

export const FETCH_FISCAL_DEVICE_DOUBLE_INVOICES = (fiscalDeviceId, offset) =>
  `/fiscal-devices/${fiscalDeviceId}/invoices/check?interval=${offset}`;
//vending controllers
export const FETCH_PENDING_VENDING_CONTROLLERS = (page, perPage, term) =>
  `/fiscal-devices/esp/pending?page=${page}&perPage=${perPage}&term=${term}`;
export const FETCH_ACTIVE_VENDING_CONTROLLERS = (
  page,
  perPage,
  term,
  filterOffline
) =>
  `/fiscal-devices/esp/active?page=${page}&perPage=${perPage}&term=${term}&filterOffline=${filterOffline}`;
export const ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE = (id) =>
  `/fiscal-devices/esp/${id}/fiscal-device`;
export const ADD_ITEM_TO_VENDING_CONTROLLER = (id) =>
  `/fiscal-devices/esp/${id}/items`;
export const GET_VENDING_CONTROLLER = (id) => `/fiscal-devices/esp/${id}`;
export const DELETE_ITEM = (id, itemId) =>
  `/fiscal-devices/esp/${id}/items/${itemId}`;
export const UPDATE_ITEM = (id) => `/fiscal-devices/esp/${id}/items`;
export const GET_VENDING_CONTROLLER_LAST_INVOICE = (id, captchaData) =>
  `/fiscal-devices/esp/${id}/invoice?captcha=${captchaData}`;
export const GET_VENDING_CONTROLLER_INVOICES = (
  id,
  page,
  perPage,
  term,
  sortBy,
  order
) =>
  `/fiscal-devices/esp/${id}/invoices?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}`;
export const UPDATE_VENDING_CONTROLLER = "/fiscal-devices/esp";
export const FETCH_FISCAL_DEVICE_LOGS = (
  id,
  page,
  perPage,
  term,
  filterLogTypes,
  sortBy,
  order
) => {
  const filter = filterLogTypes && `[${filterLogTypes.join(",")}]`;
  const url = filter
    ? `/fiscal-device-logs/${id}?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}&filterLogTypes=${filter}`
    : `/fiscal-device-logs/${id}?page=${page}&perPage=${perPage}&term=${term}&sortBy=${sortBy}&order=${order}`;
  return url;
};
//tax lables
export const GET_ALL_TAX_LABELS = "/tax";
export const ADD_TAX_LABEL = "/tax";

//users
export const FETCH_ALL_USERS = (page, perPage, term) =>
  `/users?page=${page}&perPage=${perPage}&term=${term}`;
export const SINGLE_USER = (id) => `/users/${id}`;
export const UPDATE_SINGLE_USER = "/users";
export const DELETE_SINGLE_USER = "/users";

//report
export const FETCH_COMPANY_REPORT_ADMIN = (
  companyId,
  from,
  to,
  type,
  includeDays
) =>
  `/revenue/company/${companyId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_BUSINESS_UNIT_REPORT_ADMIN = (
  businessUnitId,
  from,
  to,
  type,
  includeDays
) =>
  `/revenue/business-unit/${businessUnitId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_FISCAL_DEVICE_REPORT_ADMIN = (
  fiscalDeviceId,
  from,
  to,
  type,
  includeDays
) =>
  `/revenue/fiscal-device/${fiscalDeviceId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_VENDING_CONTROLLER_REPORT_ADMIN = (
  vendingControllerId,
  from,
  to,
  type,
  includeDays
) =>
  `/revenue/vending-controller/${vendingControllerId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

//report
export const FETCH_COMPANY_REPORT_USER = (
  companyId,
  from,
  to,
  type,
  includeDays
) =>
  `/user/revenue/company/${companyId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_BUSINESS_UNIT_REPORT_USER = (
  businessUnitId,
  from,
  to,
  type,
  includeDays
) =>
  `/user/revenue/v2/business-unit/${businessUnitId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_FISCAL_DEVICE_REPORT_USER = (
  fiscalDeviceId,
  from,
  to,
  type,
  includeDays
) =>
  `/user/revenue/fiscal-device/${fiscalDeviceId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_VENDING_CONTROLLER_REPORT_USER = (
  vendingControllerId,
  from,
  to,
  type,
  includeDays
) =>
  `/user/revenue/vending-controller/${vendingControllerId}?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_ALL_BUSINESS_UNITS_REPORTS = (from, to, type, includeDays) =>
  `/user/revenue/business-units?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const FETCH_ALL_BUSINESS_UNITS_REPORTS_CACHED = (
  from,
  to,
  type,
  includeDays
) =>
  `/user/revenue/business-units/cache/v2?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const EXPORT_ALL_BUSINESS_UNITS_REPORTS_CACHED = (
  from,
  to,
  type,
  includeDays
) =>
  `/user/revenue/business-units/export-cache/v2?from=${from}&to=${to}&type=${type}&includeDays=${includeDays}`;

export const CREATE_FISCAL_DEVICE_UPDATE =
  "/fiscal-devices-updates/schedule-update";

//analytics
export const FETCH_DEVICE_ANALYTICS = (deviceId, deviceType, from, to) =>
  `/analytics/uptime-downtime?from=${from}&to=${to}&deviceType=${deviceType}&deviceId=${deviceId}`;
export const FETCH_BUSYNESS_REPORT = (fiscalDeviceId, from, to) =>
  `/analytics/busyness?from=${from}&to=${to}&fiscalDeviceId=${fiscalDeviceId}`;
