import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import styles from "./RegisterScreen.module.scss";
import { toast } from "react-toastify";
import { isValidEmail } from "../../util/validations";
import { useDispatch, useSelector } from "react-redux";
import { login, signUp, signUpInfoReset } from "../../store/actions/auth";

const RegisterScreen = () => {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwrodConfirm, setPasswordConfirm] = useState("");

  const dispatch = useDispatch();
  const signUpInfo = useSelector((state) => state.auth.signUpInfo);
  const { attempt, success, error } = signUpInfo || [];

  useEffect(() => {
    if (success) {
      history.push("/verify");
      dispatch(signUpInfoReset());
    }
  }, [success, history]);

  useEffect(() => {
    toast.error(error);
    dispatch(signUpInfoReset());
  }, [error, dispatch]);

  const validateInput = () => {
    if (firstName === "") {
      toast.warning("Firstname cannot be empty");
      return false;
    }
    if (lastName === "") {
      toast.warning("Lastname cannot be empty");
      return false;
    }
    if (email === "") {
      toast.warning("Email cannot be empty");
      return false;
    } else if (!isValidEmail(email)) {
      toast.warning("Email wrong formatted");
      return false;
    }
    if (password === "") {
      toast.warning("Password cannot be empty!");
      return false;
    }
    if (password !== passwrodConfirm) {
      toast.warning("Passwords don't match");
      return false;
    }
    // if (phoneNumber !== "") {
    //   if (!isPhoneNumberValid()) {
    //     toast.warning("Phone number not valid");
    //     return false;
    //   }
    // }

    return true;
  };

  const onSubmit = () => {
    if (!validateInput()) return;
    dispatch(
      signUp({
        firstName,
        lastName,
        email,
        password,
        phone: phoneNumber,
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.gridHalves}>
        <div className={styles.contentWrapper}>
          <p className={styles.title}>Registrujte se</p>
          <p
            className={styles.loginText}
            onClick={() => history.push("/login")}
          >
            Imate nalog? Prijavite se.
          </p>
          <div className={styles.formWrapper}>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputWrapper}>
                <p>Ime</p>
                <Input name={"Ime"} onChange={setFirstName} value={firstName} />
              </div>
              <div className={styles.inputWrapper}>
                <p>Prezime</p>
                <Input
                  name={"Prezime"}
                  onChange={setLastName}
                  value={lastName}
                />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Email adresa</p>
                <Input
                  name={"Email adresa"}
                  onChange={setEmail}
                  value={email}
                />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Broj telefona</p>
                <Input
                  name={"Broj telefona"}
                  type={"number"}
                  onChange={setPhoneNumber}
                  value={phoneNumber}
                />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Lozinka</p>
                <Input
                  name={"Lozinka"}
                  onChange={setPassword}
                  type={"password"}
                  value={password}
                />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Potvrda lozinke</p>
                <Input
                  name={"Potvrda lozinke"}
                  onChange={setPasswordConfirm}
                  type={"password"}
                  value={passwrodConfirm}
                />
              </div>
            </div>
            <div className={styles.continueButtonWrap}>
              <Button name="Nastavi" onClick={onSubmit} disabled={attempt} />
            </div>
            <p className={styles.terms}>
              Klikom na dugme prihvatate uslove koriscenja.
            </p>
          </div>
        </div>
        <div className={styles.termsAndConditions}></div>
      </div>
    </div>
  );
};

export default RegisterScreen;
