import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditIcon, FilterIcon } from "../../../../assets";
import InfiniteScrollList from "../../../../components/infiniteScrollList/InfiniteScrollList";
import Input from "../../../../components/input/Input";
import { fetchActiveVendingControllers } from "../../../../store/actions/admin/vendingControllers";
import styles from "./ActiveVendingControllers.module.scss";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";

const ActiveVendingControllers = ({ openFilter, filterOffline }) => {
  const dispatch = useDispatch();
  const activeVendingControllers = useSelector(
    (state) => state.vendingControllers.activeVendingControllers
  );
  const history = useHistory();

  const { page, perPage, term } = activeVendingControllers || [];
  const [searchTerm, setSearchTerm] = useState("");
  const searchVendingControllers = _.debounce((searchTerm) => {
    dispatch(fetchActiveVendingControllers(1, 15, searchTerm, filterOffline));
  }, 600);

  useEffect(() => {
    searchVendingControllers(searchTerm);
  }, [searchTerm, dispatch]);

  useEffect(() => {
    dispatch(fetchActiveVendingControllers(page, perPage, term, filterOffline));
  }, [dispatch, filterOffline]);

  const loadMore = () => {
    dispatch(
      fetchActiveVendingControllers(
        page + 1,
        perPage,
        searchTerm,
        filterOffline
      )
    );
  };

  const renderItem = (vendingController) => {
    const lastTimeOnline = vendingController.lastOnlineAt
      ? moment(vendingController.lastOnlineAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";
    const isOnline =
      new Date().getTime() - vendingController.lastOnlineAt > 600 * 1000
        ? false
        : true;

    return (
      <div
        className={styles.itemWrap}
        onClick={() => {
          history.push(`/vending-controllers/${vendingController.id}`);
        }}
      >
        <div className={styles.itemInfoWrapper}>
          <a
            href={`https://sdf.rs/vending-controllers/${vendingController.id}`}
            target="_blank"
            className={styles.aTag}
          >
            <p
              className={classNames(styles.configLabel, styles.clickable)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {vendingController.macAddress}
            </p>
          </a>
          <p className={styles.itemTitle}>{vendingController.serialNumber}</p>
          <p>{vendingController?.fiscalDevice?.businessUnit?.company?.name}</p>
          <p>{vendingController?.fiscalDevice?.businessUnit?.address?.name}</p>
          <div className={styles.onlineWrap}>
            <div className={isOnline ? styles.online : styles.offline}></div>
            <p>{`Online: ${lastTimeOnline}`}</p>
          </div>
        </div>
        <img src={EditIcon} alt="edit" />
      </div>
    );
  };
  return (
    <div className={styles.content}>
      <div className={styles.topPart}>
        <p className={styles.title}>Aktivni</p>
        <div className={styles.flexEnd}>
          <div
            className={classNames(
              filterOffline ? styles.filterIconActive : styles.filterIconWrap
            )}
          >
            <img
              src={FilterIcon}
              className={styles.filterIcon}
              fill={"red"}
              alt="left"
              onClick={() => {
                openFilter();
              }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={searchTerm === "" && "Search"}
            />
          </div>
        </div>
      </div>
      <div className={styles.listWrapper}>
        <InfiniteScrollList
          resource={activeVendingControllers}
          renderItem={renderItem}
          loadMore={loadMore}
        />
      </div>
    </div>
  );
};

export default ActiveVendingControllers;
