import {
  DELETE_USER_ATTEMPT,
  DELETE_USER_ERROR,
  DELETE_USER_RESET_STATE,
  DELETE_USER_SUCCESS,
  FETCH_ALL_USERS_ATTEMPT,
  FETCH_ALL_USERS_ERROR,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_SINGLE_USER_ATTEMPT,
  FETCH_SINGLE_USER_ERROR,
  FETCH_SINGLE_USER_RESET_STATE,
  FETCH_SINGLE_USER_SUCCESS,
  UPDATE_SINGLE_USER_ATTEMPT,
  UPDATE_SINGLE_USER_ERROR,
  UPDATE_SINGLE_USER_RESET_STATE,
  UPDATE_SINGLE_USER_SUCCESS,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  users: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    page: 1,
    perPage: 15,
    total: null,
    term: "",
  },
  singleUser: {
    data: null,
    attempt: false,
    success: false,
    error: null,
  },
  updateUserInfo: {
    data: null,
    attempt: false,
    success: false,
    error: null,
  },
  deleteUserInfo: {
    data: null,
    attempt: false,
    success: false,
    error: null,
  },
};

const usersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS_ATTEMPT:
      return {
        ...state,
        users: {
          ...state.users,
          attempt: true,
        },
      };
    case FETCH_ALL_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          attempt: false,
          error: action.payload,
          success: false,
        },
      };
    case FETCH_ALL_USERS_SUCCESS:
      const { page, perPage, search, data, total } = action.payload.data;
      return {
        ...state,
        users: {
          ...state.users,
          page: page,
          perPage: perPage,
          search: search,
          data: page === 1 ? [...data] : [...state.users.data, ...data],
          attempt: false,
          success: true,
          error: false,
          total: total,
        },
      };
    case FETCH_SINGLE_USER_ATTEMPT:
      return {
        ...state,
        singleUser: {
          data: null,
          attempt: true,
          success: false,
          error: null,
        },
      };
    case FETCH_SINGLE_USER_ERROR:
      return {
        ...state,
        singleUser: {
          data: null,
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case FETCH_SINGLE_USER_SUCCESS:
      return {
        ...state,
        singleUser: {
          data: action?.payload?.data?.user,
          attempt: false,
          success: true,
          error: null,
        },
      };
    case FETCH_SINGLE_USER_RESET_STATE:
      return {
        ...state,
        singleUser: {
          data: null,
          attempt: false,
          error: null,
          success: false,
        },
      };
    case UPDATE_SINGLE_USER_ATTEMPT:
      return {
        ...state,
        updateUserInfo: {
          attempt: true,
          data: null,
          success: false,
          error: null,
        },
      };
    case UPDATE_SINGLE_USER_SUCCESS:
      return {
        ...state,
        updateUserInfo: {
          attempt: false,
          data: null,
          success: true,
          error: null,
        },
      };
    case UPDATE_SINGLE_USER_ERROR:
      return {
        ...state,
        updateUserInfo: {
          attempt: false,
          data: null,
          success: false,
          error: action.payload,
        },
      };
    case UPDATE_SINGLE_USER_RESET_STATE:
      return {
        ...state,
        updateUserInfo: {
          attempt: false,
          data: null,
          success: false,
          error: null,
        },
      };

    case DELETE_USER_ATTEMPT:
      return {
        ...state,
        deleteUserInfo: {
          attempt: true,
          data: null,
          success: false,
          error: null,
        },
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserInfo: {
          attempt: false,
          data: null,
          success: true,
          error: null,
        },
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserInfo: {
          attempt: false,
          data: null,
          success: false,
          error: action.payload,
        },
      };
    case DELETE_USER_RESET_STATE:
      return {
        ...state,
        deleteUserInfo: {
          attempt: false,
          data: null,
          success: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default usersReducer;
