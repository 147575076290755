import { combineReducers } from "redux";
import authReducer from "./authReducer";
import taxpayersReducer from "./admin/taxpayersReducer";
import fiscalDeviceReducer from "./admin/fiscalDeviceReducer";
import fiscalDeviceLogsReducer from "./admin/fiscalDeviceLogsReducer";
import vendingControllersReducer from "./admin/vendingControllers";
import taxReducer from "./admin/taxReducer";
import usersReducer from "./admin/users";
import businessUnitReducer from "./user/businessUnitReducer";

export default combineReducers({
  auth: authReducer,
  taxpayers: taxpayersReducer,
  fiscalDevices: fiscalDeviceReducer,
  vendingControllers: vendingControllersReducer,
  tax: taxReducer,
  users: usersReducer,
  businessUnitReports: businessUnitReducer,
  fiscalDeviceLogs: fiscalDeviceLogsReducer,
});
