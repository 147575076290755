import classNames from "classnames";
import React, { useRef, useState } from "react";
import SecondaryButton from "../../secondaryButton/SecondaryButton";
import styles from "./TableGraph.module.scss";

const TableGraph = ({ data, onExport }) => {
  const tableRef = useRef()
  const formatter = Intl.NumberFormat("en");

  const [tooltip, setTooltip] = useState(false);

  const renderTableHeader = () => {
    return data.array?.sort(compare).map((item) => {
      let headerTitle;
      switch (item.intervalType) {
        case "months":
          headerTitle = item.month + " " + item.year;
          break;
        case "days":
          headerTitle = item.date;
          break;
        case "hours":
          headerTitle = item.hour;
          break;
        default:
      }
      return (
        <>
          <div className={styles.headerCell}>
            <p className={styles.headerTitle}>{headerTitle.toUpperCase()}</p>
          </div>
        </>
      );
    });
  };

  const compare = (a, b) => {
    if (a.begin < b.begin) {
      return -1;
    }
    if (a.begin > b.begin) {
      return 1;
    }
    return 0;
  };

  const renderTableContent = () => {
    let totalKey = "";
    let taxKey = "";
    return data.businessUnitsNames?.map((name, index) => {
      totalKey = name + " promet";
      taxKey = name + " porez";
      return (
        <div
          className={classNames(styles.tableRow, index % 2 !== 0 && styles.odd)}
        >
          {renderBuRow(totalKey, taxKey, index % 2 !== 0, name, index)}
        </div>
      );
    });
  };

  const renderBuRow = (totalKey, taxKey, odd, name) => {
    return data.array.sort(compare).map((entry, index) => {
      return (
        <div
          className={classNames(styles.revenueCell, odd && styles.odd)}
          onClick={(e) => {
            setTooltip(totalKey + index);
            e.stopPropagation();
          }}
        >
          <p>{entry[totalKey] && formatter.format(entry[totalKey])}</p>
          <p>&nbsp;/&nbsp;</p>
          <p>{entry[taxKey] && formatter.format(entry[taxKey])}</p>
          {tooltip === totalKey + index && data.businessUnitsNames.length > 1 && (
            <div className={styles.tooltip}>
              <p className={styles.revenueTooltip}>
                {name.replaceAll('"', "")}
              </p>
              <p>{getDate(index)}</p>
              <p className={styles.revenueTooltip}>{`Promet: ${entry[totalKey] && formatter.format(entry[totalKey])
                }`}</p>
              <p className={styles.revenueTooltip}>{`Porez: ${entry[taxKey] && formatter.format(entry[taxKey])
                }`}</p>
            </div>
          )}
        </div>
      );
    });
  };

  const getDate = (index) => {
    const item = data.array[index];
    const headerTitle =
      item.intervalType === "months" ? item.month + " " + item.year : item.date;
    return headerTitle;
  };

  const renderBusinessunits = () => {
    return data.businessUnitsNames?.map((name, index) => {
      return (
        <>
          {index === 0 && (
            <div className={classNames(styles.cell, styles.odd)}>
              <p className={styles.buNameTitle}>POSLOVNI PROSTORI</p>
            </div>
          )}
          <div
            className={classNames(styles.buCell, index % 2 !== 0 && styles.odd)}
          >
            <p className={styles.buName}>{name}</p>
            <p></p>
          </div>
        </>
      );
    });
  };

  return (
    <div
      className={styles.tableWrap}
      ref={tableRef}
      onClick={(e) => {
        setTooltip(null);
        e.stopPropagation();
      }}
    >
      <div className={styles.tableContent}>
        <div className={styles.buWrap}>{renderBusinessunits()}</div>
        <div className={styles.headerAndContent}>
          <div className={styles.tableHeader}>{renderTableHeader()}</div>
          {renderTableContent()}
        </div>
      </div>
    </div>
  );
};

export default TableGraph;
