import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import styles from "./FiscalDeviceScreen.module.scss";
import classNames from "classnames";
import { CloseIcon, EditIcon, DeleteIcon } from "../../../assets";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSigngleFiscalDevice,
  getFiscalDeviceInvoices,
} from "../../../store/actions/user/fiscalDevice";
import { useHistory } from "react-router-dom";
import moment from "moment";
import UpdateFiscalDeviceModal from "../../../components/modals/updateFiscalDeviceModal/UpdateFiscalDeviceModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AreYouSureModal from "../../../components/modals/areYouSureModal/AreYouSureModal";
import ResourceList from "../../../components/resourceList/ResourceList";
import { fetchFiscalDeviceCurrentReport } from "../../../store/actions/user/userReport";
import { isMobile } from "react-device-detect";
import SecondaryButton from "../../../components/secondaryButton/SecondaryButton";
import fiscalDeviceService from "../../../apis/services/user/fiscalDeviceService";
import ExportInvoicesModal from "../../../components/modals/exportInvoicesModal/ExportInvoicesModal";

const HEADER_VALUES = [
  { label: "BROJ RAČUNA", key: "invoiceNumber", sortable: false },
  { label: "VREME NA PFR SERVERU", key: "sdcDateTime", sortable: false },
  { label: "UKUPAN IZNOS", key: "totalAmount", sortable: false },
  { label: "UKUPAN IZNOS POREZA", key: "taxTotalAmount", sortable: false },
  { label: "RAČUN" },
  // { label: "MAK ADRESA", key: "macAddress", sortable: true },
  // { label: "ONLINE", key: "lastOnlineAt", sortable: true },
];

const VENDING_CONTROLLERS_HEADER = [
  { label: "IME", key: "invoiceNumber", sortable: false },
  { label: "MAK ADRESA", key: "invoiceNumber", sortable: false },
  {
    label: "ZADNJI PUT VIĐEN",
    key: "lastOnlineAt",
  },
  {
    label: "LINK ZA PREUZIMANJE RAČUNA",
    key: "invoiceNumber",
    sortable: false,
  },
];

const FiscalDeviceScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateFiscalDeviceModalVisible, setUpdateFiscalDeviceModalVisible] =
    useState(false);
  const [deleteFiscalDeviceModalVisible, setDeleteFiscalDeviceModalVisible] =
    useState(false);
  const [exportInvoicesVisible, setExportInvoicesVisible] = useState(false);
  const maxTitleLen = isMobile ? 10 : 55;

  const fiscalDevice = useSelector(
    (state) => state.fiscalDevices.singleFiscalDevice
  );
  const updateFiscalDeviceInfo = useSelector(
    (state) => state.fiscalDevices.updateFiscalDeviceInfo
  );
  const deleteFiscalDeviceInfo = useSelector(
    (state) => state.fiscalDevices.deleteFiscalDeviceInfo
  );
  const invoices = useSelector(
    (state) => state.fiscalDevices.fiscalDeviceInvoices
  );
  const [copiedItemText, setCopiedItemText] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (id) {
      getFiscalDeviceInvoices(id, 1, 15, "");
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (fiscalDevice?.data) {
      dispatch(fetchFiscalDeviceCurrentReport(fiscalDevice.data.id));
    }
  }, [fiscalDevice]);

  const onLoadPage = (page) => {
    dispatch(
      getFiscalDeviceInvoices(id, page, invoices.perPage, searchTerm, "", "")
    );
  };
  const [searchTerm, setSearchTerm] = useState("");

  const onSort = (sortBy, order) => {
    dispatch(
      getFiscalDeviceInvoices(
        id,
        invoices.page,
        invoices.perPage,
        searchTerm,
        sortBy,
        order
      )
    );
  };

  useEffect(() => {
    dispatch(fetchSigngleFiscalDevice(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (copiedItemText) {
      setTimeout(() => {
        setCopiedItemText(null);
      }, 600);
    }
  }, [copiedItemText]);

  useEffect(() => {
    if (updateFiscalDeviceInfo.success) {
      toast.info("Sacuvano");
      setUpdateFiscalDeviceModalVisible(false);
      // dispatch(updateFiscalDeviceResetState());
    }
  }, [updateFiscalDeviceInfo.success]);

  useEffect(() => {
    if (updateFiscalDeviceInfo.error) {
      toast.error("Greska");
    }
  }, [updateFiscalDeviceInfo.error]);

  useEffect(() => {
    if (deleteFiscalDeviceInfo.success) {
      toast.info("Obrisano");
      setUpdateFiscalDeviceModalVisible(false);
      // dispatch(deleteFiscalDeviceResetState());
      history.goBack();
    }
  }, [deleteFiscalDeviceInfo.success]);

  useEffect(() => {
    if (deleteFiscalDeviceInfo.error) {
      toast.error("Greska");
    }
  }, [deleteFiscalDeviceInfo.error]);

  const renderAdditionalInfo = () => {
    if (fiscalDevice?.data) {
      const lastOnline = fiscalDevice.data?.lastOnlineAt;
      const lastTimeOnline = lastOnline
        ? moment(lastOnline).tz(moment.tz.guess()).format("MM-DD-YYYY HH:mm:ss")
        : "";

      const isOnline =
        new Date().getTime() - fiscalDevice?.data.lastOnlineAt > 120 * 1000
          ? false
          : true;

      return (
        <>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Ime:</p>
            </div>
            <div className={classNames(styles.configValue)}>
              <p>LPFR</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Mac adresa:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.macAddress}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Firma:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.businessUnit?.company?.name}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Poslovni prostor:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data.businessUnit?.address?.name}</p>
            </div>
          </div>
          {fiscalDevice?.data?.scUuid && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Bezbednosni element:</p>
              </div>
              <div className={styles.configValue}>
                <p>{fiscalDevice?.data?.scUuid}</p>
              </div>
            </div>
          )}
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Zadnji put vidjen</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(isOnline ? styles.online : styles.ofline)}
              />
              <p>{lastTimeOnline}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status čitača:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(
                  fiscalDevice?.data?.readerConnected
                    ? styles.online
                    : styles.ofline
                )}
              />
              <p>
                {fiscalDevice?.data?.readerConnected
                  ? "Čitač povezan"
                  : "Čitač nije povezan"}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status kartice:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(
                  fiscalDevice?.data?.cardConnected
                    ? styles.online
                    : styles.ofline
                )}
              />
              <p>
                {fiscalDevice?.data?.cardConnected
                  ? "Kartica povezana"
                  : "Kartica nije povezana"}
              </p>
            </div>
          </div>
          {fiscalDevice?.data?.localIpAddress && (
            <div className={styles.configRow}>
              <a
                href={`http://${fiscalDevice?.data?.localIpAddress}:5000/connect?ip=${fiscalDevice?.data?.localIpAddress}`}
                target="_blank"
                className={styles.aTag}
              >
                <p
                  className={classNames(styles.configLabel, styles.clickable)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Povezi se na LPFR i ESIR
                </p>
              </a>
            </div>
          )}
        </>
      );
    }
  };

  const renderResourceListRow = (invoice, index) => {
    let totalTaxAmount = 0;
    invoice?.taxItems.forEach((taxItem) => {
      totalTaxAmount += taxItem.amount;
    });

    const localDt = invoice.sdcDateTimeTimestamp
      ? moment(invoice.sdcDateTimeTimestamp)
          .tz(moment.tz.guess())
          .format("HH:mm:ss DD-MM-YYYY")
      : moment
          .utc(invoice.createdAt)
          .tz(moment.tz.guess())
          .format("HH:mm:ss DD-MM-YYYY");

    if (isMobile) {
      return (
        <div className={styles.listCard}>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Broj računa</p>
            <p className={styles.rowCardValue}>{invoice.invoiceNumber}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Vreme na PFR serveru</p>
            <p className={styles.rowCardValue}>{localDt}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Ukupan iznos</p>
            <p className={styles.rowCardValue}>{invoice.totalAmount}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Porez</p>
            <p className={styles.rowCardValue}>{totalTaxAmount}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Zurnal</p>
            <a
              href={invoice.convertedUrlPreview}
              target="_blank"
              className={styles.journalLink}
            >
              Prikaži zurnal
            </a>
          </div>
        </div>
      );
    } else
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={invoice.id}
          onClick={() => {}}
        >
          <div className={styles.cell}>
            <p>{invoice.invoiceNumber}</p>
          </div>
          <div className={styles.cell}>
            <p>{localDt}</p>
          </div>
          <div className={styles.cell}>
            <p>{invoice.totalAmount}</p>
          </div>
          <div className={styles.cell}>
            <p>{totalTaxAmount}</p>
          </div>
          <div className={styles.cell}>
            <a
              href={invoice.convertedUrlPreview}
              target="_blank"
              className={styles.journalLink}
            >
              Prikaži zurnal
            </a>
          </div>
        </div>
      );
  };

  const renderVedndingControllerListRow = (item, index) => {
    const isOnline =
      new Date().getTime() - parseInt(item.lastOnlineAt) < 5 * 60 * 1000;
    const lastTimeOnline = item.lastOnlineAt
      ? moment(item.lastOnlineAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    if (isMobile) {
      return (
        <div className={styles.listCard}>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Ime</p>
            <p className={styles.rowCardValue}>{item.name}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Mak adresa</p>
            <p className={styles.rowCardValue}>{item.macAddress}</p>
          </div>

          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Link za preuzimanje računa</p>
            <p
              className={classNames(styles.rowCardValue, styles.clickable)}
              onClick={() => {
                if (copiedItemText !== item.id) {
                  setCopiedItemText(item.id);
                  navigator.clipboard.writeText(
                    `https://sdf.rs/invoices/validation/${item.macAddress}`
                  );
                }
              }}
            >
              {copiedItemText === item.id ? "Kopirano" : `Kopiraj tekst`}
            </p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Zadnji put vidjen</p>
            <div className={styles.flexRow}>
              {isOnline ? (
                <div className={styles.online} />
              ) : (
                <div className={styles.ofline} />
              )}
              <p className={styles.rowCardValue}>{lastTimeOnline}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={item.id}
        >
          <div className={styles.cell}>
            <p>{item.name}</p>
          </div>
          <div className={styles.cell}>
            <p className={styles.clickable}>{item.macAddress}</p>
          </div>
          <div className={styles.cell}>
            <div className={styles.flexRow}>
              {isOnline ? (
                <div className={styles.online} />
              ) : (
                <div className={styles.ofline} />
              )}
              <p className={styles.rowCardValue}>{lastTimeOnline}</p>
            </div>
          </div>
          <div className={styles.cell}>
            <p
              className={styles.clickable}
              onClick={() => {
                if (copiedItemText !== item.id) {
                  setCopiedItemText(item.id);
                  navigator.clipboard.writeText(
                    `https://sdf.rs/invoices/validation/${item.macAddress}`
                  );
                }
              }}
            >
              {copiedItemText === item.id
                ? "Kopirano"
                : `https://sdf.rs/invoices/validation/${item.macAddress}`}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <DashboardLayout>
      <UpdateFiscalDeviceModal
        fiscalDevice={fiscalDevice?.data}
        modalOpen={updateFiscalDeviceModalVisible}
        onClose={() => setUpdateFiscalDeviceModalVisible(false)}
        attempt={updateFiscalDeviceInfo.attempt}
      />
      <ExportInvoicesModal
        fiscalDeviceId={fiscalDevice?.data?.id}
        modalOpen={exportInvoicesVisible}
        onClose={() => setExportInvoicesVisible(false)}
        attempt={updateFiscalDeviceInfo.attempt}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.titleWrap}>
            <div className={styles.closeIconWrap}>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => {
                  history.push("/fiscal-devices");
                }}
                className={styles.closeIcon}
              />
            </div>
            <p className={isMobile ? styles.titleMobile : styles.title}>
              {`Fiskalni uređaj`}
            </p>
          </div>
        </div>
        <div className={classNames(styles.gridHalves)}>
          <div className={classNames(styles.sectionMax, styles.maxHeight)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Informacije o uređaju</p>
            </div>
            <div className={classNames(styles.card)}>
              <div className={styles.cardContent}>{renderAdditionalInfo()}</div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.section, styles.marginTop)}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>Vending kontroleri</p>
          </div>
          {fiscalDevice?.data?.vendingControllers && (
            <ResourceList
              resource={{ data: fiscalDevice?.data?.vendingControllers }}
              onSort={() => {}}
              onLoadPage={() => {}}
              headerValues={VENDING_CONTROLLERS_HEADER}
              rowRender={renderVedndingControllerListRow}
            />
          )}
        </div>

        <div className={classNames(styles.section, styles.marginBottom)}>
          <div className={styles.sectionHeader}>
            <p className={styles.sectionTitle}>Računi</p>
            <div className={styles.wrap}>
              <SecondaryButton
                name={"Izvezi račune"}
                onClick={() => {
                  setExportInvoicesVisible(true);
                  // exportInvoices();
                }}
              />
            </div>
          </div>
          <div>
            <ResourceList
              rowRender={renderResourceListRow}
              onSort={onSort}
              onLoadPage={onLoadPage}
              headerValues={HEADER_VALUES}
              resource={invoices}
              minHeight
              showLoader={true}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default FiscalDeviceScreen;
