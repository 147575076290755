import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import styles from "./FiscalDevices.module.scss";
import _ from "lodash";
import Input from "../../../components/input/Input";
import { fetchFiscalDevices } from "../../../store/actions/user/fiscalDevice";
import moment from "moment-timezone";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import ResourceList from "../../../components/resourceList/ResourceList";
import { isMobile } from "react-device-detect";

const HEADER_VALUES = [
  { label: "NAZIV", key: "name", sortable: true },
  { label: "FIRMA", key: "company", sortable: true },
  { label: "POSLOVNI PROSTOR", key: "businessUnit", sortable: true },
  { label: "ONLINE", key: "lastOnlineAt", sortable: true },
];

const FiscalDevices = () => {
  const dispatch = useDispatch();
  const fiscalDevices = useSelector(
    (state) => state.fiscalDevices.fiscalDevices
  );
  const { page, perPage, sortBy, order } = fiscalDevices || [];
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const searchFicalDevices = _.debounce((searchTerm) => {
    dispatch(fetchFiscalDevices(1, 10, searchTerm));
  }, 600);

  useEffect(() => {
    dispatch(fetchFiscalDevices(1, 10, ""));
  }, [dispatch]);

  const onLoadPage = (page) => {
    dispatch(fetchFiscalDevices(page, perPage, searchTerm, sortBy, order));
  };

  const onSort = (sortBy, order) => {
    dispatch(fetchFiscalDevices(page, perPage, searchTerm, sortBy, order));
  };

  const onSelectFiscalDevice = (fiscalDevice) => {
    history.push(`/fiscal-devices/${fiscalDevice.id}`);
  };

  const getDeviceParamValue = (
    device,
    key,
    lastTimeOnline,
    createdAt,
    isOnline
  ) => {
    switch (key) {
      case HEADER_VALUES[0].key:
        return "LPFR";
      case HEADER_VALUES[1].key:
        return device.businessUnit.company.name;
      case HEADER_VALUES[2].key:
        return device.businessUnit.address.name;
      case HEADER_VALUES[3].key:
        return (
          <div className={styles.cell}>
            <div
              className={classNames(isOnline ? styles.online : styles.ofline)}
            />
            <p>{lastTimeOnline}</p>
          </div>
        );
      default:
        return "";
    }
  };

  const renderResourceListRow = (device, index) => {
    const lastTimeOnline = device.lastOnlineAt
      ? moment(device.lastOnlineAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    const isOnline =
      new Date().getTime() - device.lastOnlineAt > 300 * 1000 ? false : true;

    const createdAt = device.createdAt
      ? moment(device.createdAt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
      : "";

    if (isMobile) {
      return (
        <div
          className={styles.card}
          onClick={() => onSelectFiscalDevice(device)}
        >
          {HEADER_VALUES.map((value) => (
            <div className={styles.rowCard}>
              <p className={styles.rowCardTitle}>
                {value.label.charAt(0).toUpperCase() +
                  value.label.slice(1).toLowerCase()}
              </p>
              <p className={styles.rowCardValue}>
                {getDeviceParamValue(
                  device,
                  value.key,
                  lastTimeOnline,
                  createdAt,
                  isOnline
                )}
              </p>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={device.id}
          onClick={() => onSelectFiscalDevice(device)}
        >
          <div className={styles.cell}>
            <a
              href={`https://sdf.rs/fiscal-devices/${device.id}`}
              target="_blank"
              className={styles.aTag}
            >
              <p
                className={classNames(styles.configLabel, styles.clickable)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                LPFR
              </p>
            </a>
          </div>
          <div className={styles.cell}>
            <p>{device.businessUnit.company.name}</p>
          </div>
          <div className={styles.cell}>
            <p>{device.businessUnit.address?.name}</p>
          </div>
          <div className={styles.cell}>
            <div
              className={classNames(isOnline ? styles.online : styles.ofline)}
            />
            <p>{lastTimeOnline}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.content}>
        <div className={styles.topPart}>
          <p className={isMobile ? styles.titleMobile : styles.title}>
            Fiskalni uređaji
          </p>
          <div className={styles.flexEnd}>
            <div className={styles.inputWrapper}>
              <Input
                onChange={(e) => {
                  setSearchTerm(e);
                  searchFicalDevices(e);
                }}
                value={searchTerm}
                placeholder={searchTerm === "" && "Search"}
              />
            </div>
          </div>
        </div>
        <div className={styles.listWrapper}>
          <ResourceList
            resource={fiscalDevices}
            headerValues={HEADER_VALUES}
            onLoadPage={onLoadPage}
            onSort={onSort}
            rowRender={renderResourceListRow}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default FiscalDevices;
