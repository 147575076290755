import {
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_SUCCESS,
  LOGIN_ATTEMPT,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SIGN_UP_INFO_RESET,
  SIGN_UP_ATTEMPT,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  LOGIN_INFO_RESET,
  POST_COMPANY_INFROMATION_ATTEMPT,
  POST_COMPANY_INFROMATION_SUCCESS,
  POST_COMPANY_INFROMATION_ERROR,
  POST_COMPANY_INFROMATION_RESET,
  VERIFY_EMAIL_ATTEMPT,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_INFO_RESET,
  RESEND_CODE_ATTEMPT,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  RESEND_CODE_INFO_RESET,
  RESET_PASSWORD_RESET_INFO,
  VERIFY_PASSWORD_CODE_RESET_INFO,
  UPDATE_PASSWORD_RESET_INFO,
  RESET_PASSWORD_REQUEST_ATTEMPT,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_ERROR,
  VERIFY_PASSWORD_RESET_CODE_ATTEMPT,
  VERIFY_PASSWORD_RESET_CODE_SUCCESS,
  VERIFY_PASSWORD_RESET_CODE_ERROR,
  UPDATE_PASSWORD_ATTEMPT,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from "../actionTypes/actionTypes";
import authService from "../../apis/services/authService";

export const login = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_ATTEMPT });
      const { accessToken, refreshToken } = await authService.login(
        credentials
      );
      localStorage.setItem("AUTH_TOKEN", accessToken);
      localStorage.setItem("REFRESH_TOKEN", refreshToken);
      const { user } = await authService.fetchUserInfo();
      dispatch({ type: LOGIN_SUCCESS, payload: { user } });
    } catch (err) {
      dispatch({ type: LOGIN_ERROR, payload: err });
    }
  };
};

export const fetchUserInfo = () => {
  return async (dispatch) => {
    try {
      const { user } = await authService.fetchUserInfo();
      dispatch({ type: FETCH_USER_INFO_SUCCESS, payload: { user } });
    } catch (err) {
      dispatch({ type: FETCH_USER_INFO_ERROR });
    }
  };
};

export const signUp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGN_UP_ATTEMPT });
      await authService.signUp(data);
      dispatch({ type: SIGN_UP_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: SIGN_UP_ERROR, payload: err });
    }
  };
};

export const postCompanyInformation = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: POST_COMPANY_INFROMATION_ATTEMPT });
      const { company } = await authService.postCompanyInformation(data);
      dispatch({
        type: POST_COMPANY_INFROMATION_SUCCESS,
        payload: { company },
      });
    } catch (err) {
      dispatch({ type: POST_COMPANY_INFROMATION_ERROR, payload: err });
    }
  };
};

export const verifyEmail = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_EMAIL_ATTEMPT });
      await authService.verifyEmail(data);
      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
      });
    } catch (err) {
      dispatch({ type: VERIFY_EMAIL_ERROR, payload: err });
    }
  };
};

export const resendCode = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESEND_CODE_ATTEMPT });
      await authService.resendCode(data);
      dispatch({
        type: RESEND_CODE_SUCCESS,
      });
    } catch (err) {
      dispatch({ type: RESEND_CODE_ERROR, payload: err });
    }
  };
};

export const requestPasswordReset = (email) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST_ATTEMPT });
      await authService.requestPasswordReset(email);
      dispatch({
        type: RESET_PASSWORD_REQUEST_SUCCESS,
        payload: email,
      });
    } catch (err) {
      dispatch({ type: RESET_PASSWORD_REQUEST_ERROR, payload: err });
    }
  };
};

export const verifyPasswordResetCode = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_PASSWORD_RESET_CODE_ATTEMPT });
      await authService.verifyPasswordResetCode(data);
      dispatch({
        type: VERIFY_PASSWORD_RESET_CODE_SUCCESS,
        payload: data.code,
      });
    } catch (err) {
      dispatch({ type: VERIFY_PASSWORD_RESET_CODE_ERROR, payload: err });
    }
  };
};

export const updatePassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PASSWORD_ATTEMPT });
      await authService.updatePassword(data);
      dispatch({ type: UPDATE_PASSWORD_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_PASSWORD_ERROR, payload: err });
    }
  };
};

export const loginInfoReset = () => ({ type: LOGIN_INFO_RESET });
export const signUpInfoReset = () => ({ type: SIGN_UP_INFO_RESET });
export const postCompanyInfoReset = () => ({
  type: POST_COMPANY_INFROMATION_RESET,
});
export const verifyEmailInfoReset = () => ({
  type: VERIFY_EMAIL_INFO_RESET,
});
export const resendCodeInfoReset = () => ({
  type: RESEND_CODE_INFO_RESET,
});
export const resetPasswordRequestInfo = () => ({
  type: RESET_PASSWORD_RESET_INFO,
});
export const resetPasswordVerifyInfo = () => ({
  type: VERIFY_PASSWORD_CODE_RESET_INFO,
});
export const resetUpdatePasswordInfo = () => ({
  type: UPDATE_PASSWORD_RESET_INFO,
});
