import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import styles from "./BusinessUnitReportModal.module.scss";
import Button from "../../button/Button";
import classNames from "classnames";
import userReportService from "../../../apis/services/user/userReportService";
import moment from "moment";
import { toast } from "react-toastify";
import { CloseIcon } from "../../../assets";

const BusinessUnitReportModal = ({ modalOpen, onClose, businessUnit }) => {
  const [singleDate, setSingleDate] = useState(null);

  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [singleDateCalcAttempt, setSingleDateCalcAttempt] = useState(false);
  const [intervalCalcAttempt, setIntervalCalcAttempt] = useState(false);

  const minDate = moment()
    .tz("Europe/Belgrade")
    .set("year", 2022)
    .set("month", 3)
    .set("date", 1);
  const maxDate = moment().tz("Europe/Belgrade").add(1, "day");

  const [singleDateTotalRevenueAmount, setSingleDateTotalRevenueAmount] =
    useState(null);
  const [singleDateTotalTaxAmount, setSingleDateTotalTaxAmount] =
    useState(null);

  const [intervalRevenueAmount, setIntervalRevenueAmount] = useState(null);
  const [intervalTaxAmount, setIntervalTaxAmount] = useState(null);
  const calcDayReport = async () => {
    const dayBegin = moment(new Date(singleDate))
      .tz("Europe/Belgrade")
      .startOf("day");
    const dayEnd = moment(new Date(singleDate))
      .tz("Europe/Belgrade")
      .endOf("day");
    setSingleDateCalcAttempt(true);
    const resp = await userReportService.fetchBusinessUnitDayReport(
      businessUnit.id,
      dayBegin.format("x"),
      dayEnd.format("x"),
      "days",
      false
    );
    setSingleDateCalcAttempt(false);
    setSingleDateTotalRevenueAmount(Math.round(resp.data.totalAmount, 2));
    setSingleDateTotalTaxAmount(Math.round(resp.data.totalTaxAmount, 2));
  };

  const calcIntervalReport = async () => {
    const dayBegin = moment(new Date(dateBegin)).tz("Europe/Belgrade");
    const dayEnd = moment(new Date(dateEnd)).tz("Europe/Belgrade");

    const dayBeginMillis = parseInt(dayBegin.format("x"));
    const dayEndMillis = parseInt(dayEnd.format("x"));
    if (dayEndMillis < dayBeginMillis) {
      toast.error("Neispravan unos");
      return;
    }
    if (dayBegin < minDate || dayEnd > maxDate) {
      toast.error("Neispravan unos");
      return;
    }
    setIntervalCalcAttempt(true);
    const resp = await userReportService.fetchBusinessUnitDayReport(
      businessUnit.id,
      dayBeginMillis,
      dayEndMillis,
      "days",
      false
    );
    setIntervalCalcAttempt(false);
    setIntervalRevenueAmount(Math.round(resp.data.totalAmount, 2));
    setIntervalTaxAmount(Math.round(resp.data.totalTaxAmount, 2));
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        onClose();
        setDateBegin(null);
        setDateEnd(null);
        setSingleDate(null);
        setIntervalRevenueAmount(null);
        setIntervalTaxAmount(null);
        setSingleDateTotalTaxAmount(null);
        setSingleDateTotalRevenueAmount(null);
      }}
    >
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <div className={styles.header}>
            <div className={styles.closeIconWrap}>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => {
                  onClose();
                  setDateBegin(null);
                  setDateEnd(null);
                  setSingleDate(null);
                  setIntervalRevenueAmount(null);
                  setIntervalTaxAmount(null);
                  setSingleDateTotalTaxAmount(null);
                  setSingleDateTotalRevenueAmount(null);
                }}
                className={styles.closeIcon}
              />
            </div>
            <p className={styles.title}>{` ${businessUnit?.address?.name}`}</p>
          </div>
          <div className={styles.content}>
            <div className={styles.singleDayRevenue}>
              <p className={styles.title}>Promet na dan</p>
              <div
                className={classNames(styles.inputWrapper, styles.marginTop)}
              >
                <p>Odaberi datum</p>
                <input
                  type="date"
                  min={minDate.format("YYYY-MM-DD")}
                  max={maxDate.format("YYYY-MM-DD")}
                  className={styles.dateInput}
                  onChange={(e) => setSingleDate(e.target.value)}
                />
              </div>
              <div className={styles.revenueWrapSm}>
                {singleDateTotalRevenueAmount && (
                  <p>{`${moment(new Date(singleDate))
                    .tz("Europe/Belgrade")
                    .format("DD-MM-YYYY")}`}</p>
                )}
                <p>
                  {singleDateTotalRevenueAmount
                    ? `Promet: ${singleDateTotalRevenueAmount}`
                    : ""}
                </p>
                <p>
                  {singleDateTotalTaxAmount
                    ? `Porez: ${singleDateTotalTaxAmount}`
                    : ""}
                </p>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  name={singleDateCalcAttempt ? "Izračunavanje" : "Izračunaj"}
                  onClick={() => {
                    if (singleDate === null || singleDateCalcAttempt) {
                      return;
                    }
                    calcDayReport();
                  }}
                  showLoader={singleDateCalcAttempt}
                  disabled={singleDate === null || singleDateCalcAttempt}
                />
              </div>
            </div>
            <div className={styles.intervalRevenueReport}>
              <p className={styles.title}>Promet između datuma</p>
              <div
                className={classNames(styles.datePickersWrap, styles.marginTop)}
              >
                <div className={styles.inputWrapperSm}>
                  <p>Pocetak</p>
                  <input
                    type="datetime-local"
                    className={styles.dateInput}
                    min={minDate.format("YYYY-MM-DDTHH:mm:ss")}
                    max={maxDate.format("YYYY-MM-DDTHH:mm:ss")}
                    onChange={(e) => {
                      setDateBegin(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.inputWrapperSm}>
                  <p>Kraj</p>
                  <input
                    type="datetime-local"
                    min={minDate.format("YYYY-MM-DDTHH:mm:ss")}
                    max={maxDate.format("YYYY-MM-DDTHH:mm:ss")}
                    className={styles.dateInput}
                    onChange={(e) => {
                      setDateEnd(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.revenueWrap}>
                {intervalRevenueAmount && (
                  <p>{`${moment(new Date(dateBegin))
                    .tz("Europe/Belgrade")
                    .format("DD-MM-YYYY HH:mm")} - ${moment(new Date(dateEnd))
                    .tz("Europe/Belgrade")
                    .format("DD-MM-YYYY HH:mm")}`}</p>
                )}
                <p>
                  {intervalRevenueAmount
                    ? `Promet: ${intervalRevenueAmount}`
                    : ""}
                </p>
                <p>{intervalTaxAmount ? `Porez: ${intervalTaxAmount}` : ""}</p>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  name={intervalCalcAttempt ? "Izračunavanje" : "Izračunaj"}
                  onClick={() => {
                    if (
                      dateBegin === null ||
                      dateEnd === null ||
                      intervalCalcAttempt
                    ) {
                      return;
                    }
                    calcIntervalReport();
                  }}
                  showLoader={intervalCalcAttempt}
                  disabled={
                    dateBegin === null ||
                    dateEnd === null ||
                    intervalCalcAttempt
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BusinessUnitReportModal;
