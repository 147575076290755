import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import Button from "../../button/Button";
import styles from "./UpdateUserModal.module.scss";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Input from "../../input/Input";
import taxPayersService from "../../../apis/services/admin/taxPayersService";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../store/actions/admin/users";

const ROLE_OPTIONS = [
  { label: "ADMIN", value: "ADMIN" },
  { label: "USER", value: "USER" },
];

const UpdateUserModal = ({ modalOpen, onClose, user }) => {
  const [companyOptions, setCopmanyOptions] = useState([]);
  const [companies, setCompanies] = useState(null);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setRole({
        value: user.role,
        label: user.role,
      });
      const userCompanies = [];
      user?.companies?.forEach((company) => {
        userCompanies.push({
          value: company,
          label: company?.name,
        });
      });
      setCompanies(userCompanies);
    }
  }, [user]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await taxPayersService.fetchTaxpayers(1, 15, "");
      const { data } = response;
      const retItems = data?.data?.map((item) => {
        return {
          value: item,
          label: item.name,
        };
      });
      setCopmanyOptions(retItems);
    };
    fetchCompanies();
  }, []);

  const onUpdateUser = () => {
    const updatedCompanies = companies.map((copmany) => copmany.value);
    const userCompanies = user.companies;
    let addedCopmanies = updatedCompanies?.filter(
      (x) => !userCompanies?.includes(x)
    );
    let deletedCopmanies = userCompanies?.filter(
      (x) => !updatedCompanies?.includes(x)
    );

    user.updatedCompanies =
      addedCopmanies?.length > 0 || deletedCopmanies.length > 0
        ? companies.map((copmany) => copmany.value)
        : null;
    user.role = role.value;
    dispatch(updateUser(user));
  };

  const onSearchCompanies = _.debounce(async (searchTerm) => {
    const response = await taxPayersService.fetchTaxpayers(1, 15, searchTerm);
    const { data } = response;
    const retItems = data?.data?.map((item) => {
      return {
        value: item,
        label: item.name,
      };
    });
    return retItems;
  }, 100);

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <p className={styles.title}>Izmeni korisnika</p>
          <div className={styles.inputWrapper}>
            <p>Ime</p>
            <Input value={user?.firstName} disabled={true} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Prezime</p>
            <Input value={user?.lastName} disabled={true} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Email</p>
            <Input value={user?.email} disabled={true} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Firma</p>
            <AsyncSelect
              onInputChange={(e) => {
                onSearchCompanies(e);
              }}
              loadOptions={onSearchCompanies}
              defaultOptions={companyOptions}
              onChange={(e) => {
                setCompanies(e);
              }}
              isMulti={true}
              value={companies}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <p>Tip korisnika</p>
            <Select
              options={ROLE_OPTIONS}
              isMulti={false}
              value={role}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
              onChange={(e) => {
                setRole(e);
              }}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button name={"Sačuvaj"} onClick={onUpdateUser} />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateUserModal;
