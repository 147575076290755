import taxLabelsService from "../../../apis/services/admin/taxLabelsService";
import {
  ADD_TAX_LABEL_ATTEMPT,
  ADD_TAX_LABEL_ERROR,
  ADD_TAX_LABEL_RESET_STATE,
  ADD_TAX_LABEL_SUCCESS,
  DELETE_TAX_LABEL_ATTEMPT,
  DELETE_TAX_LABEL_ERROR,
  DELETE_TAX_LABEL_RESET_STATE,
  DELETE_TAX_LABEL_SUCCESS,
  GET_ALL_TAX_LABELS,
} from "../../actionTypes/actionTypes";

export const getAllTaxLabels = () => {
  return async (dispatch) => {
    const { data } = await taxLabelsService.fetchTaxLabels();
    dispatch({ type: GET_ALL_TAX_LABELS, payload: { data } });
    try {
    } catch (err) {}
  };
};

export const addTaxLabel = (taxLabel) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_TAX_LABEL_ATTEMPT });
      const { data } = await taxLabelsService.addTaxLabel(taxLabel);
      dispatch({ type: ADD_TAX_LABEL_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: ADD_TAX_LABEL_ERROR, payload: err });
    }
  };
};

export const deleteTaxRate = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_TAX_LABEL_ATTEMPT });
      const { data } = await taxLabelsService.deleteTaxRate(id);
      dispatch({ type: DELETE_TAX_LABEL_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: DELETE_TAX_LABEL_ERROR, payload: err });
    }
  };
};

export const addTaxLabelResetState = () => {
  return async (dispatch) => {
    dispatch({ type: ADD_TAX_LABEL_RESET_STATE });
  };
};

export const deleteTaxLabelResetState = () => {
  return async (dispatch) => {
    dispatch({ type: DELETE_TAX_LABEL_RESET_STATE });
  };
};
