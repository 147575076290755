import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import {
  requestPasswordReset,
  resetPasswordRequestInfo,
} from "../../../store/actions/auth";
import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const requestPasswordResetInfo = useSelector(
    (state) => state.auth.requestPasswordResetInfo
  );

  const { success, attempt, error } = requestPasswordResetInfo || [];

  const validateInput = () => {
    if (email === "") {
      toast.warning("Email cannot be empty");
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const valid = validateInput();
    if (!valid) return;
    dispatch(requestPasswordReset({ email }));
  };

  useEffect(() => {
    if (success) {
      history.push("/forgot-password/code");
    }
  }, [success, dispatch, history]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetPasswordRequestInfo());
    }
  }, [error, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.gridHalves}>
        <div className={styles.contentWrapper}>
          <p className={styles.title}>Resetuj password</p>
          <div className={styles.formWrapper}>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Email</p>
                <Input name={"Email"} onChange={setEmail} value={email} />
              </div>
            </div>
            <div className={styles.continueButtonWrap} onClick={() => {}}>
              <Button name="Nastavi" disabled={attempt} onClick={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
