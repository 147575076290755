import React from "react";
import ReactPinField from "react-pin-field";
import "./CodeInput.css";
const CodeInput = ({ onComplete, disabled }) => {
  return (
    <div>
      <ReactPinField
        class="pin-field"
        type="text"
        autocapitalize="off"
        // disabled={disabled}
        size={6}
        autocorrect="off"
        length={6}
        autocomplete="off"
        onComplete={onComplete}
        inputmode="number"
      />
    </div>
  );
};

export default CodeInput;
