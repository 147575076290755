import fiscalDeviceLogService from "../../../apis/services/admin/fiscalDeviceLogService";
import {
  FETCH_FISCAL_DEVICE_LOGS_ATTEMPT,
  FETCH_FISCAL_DEVICE_LOGS_SUCCESS,
} from "../../actionTypes/actionTypes";

export const fetchFiscalDeviceLogs = (
  id,
  page,
  perPage,
  term,
  filterLogTypes,
  sortBy,
  order
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_FISCAL_DEVICE_LOGS_ATTEMPT });
      const { data } = await fiscalDeviceLogService.fetchFiscalDeviceLogs(
        id,
        page,
        perPage,
        term,
        filterLogTypes,
        sortBy,
        order
      );
      dispatch({
        type: FETCH_FISCAL_DEVICE_LOGS_SUCCESS,
        payload: {
          data: {
            ...data,
            term,
            filterLogTypes,
            sortBy,
            order,
          },
        },
      });
    } catch (err) {}
  };
};
