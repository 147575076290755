import fiscalDeviceService from "../../../apis/services/user/fiscalDeviceService";
import {
  FETCH_FISCAL_DEVICES_ATTEMPT,
  FETCH_FISCAL_DEVICES_ERROR,
  FETCH_FISCAL_DEVICES_SUCCESS,
  FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT,
  FETCH_SINGLE_FISCAL_DEVICE_ERROR,
  FETCH_SINGLE_FISCAL_DEVICE_SUCCESS,
  GET_FISCAL_DEVICE_INVOICES_ATTEMPT,
  GET_FISCAL_DEVICE_INVOICES_ERROR,
  GET_FISCAL_DEVICE_INVOICES_SUCCESS,
} from "../../actionTypes/actionTypes";

export const fetchFiscalDevices = (
  page,
  perPage,
  term,
  sortBy = "",
  order = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_FISCAL_DEVICES_ATTEMPT });
      const { data } = await fiscalDeviceService.fetchFiscalDevices(
        page,
        perPage,
        term,
        sortBy,
        order
      );
      dispatch({
        type: FETCH_FISCAL_DEVICES_SUCCESS,
        payload: { data: { ...data, order, sortBy } },
      });
    } catch (err) {
      dispatch({ type: FETCH_FISCAL_DEVICES_ERROR, payload: err });
    }
  };
};

export const getFiscalDeviceInvoices = (
  id,
  page,
  perPage,
  term,
  sortBy = "",
  order = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FISCAL_DEVICE_INVOICES_ATTEMPT });
      const { data } = await fiscalDeviceService.fetchFiscalDeviceInvoices(
        id,
        page,
        perPage,
        term,
        sortBy,
        order
      );
      dispatch({
        type: GET_FISCAL_DEVICE_INVOICES_SUCCESS,
        payload: { data: { ...data, order, sortBy } },
      });
    } catch (err) {
      dispatch({ type: GET_FISCAL_DEVICE_INVOICES_ERROR, payload: err });
    }
  };
};

export const fetchSigngleFiscalDevice = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT });
      const { data } = await fiscalDeviceService.fetchSignleFiscalDevice(id);
      dispatch({ type: FETCH_SINGLE_FISCAL_DEVICE_SUCCESS, payload: { data } });
    } catch (err) {
      dispatch({ type: FETCH_SINGLE_FISCAL_DEVICE_ERROR, payload: err });
    }
  };
};
