import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { FETCH_FISCAL_DEVICE_LOGS } from "../../urls";

class FiscalDeviceLogService {
  fetchFiscalDeviceLogs = async (
    id,
    page,
    perPage,
    term,
    filterLogTypes,
    sortBy,
    order
  ) => {
    try {
      const response = await apiBase.get(
        FETCH_FISCAL_DEVICE_LOGS(
          id,
          page,
          perPage,
          term,
          filterLogTypes,
          sortBy,
          order
        )
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching fiscal device logs");
    }
  };
}
export default new FiscalDeviceLogService();
