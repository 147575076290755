export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_INFO_RESET = "LOGIN_INFO_RESET";
export const LOGOUT = "LOGOUT";

export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";

export const SIGN_UP_ATTEMPT = "SIGN_UP_ATTEMPT";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_UP_INFO_RESET = "SIGN_UP_INFO_RESET";

export const POST_COMPANY_INFROMATION_ATTEMPT =
  "POST_COMPANY_INFROMATION_ATTEMPT";
export const POST_COMPANY_INFROMATION_SUCCESS =
  "POST_COMPANY_INFROMATION_SUCCESS";
export const POST_COMPANY_INFROMATION_ERROR = "POST_COMPANY_INFROMATION_ERROR";
export const POST_COMPANY_INFROMATION_RESET = "POST_COMPANY_INFROMATION_RESET";

export const VERIFY_EMAIL_ATTEMPT = "VERIFY_EMAIL_ATTEMPT";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";
export const VERIFY_EMAIL_INFO_RESET = "VERIFY_EMAIL_INFO_RESET";

export const RESEND_CODE_ATTEMPT = "RESEND_CODE_ATTEMPT";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_ERROR = "RESEND_CODE_ERROR";
export const RESEND_CODE_INFO_RESET = "RESEND_CODE_INFO_RESET";

export const RESET_PASSWORD_REQUEST_ATTEMPT = "RESET_PASSWORD_REQUEST_ATTEMPT";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR";
export const RESET_PASSWORD_RESET_INFO = "RESET_PASSWORD_RESET_INFO";

export const VERIFY_PASSWORD_RESET_CODE_ATTEMPT =
  "VERIFY_PASSWORD_RESET_CODE_ATTEMPT";
export const VERIFY_PASSWORD_RESET_CODE_SUCCESS =
  "VERIFY_PASSWORD_RESET_CODE_SUCCESS";
export const VERIFY_PASSWORD_RESET_CODE_ERROR =
  "VERIFY_PASSWORD_RESET_CODE_ERROR";
export const VERIFY_PASSWORD_CODE_RESET_INFO =
  "VERIFY_PASSWORD_CODE_RESET_INFO";

export const UPDATE_PASSWORD_ATTEMPT = "UPDATE_PASSWORD_ATTEMPT";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
export const UPDATE_PASSWORD_RESET_INFO = "UPDATE_PASSWORD_RESET_INFO";

export const FETCH_TAXPAYERS_ATTEMPT = "FETCH_TAXPAYERS_ATTEMPT";
export const FETCH_TAXPAYERS_SUCCESS = "FETCH_TAXPAYERS_SUCCESS";
export const FETCH_TAXPAYERS_ERROR = "FETCH_TAXPAYERS_ERROR";
export const FETCH_TAXPAYERS_RESET_INFO = "FETCH_TAXPAYERS_RESET_INFO";

export const FETCH_FISCAL_DEVICES_ATTEMPT = "FETCH_FISCAL_DEVICES_ATTEMPT";
export const FETCH_FISCAL_DEVICES_SUCCESS = "FETCH_FISCAL_DEVICES_SUCCESS";
export const FETCH_FISCAL_DEVICES_ERROR = "FETCH_FISCAL_DEVICES_ERROR";
export const FETCH_FISCAL_DEVICES_RESET_INFO =
  "FETCH_FISCAL_DEVICES_RESET_INFO";

export const SELECT_FISCAL_DEVICE = "SELECT_FISCAL_DEVICE";
export const SELECT_ALL_FISCAL_DEVICES = "SELECT_ALL_FISCAL_DEVICES";

export const FETCH_PENDING_VENDING_CONTROLLERS_ATTEMPT =
  "FETCH_PENDING_VENDING_CONTROLLERS_ATTEMPT";
export const FETCH_PENDING_VENDING_CONTROLLERS_SUCCESS =
  "FETCH_PENDING_VENDING_CONTROLLERS";
export const FETCH_PENDING_VENDING_CONTROLLERS_ERROR =
  "FETCH_PENDING_VENDING_CONTROLLERS_ERROR";

export const FETCH_ACTIVE_VENDING_CONTROLLERS_ATTEMPT =
  "FETCH_ACTIVE_VENDING_CONTROLLERS_ATTEMPT";
export const FETCH_ACTIVE_VENDING_CONTROLLERS_SUCCESS =
  "FETCH_ACTIVE_VENDING_CONTROLLERS_SUCCESS";
export const FETCH_ACTIVE_VENDING_CONTROLLERS_ERROR =
  "FETCH_ACTIVE_VENDING_CONTROLLERS_ERROR";

export const ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ATTEMPT =
  "ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ATTEMPT";
export const ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_SUCCESS =
  "ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_SUCCESS";
export const ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ERROR =
  "ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_ERROR";
export const ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_RESET =
  "ADD_VENDING_CONTROLLER_TO_FISCAL_DEVICE_RESET";

export const ADD_ITEM_TO_VENDING_CONTROLLER_ATTEMPT =
  "ADD_ITEM_TO_VENDING_CONTROLLER_ATTEMPT";
export const ADD_ITEM_TO_VENDING_CONTROLLER_SUCCESS =
  "ADD_ITEM_TO_VENDING_CONTROLLER_SUCCESS";
export const ADD_ITEM_TO_VENDING_CONTROLLER_ERROR =
  "ADD_ITEM_TO_VENDING_CONTROLLER_ERROR";
export const ADD_ITEM_TO_VENDING_CONTROLLER_RESET =
  "ADD_ITEM_TO_VENDING_CONTROLLER_RESET";

export const GET_VENDING_CONTROLLER_ATTEMPT = "GET_VENDING_CONTROLLER_ATTEMPT";
export const GET_VENDING_CONTROLLER_SUCCESS = "GET_VENDING_CONTROLLER_SUCCESS";
export const GET_VENDING_CONTROLLER_ERROR = "GET_VENDING_CONTROLLER_ERROR";
export const GET_VENDING_CONTROLLER_RESET_STATE =
  "GET_VENDING_CONTROLLER_RESET_STATE";

export const GET_VENDING_CONTROLLER_INVOICES_ATTEMPT =
  "GET_VENDING_CONTROLLER_INVOICES_ATTEMPT";
export const GET_VENDING_CONTROLLER_INVOICES_SUCCESS =
  "GET_VENDING_CONTROLLER_INVOICES_SUCCESS";
export const GET_VENDING_CONTROLLER_INVOICES_ERROR =
  "GET_VENDING_CONTROLLER_INVOICES_ERROR";

export const GET_FISCAL_DEVICE_INVOICES_ATTEMPT =
  "GET_FISCAL_DEVICE_INVOICES_ATTEMPT";
export const GET_FISCAL_DEVICE_INVOICES_SUCCESS =
  "GET_FISCAL_DEVICE_INVOICES_SUCCESS";
export const GET_FISCAL_DEVICE_INVOICES_ERROR =
  "GET_FISCAL_DEVICE_INVOICES_ERROR";

export const GET_ALL_TAX_LABELS = "GET_ALL_TAX_LABELS";
export const ADD_TAX_LABEL_ATTEMPT = "ADD_TAX_LABEL_ATTEMPT";
export const ADD_TAX_LABEL_SUCCESS = "ADD_TAX_LABEL_SUCCESS";
export const ADD_TAX_LABEL_ERROR = "ADD_TAX_LABEL_ERROR";
export const ADD_TAX_LABEL_RESET_STATE = "ADD_TAX_LABEL_RESET_STATE";

export const DELETE_TAX_LABEL_ATTEMPT = "DELETE_TAX_LABEL_ATTEMPT";
export const DELETE_TAX_LABEL_SUCCESS = "DELETE_TAX_LABEL_SUCCESS";
export const DELETE_TAX_LABEL_ERROR = "DELETE_TAX_LABEL_ERROR";
export const DELETE_TAX_LABEL_RESET_STATE = "DELETE_TAX_LABEL_RESET_STATE";

export const DELETE_ITEM_ATTEMPT = "DELETE_ITEM_ATTEMPT";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_ERROR = "DELETE_ITEM_ERROR";
export const DELETE_ITEM_RESET_STATE = "DELETE_ITEM_RESET_STATE";

export const UPDATE_ITEM_ATTEMPT = "UPDATE_ITEM_ATTEMPT";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_ERROR = "UPDATE_ITEM_ERROR";
export const UPDATE_ITEM_RESET_STATE = "UPDATE_ITEM_RESET_STATE";

export const UPDATE_VC_ATTEMPT = "UPDATE_VC_ATTEMPT";
export const UPDATE_VC_SUCCESS = "UPDATE_VC_SUCCESS";
export const UPDATE_VC_ERROR = "UPDATE_VC_ERROR";
export const UPDATE_VC_RESET_STATE = "UPDATE_VC_RESET_STATE";

export const FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT =
  "FETCH_SINGLE_FISCAL_DEVICE_ATTEMPT";
export const FETCH_SINGLE_FISCAL_DEVICE_SUCCESS =
  "FETCH_SINGLE_FISCAL_DEVICE_SUCCESS";
export const FETCH_SINGLE_FISCAL_DEVICE_ERROR =
  "FETCH_SINGLE_FISCAL_DEVICE_ERROR";
export const FETCH_SINGLE_FISCAL_DEVICE_RESET_STATE =
  "FETCH_SINGLE_FISCAL_DEVICE_RESET_STATE";

export const DELETE_SINGLE_FISCAL_DEVICE_ATTEMPT =
  "DELETE_SINGLE_FISCAL_DEVICE_ATTEMPT";
export const DELETE_SINGLE_FISCAL_DEVICE_SUCCESS =
  "DELETE_SINGLE_FISCAL_DEVICE_SUCCESS";
export const DELETE_SINGLE_FISCAL_DEVICE_ERROR =
  "DELETE_SINGLE_FISCAL_DEVICE_ERROR";
export const DELETE_SINGLE_FISCAL_DEVICE_RESET_STATE =
  "DELETE_SINGLE_FISCAL_DEVICE_RESET_STATE";

export const UPDATE_FISCAL_DEVICE_ATTEMPT = "UPDATE_FISCAL_DEVICE_ATTEMPT";
export const UPDATE_FISCAL_DEVICE_SUCCESS = "UPDATE_FISCAL_DEVICE_SUCCESS";
export const UPDATE_FISCAL_DEVICE_ERROR = "UPDATE_FISCAL_DEVICE_ERROR";
export const UPDATE_FISCAL_DEVICE_RESET_STATE =
  "UPDATEE_FISCAL_DEVICE_RESET_STATE";

export const FETCH_ALL_USERS_ATTEMPT = "FETCH_ALL_USERS_ATTEMPT";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_ERROR = "FETCH_ALL_USERS_ERROR";

export const FETCH_SINGLE_USER_ATTEMPT = "FETCH_SINGLE_USER_ATTEMPT";
export const FETCH_SINGLE_USER_SUCCESS = "FETCH_SINGLE_USER_SUCCESS";
export const FETCH_SINGLE_USER_ERROR = "FETCH_SINGLE_USER_ERROR";
export const FETCH_SINGLE_USER_RESET_STATE = "FETCH_SINGLE_USER_RESET_STATE";

export const UPDATE_SINGLE_USER_ATTEMPT = "UPDATE_SINGLE_USER_ATTEMPT";
export const UPDATE_SINGLE_USER_SUCCESS = "UPDATE_SINGLE_USER_SUCCESS";
export const UPDATE_SINGLE_USER_ERROR = "UPDATE_SINGLE_USER_ERROR";
export const UPDATE_SINGLE_USER_RESET_STATE = "UPDATE_SINGLE_USER_RESET_STATE";

export const DELETE_USER_ATTEMPT = "DELETE_USER_ATTEMPT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const DELETE_USER_RESET_STATE = "DELETE_USER_RESET_STATE";

export const FETCH_BUSINESS_UNIT_CURRENT_REPORT_ATTEMPT =
  "FETCH_BUSINESS_UNIT_CURRENT_REPORT_ATTEMPT";
export const FETCH_BUSINESS_UNIT_CURRENT_REPORT_SUCCESS =
  "FETCH_BUSINESS_UNIT_CURRENT_REPORT_SUCCESS";
export const FETCH_BUSINESS_UNIT_CURRENT_REPORT_ERROR =
  "FETCH_BUSINESS_UNIT_CURRENT_REPORT_ERROR";
export const FETCH_BUSINESS_UNIT_CURRENT_REPORT_RESET_STATE =
  "FETCH_BUSINESS_UNIT_CURRENT_REPORT";

export const FETCH_BUSINESS_UNIT_YEAR_REPORT_ATTEMPT =
  "FETCH_BUSINESS_UNIT_YEAR_REPORT_ATTEMPT";
export const FETCH_BUSINESS_UNIT_YEAR_REPORTT_SUCCESS =
  "FETCH_BUSINESS_UNIT_YEAR_REPORTT_SUCCESS";
export const FETCH_BUSINESS_UNIT_YEAR_REPORT_ERROR =
  "FETCH_BUSINESS_UNIT_YEAR_REPORT_ERROR";
export const FETCH_BUSINESS_UNIT_YEAR_REPORT_STATE =
  "FETCH_BUSINESS_UNIT_YEAR_REPORT_STATE";

export const FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ERROR";
export const FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_RESET_STATE =
  "FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_RESET_STATE";

export const FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ERROR";
export const FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_RESET_STATE =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_RESET_STATE";

export const FETCH_FISCAL_DEVICE_CURRENT_REPORT_ATTEMPT =
  "FETCH_FISCAL_DEVICE_CURRENT_REPORT_ATTEMPT";
export const FETCH_FISCAL_DEVICE_CURRENT_REPORT_SUCCESS =
  "FETCH_FISCAL_DEVICE_CURRENT_REPORT_SUCCESS";
export const FETCH_FISCAL_DEVICE_CURRENT_REPORT_ERROR =
  "FETCH_FISCAL_DEVICE_CURRENT_REPORT_ERROR";
export const FETCH_FISCAL_DEVICE_CURRENT_REPORT_RESET_STATE =
  "FETCH_FISCAL_DEVICE_CURRENT_REPORT_RESET_STATE";

export const FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ERROR";
export const FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_RESET_STATE =
  "FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_RESET_STATE";

export const FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ERROR";
export const FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_RESET_STATE =
  "FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_RESET_STATE";

export const FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ERROR";
export const FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_RESET_STATE =
  "FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_RESET_STATE";

export const FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_24H_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_24H_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ERROR";

export const FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ATTEMPT =
  "FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ATTEMPT";
export const FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_SUCCESS =
  "FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_SUCCESS";
export const FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ERROR =
  "FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ERROR";
export const FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_RESET_STATE =
  "FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_RESET_STATE";

export const FETCH_FISCAL_DEVICE_LOGS_ATTEMPT =
  "FETCH_FISCAL_DEVICE_LOGS_ATTEMPT";
export const FETCH_FISCAL_DEVICE_LOGS_SUCCESS =
  "FETCH_FISCAL_DEVICE_LOGS_SUCCESS";
export const FETCH_FISCAL_DEVICE_LOGS_RESET_STATE =
  "FETCH_FISCAL_DEVICE_LOGS_RESET_STATE";
