import React from "react";
import styles from "./Checkbox.module.scss";

const Checkbox = ({ onCheck, checked }) => {
  return (
    <input
      type={"checkbox"}
      className={styles.checkbox}
      onClick={onCheck}
      checked={checked}
    />
  );
};

export default Checkbox;
