import classNames from "classnames";
import React from "react";
import styles from "./SecondaryButton.module.scss";

const SecondaryButton = ({ name, onClick, disabled, sm }) => {
  return (
    <div
      className={classNames(styles.wrap, sm && styles.sm)}
      onClick={onClick}
      disabled={disabled}
    >
      <p className={styles.buttonText}>{name}</p>
    </div>
  );
};

export default SecondaryButton;
