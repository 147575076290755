import React from "react";
import styles from "./BusinessUnitReportCard.module.scss";

const BusinessUnitReportCard = ({
  businessUnitName,
  total,
  taxTotal,
  address,
  onClick,
}) => {
  return (
    <div className={styles.cardWrap} onClick={onClick}>
      <div>
        <p className={styles.buName}>
          {businessUnitName.replace("poslovna jedinica", "")}
        </p>
      </div>
      <div>
        <p className={styles.revenue}>{`Danšnji promet: ${total}`}</p>
        <p className={styles.revenue}>{`Današnji porez: ${taxTotal}`}</p>
      </div>
    </div>
  );
};

export default BusinessUnitReportCard;
