import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "@material-ui/core";
import Input from "../../input/Input";
import Button from "../../button/Button";
import styles from "./AddTaxRateModal.module.scss";
import { addTaxLabel } from "../../../store/actions/admin/tax";

const AddTaxRateModal = ({ modalOpen, onClose, attempt }) => {
  const dispatch = useDispatch();
  const [label, setLabel] = useState(null);
  const [rate, setRate] = useState(null);

  const onAddTaxRate = () => {
    dispatch(addTaxLabel({ label, rate }));
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        <div className={styles.modalInner}>
          <div className={styles.inputWrapper}>
            <p>Labela</p>
            <Input onChange={setLabel} value={label} />
          </div>
          <div className={styles.inputWrapper}>
            <p>Procenat</p>
            <Input onChange={setRate} value={rate} type="number" />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button name={"Sačuvaj"} onClick={onAddTaxRate} disabled={attempt} />
        </div>
      </div>
    </Modal>
  );
};

export default AddTaxRateModal;
