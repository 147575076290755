import axios from "axios";
import config from "../config";
import authService from "./services/authService";
import store from "../index";
import { LOGOUT } from "../store/actionTypes/actionTypes";

const MIN_REFRESH_INTERVAL = 60000; // 60 seconds

const axiosApiClient = axios.create({
  baseURL: `${config.api.baseUrl}`,
});

axiosApiClient.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("AUTH_TOKEN");
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;
      let currentRefreshToken = localStorage.getItem("REFRESH_TOKEN");
      
      const lastRefreshTime = parseInt(
        localStorage.getItem('LAST_REFRESH_TIME') || '0'
      );
      const currentTime = new Date().getTime();
      
      if (
        currentRefreshToken &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        currentTime - lastRefreshTime > MIN_REFRESH_INTERVAL
      ) {
        originalRequest._retry = true;
        localStorage.setItem('LAST_REFRESH_TIME', currentTime.toString());
        
        const refreshResponse = await authService.refreshToken(currentRefreshToken);
        if (refreshResponse.status !== 200) {
          return Promise.reject(error);
        }
        
        const { accessToken, refreshToken } = refreshResponse.data.data;
        localStorage.setItem("REFRESH_TOKEN", refreshToken);
        localStorage.setItem("AUTH_TOKEN", accessToken);
        
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } else if (error.response.status === 400) {
        localStorage.removeItem("REFRESH_TOKEN");
        localStorage.removeItem("AUTH_TOKEN");
        store.dispatch({ type: LOGOUT });
      }
      return Promise.reject(error);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        localStorage.removeItem("REFRESH_TOKEN");
        localStorage.removeItem("AUTH_TOKEN");
        store.dispatch({ type: LOGOUT });
      }
      return Promise.reject(err);
    }
  }
);

export default axiosApiClient;
