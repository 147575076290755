import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import ResourceList from "../../../../components/resourceList/ResourceList";
import { fetchFiscalDeviceLogs } from "../../../../store/actions/admin/fiscalDeviceLogs";
import styles from "./FiscalDeviceLogsScreen.module.scss";

const HEADER_VALUES = [
  { label: "TIP", key: "logType", sortable: true },
  { label: "PORUKA", key: "logMessage", sortable: true },
  { label: "VREME", key: "timestamp", sortable: true },
];

const LOG_TYPE_OPTIONS = [
  { label: "Info", value: "INFO" },
  { label: "Greška kartice", value: "CARD_ERROR" },
  { label: "PCSC greška", value: "PCSC_ERROR" },
  { label: "Item error", value: "VENDING_ITEM_ERROR" },
  { label: "Događaji kartice", valueArr: ["CARD_REMOVAL", "CARD_RE_INSERT"] },
  {
    label: "Događaji čitača",
    valueArr: ["CARD_READER_REMOVAL", "CARD_READER_RE_INSERT"],
  },
  { label: "Greška fiskalizacije", valye: "INVOICE_SUBMIT_ERROR" },
];

const FiscalDeviceLogsScreen = ({ id }) => {
  const dispatch = useDispatch();
  const fiscalDeviceLogs = useSelector(
    (state) => state.fiscalDeviceLogs.fiscalDeviceLogs
  );

  const [selectedLogTypeOptions, setSelectedLogTypeOptions] = useState([]);

  const onLoadPage = (page) => {
    dispatch(
      fetchFiscalDeviceLogs(
        id,
        page,
        perPage,
        term,
        filterLogTypes,
        sortBy,
        order
      )
    );
  };

  const onSort = (sortBy, order) => {
    dispatch(
      fetchFiscalDeviceLogs(
        id,
        page,
        perPage,
        term,
        filterLogTypes,
        sortBy,
        order
      )
    );
  };

  useEffect(() => {
    if (selectedLogTypeOptions) {
      let filter = selectedLogTypeOptions.map((type) =>
        type.valueArr
          ? type.valueArr.map((subType) => '"' + subType + '"')
          : '"' + type.value + '"'
      );
      if (filter.length === 0) {
        filter = null;
      }
      dispatch(
        fetchFiscalDeviceLogs(id, page, perPage, term, filter, sortBy, order)
      );
    }
  }, [selectedLogTypeOptions]);

  const { page, perPage, sortBy, order, term, filterLogTypes } =
    fiscalDeviceLogs || [];

  const getLogParamValue = (log, key) => {
    switch (key) {
      case HEADER_VALUES[0].key:
        return log.logType;
      case HEADER_VALUES[1].key:
        return log.logMessage;
      case HEADER_VALUES[2].key:
        return moment(+log.timestamp)
          .tz("Europe/Belgrade")
          .format("DD-MM-YYYY HH:mm:ss");
      default:
        return "";
    }
  };

  const renderResourceListRow = (log, index) => {
    if (isMobile) {
      return (
        <div className={styles.card}>
          {HEADER_VALUES.map((value) => (
            <div className={styles.rowCard}>
              <p className={styles.rowCardTitle}>
                {value.label.charAt(0).toUpperCase() +
                  value.label.slice(1).toLowerCase()}
              </p>
              <p className={styles.rowCardValue}>
                {getLogParamValue(log, value.key)}
              </p>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={log.id}
        >
          <div className={styles.cell}>
            <p>{log.logType}</p>
          </div>
          <div className={styles.cell}>
            <p>{log.logMessage}</p>
          </div>
          <div className={styles.cell}>
            <p>
              {moment(+log.timestamp)
                .tz("Europe/Belgrade")
                .format("DD-MM-YYYY HH:mm:ss")}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.filterLogTypeWrap}>
        {LOG_TYPE_OPTIONS.map((type) => (
          <div
            key={type.value}
            className={classNames(
              styles.logTypeOption,
              selectedLogTypeOptions.indexOf(type) !== -1 &&
                styles.logTypeOptionSelected
            )}
            onClick={() => {
              if (selectedLogTypeOptions.indexOf(type) === -1) {
                setSelectedLogTypeOptions([...selectedLogTypeOptions, type]);
              } else {
                const newArr = [];
                for (let item of selectedLogTypeOptions) {
                  if (item.label !== type.label) {
                    newArr.push(item);
                  }
                }
                setSelectedLogTypeOptions(newArr);
              }
            }}
          >
            <p>{type.label}</p>
          </div>
        ))}
      </div>
      <div className={styles.listWrap}>
        <ResourceList
          resource={fiscalDeviceLogs}
          selectable={false}
          headerValues={HEADER_VALUES}
          onLoadPage={onLoadPage}
          onSort={onSort}
          minHeight
          showLoader={true}
          rowRender={renderResourceListRow}
        />
      </div>
    </div>
  );
};

export default FiscalDeviceLogsScreen;
