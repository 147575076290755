import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import InfiniteScrollList from "../../../components/infiniteScrollList/InfiniteScrollList";
import Input from "../../../components/input/Input";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import { fetchAllUsers } from "../../../store/actions/admin/users";
import styles from "./UsersScreen.module.scss";

const UsersScreen = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchAllUsers(1, 15, ""));
  }, [dispatch]);

  const users = useSelector((state) => state.users.users);
  const { page, perPage } = users || [];
  const history = useHistory();

  const loadMore = () => {
    dispatch(fetchAllUsers(page + 1, perPage, searchTerm));
  };
  const searchUsers = _.debounce((searchTerm) => {
    dispatch(fetchAllUsers(1, 15, searchTerm));
  }, 600);

  const renderItem = (user) => {
    if (!user) return null;
    if (user.role === "ADMIN" && user.firstName === "Admin") return null;
    return (
      <div
        onClick={() => {
          history.push(`/users/${user.id}`);
        }}
        className={styles.itemWrap}
        key={user?.id}
      >
        <div className={styles.itemInfoWrapper}>
          <p
            className={styles.itemTitle}
          >{`${user.firstName} ${user.lastName}`}</p>
          <p className={styles.itemSubtitle}>{user.email}</p>
          <p className={styles.itemSubtitle}>{user.role}</p>
          <p className={styles.itemSubtitle}>{user.company?.name}</p>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <div className={styles.content}>
        <div className={styles.topPart}>
          <p className={styles.title}>Korisnici</p>
          <div className={styles.flexEnd}>
            <div className={styles.inputWrapper}>
              <Input
                onChange={(e) => {
                  setSearchTerm(e);
                  searchUsers(e);
                }}
                value={searchTerm}
                placeholder={searchTerm === "" && "Search"}
              />
            </div>
          </div>
        </div>
        <div className={styles.listWrapper}>
          <InfiniteScrollList
            resource={users}
            loadMore={() => {
              loadMore();
            }}
            renderItem={renderItem}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UsersScreen;
