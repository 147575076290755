import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { FETCH_BUSYNESS_REPORT, FETCH_DEVICE_ANALYTICS } from "../../urls";

class AnalyticsService {
  getDeviceAnalytics = async (deviceType, from, to, deviceId) => {
    try {
      const response = await apiBase.get(
        FETCH_DEVICE_ANALYTICS(deviceId, deviceType, from, to)
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError("Error ");
    }
  };

  getBusynessReport = async (fiscalDeviceId, from, to) => {
    try {
      const response = await apiBase.get(
        FETCH_BUSYNESS_REPORT(fiscalDeviceId, from, to)
      );
      if (response.status !== 200) throw new Error();
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError("Error ");
    }
  };
}

export default new AnalyticsService();
