import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./InfiniteScrollList.module.scss";

const InfiniteScrollList = ({ resource, loadMore, renderItem }) => {
  const { data, total, page, perPage } = resource || [];
  return (
    <InfiniteScroll
      dataLength={data.length}
      scrollThreshold={0.1}
      next={loadMore}
      hasMore={data.length < total}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {data.map((item, index) => {
        return renderItem(item);
      })}
    </InfiniteScroll>
  );
};

export default InfiniteScrollList;
