import {
  LOGIN_ATTEMPT,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_SUCCESS,
  LOGIN_INFO_RESET,
  SIGN_UP_ATTEMPT,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_INFO_RESET,
  POST_COMPANY_INFROMATION_ATTEMPT,
  POST_COMPANY_INFROMATION_SUCCESS,
  POST_COMPANY_INFROMATION_ERROR,
  POST_COMPANY_INFROMATION_RESET,
  VERIFY_EMAIL_ATTEMPT,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_INFO_RESET,
  RESEND_CODE_ATTEMPT,
  RESEND_CODE_ERROR,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_INFO_RESET,
  RESET_PASSWORD_REQUEST_ATTEMPT,
  RESET_PASSWORD_REQUEST_ERROR,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_RESET_INFO,
  VERIFY_PASSWORD_RESET_CODE_ATTEMPT,
  VERIFY_PASSWORD_RESET_CODE_SUCCESS,
  VERIFY_PASSWORD_RESET_CODE_ERROR,
  VERIFY_PASSWORD_CODE_RESET_INFO,
  UPDATE_PASSWORD_ATTEMPT,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_RESET_INFO,
  LOGOUT,
} from "../actionTypes/actionTypes";

const INIT_STATE = {
  loggedIn: null,
  logout: false,
  user: null,
  logInInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  signUpInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  postCompanyInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  verifyEmailInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  resendCodeInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  requestPasswordResetInfo: {
    attempt: false,
    success: false,
    error: null,
    email: null,
  },
  verifyPasswordResetCodeInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  updatePasswordInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  signUpData: null,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        logout: true,
      };
    case LOGIN_ATTEMPT:
      return {
        ...state,
        logInInfo: {
          ...state.logInInfo,
          attempt: true,
          success: false,
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        logInInfo: {
          ...state.logInInfo,
          error: action.payload.message,
          attempt: false,
        },
        loggedIn: false,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        logout: false,
        logInInfo: {
          ...state.logInInfo,
          success: true,
          attempt: false,
        },
        loggedIn: true,
        user: action.payload.user,
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loggedIn: true,
      };
    case FETCH_USER_INFO_ERROR:
      return {
        ...state,
        user: null,
        loggedIn: false,
      };
    case LOGIN_INFO_RESET:
      return {
        ...state,
        logInInfo: {
          success: false,
          error: false,
          attempt: false,
        },
      };
    case SIGN_UP_INFO_RESET:
      return {
        ...state,
        signUpInfo: {
          success: false,
          error: false,
          attempt: false,
        },
      };
    case SIGN_UP_ATTEMPT:
      return {
        ...state,
        signUpInfo: {
          ...state.signUpInfo,
          attemp: true,
        },
      };
    case SIGN_UP_SUCCESS:
      const { email, firstName, lastName, password } = action.payload;
      return {
        ...state,
        signUpInfo: {
          ...state.signUpInfo,
          attemp: false,
          success: true,
        },
        signUpData: { email, firstName, lastName, password },
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUpInfo: {
          ...state.signUpInfo,
          error: action.payload.message,
        },
      };
    case POST_COMPANY_INFROMATION_ATTEMPT:
      return {
        ...state,
        postCompanyInfo: {
          ...state.postCompanyInfo,
          attempt: true,
          error: null,
          success: false,
        },
      };
    case POST_COMPANY_INFROMATION_SUCCESS:
      return {
        ...state,
        postCompanyInfo: {
          ...state.postCompanyInfo,
          success: true,
          attempt: false,
          error: null,
        },
      };
    case POST_COMPANY_INFROMATION_ERROR:
      return {
        ...state,
        postCompanyInfo: {
          ...state.postCompanyInfo,
          error: action.payload.message,
          attempt: false,
          success: false,
        },
      };
    case POST_COMPANY_INFROMATION_RESET:
      return {
        ...state,
        postCompanyInfo: {
          error: null,
          attempt: false,
          success: false,
        },
      };
    case VERIFY_EMAIL_ATTEMPT:
      return {
        ...state,
        verifyEmailInfo: {
          attempt: true,
          error: null,
          success: false,
        },
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verifyEmailInfo: {
          attempt: false,
          error: action.payload.message,
          success: false,
        },
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailInfo: {
          attempt: false,
          error: null,
          success: true,
        },
      };
    case VERIFY_EMAIL_INFO_RESET:
      return {
        ...state,
        verifyEmailInfo: {
          attempt: false,
          error: null,
          success: false,
        },
      };
    case RESEND_CODE_ERROR:
      return {
        ...state,
        resendCodeInfo: {
          attempt: false,
          error: action.payload.message,
          success: false,
        },
      };
    case RESEND_CODE_SUCCESS:
      return {
        ...state,
        resendCodeInfo: {
          attempt: false,
          error: null,
          success: true,
        },
      };
    case RESEND_CODE_ATTEMPT:
      return {
        ...state,
        resendCodeInfo: {
          attempt: true,
          error: null,
          success: false,
        },
      };
    case RESEND_CODE_INFO_RESET:
      return {
        ...state,
        resendCodeInfo: {
          attempt: false,
          error: null,
          success: false,
        },
      };
    case RESET_PASSWORD_REQUEST_ATTEMPT:
      return {
        ...state,
        requestPasswordResetInfo: {
          attempt: true,
          success: false,
          error: null,
          email: null,
        },
      };

    case RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        requestPasswordResetInfo: {
          attempt: false,
          success: false,
          error: action.payload.message,
          email: null,
        },
      };

    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        requestPasswordResetInfo: {
          attempt: false,
          success: true,
          error: null,
          email: action.payload.email,
        },
      };
    case RESET_PASSWORD_RESET_INFO:
      return {
        ...state,
        requestPasswordResetInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case VERIFY_PASSWORD_RESET_CODE_ATTEMPT:
      return {
        ...state,
        verifyPasswordResetCodeInfo: {
          attempt: true,
          success: false,
          error: null,
          code: null,
        },
      };

    case VERIFY_PASSWORD_RESET_CODE_SUCCESS:
      return {
        ...state,
        verifyPasswordResetCodeInfo: {
          attempt: false,
          success: true,
          error: null,
          code: action.payload,
        },
      };

    case VERIFY_PASSWORD_RESET_CODE_ERROR:
      return {
        ...state,
        verifyPasswordResetCodeInfo: {
          attempt: false,
          success: false,
          error: action.payload.message,
          code: null,
        },
      };

    case VERIFY_PASSWORD_CODE_RESET_INFO:
      return {
        ...state,
        verifyPasswordResetCodeInfo: {
          attempt: false,
          success: false,
          error: null,
          code: null,
        },
      };

    case UPDATE_PASSWORD_ATTEMPT:
      return {
        ...state,
        updatePasswordInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordInfo: {
          attempt: false,
          success: true,
          error: null,
        },
      };

    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordInfo: {
          attempt: false,
          success: false,
          error: action.payload.message,
        },
      };

    case UPDATE_PASSWORD_RESET_INFO:
      return {
        ...state,
        updatePasswordInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
