import userReportService from "../../../apis/services/user/userReportService";
import {
  FETCH_BUSINESS_UNIT_CURRENT_REPORT_ATTEMPT,
  FETCH_BUSINESS_UNIT_CURRENT_REPORT_SUCCESS,
  FETCH_BUSINESS_UNIT_CURRENT_REPORT_ERROR,
  FETCH_FISCAL_DEVICE_CURRENT_REPORT_ATTEMPT,
  FETCH_FISCAL_DEVICE_CURRENT_REPORT_SUCCESS,
  FETCH_FISCAL_DEVICE_CURRENT_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ERROR,
  FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ATTEMPT,
  FETCH_ALL_BUSINESS_UNITS_24H_REPORT_SUCCESS,
  FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ERROR,
} from "../../actionTypes/actionTypes";
import moment from "moment";
import {
  getBusinessUnitsGraphReport,
  renameDuplicateBusinessUnitsNames,
} from "../../../util/report";

export const fetchBusinessUnitCurrentReport = (businessUnitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_BUSINESS_UNIT_CURRENT_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");

      const { data } = await userReportService.fetchBusinessUnitDayReport(
        businessUnitId,
        dayBegin,
        dayEnd,
        "day",
        false
      );
      dispatch({
        type: FETCH_BUSINESS_UNIT_CURRENT_REPORT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_BUSINESS_UNIT_CURRENT_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnitsYearReports = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .subtract(1, "year")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("months").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        "months",
        false
      );
      const { array, businessUnitsNames } = getBusinessUnitsGraphReport(data);
      const report = {
        array,
        businessUnitsNames,
      };
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_SUCCESS,
        payload: report,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_YEAR_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnitsCurrentDayReport = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        "days",
        false
      );
      renameDuplicateBusinessUnitsNames(data.report);
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_CURRENT_DAY_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchFiscalDeviceCurrentReport = (fiscalDeviceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_FISCAL_DEVICE_CURRENT_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");

      const { data } = await userReportService.fetchFiscalDeviceDayReport(
        fiscalDeviceId,
        dayBegin,
        dayEnd,
        "day",
        false
      );
      dispatch({
        type: FETCH_FISCAL_DEVICE_CURRENT_REPORT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_FISCAL_DEVICE_CURRENT_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnitsLast6MonthsReports = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .subtract(6, "months")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("months").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,

        dayEnd,
        "months",
        false
      );
      const { array, businessUnitsNames } = getBusinessUnitsGraphReport(data);
      const report = {
        array,
        businessUnitsNames,
      };
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_SUCCESS,
        payload: report,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_LAST_6_MONTHS_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnitsLast7DaysReprot = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .subtract(7, "days")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        "days",
        false
      );
      const { array, businessUnitsNames } = getBusinessUnitsGraphReport(data);
      const report = {
        array,
        businessUnitsNames,
      };
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_SUCCESS,
        payload: report,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_LAST_7_DAYS_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnitsLast30DaysReprot = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ATTEMPT });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("day")
        .subtract(1, "month")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        "days",
        false
      );
      const { array, businessUnitsNames } = getBusinessUnitsGraphReport(data);
      const report = {
        array,
        businessUnitsNames,
      };
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_SUCCESS,
        payload: report,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_LAST_30_DAYS_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnitsCurrentMonthDaysReprot = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ATTEMPT,
      });
      const dayBegin = moment()
        .tz("Europe/Belgrade")
        .startOf("month")
        .format("x");
      const dayEnd = moment().tz("Europe/Belgrade").endOf("day").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        "days",
        false
      );
      const { array, businessUnitsNames } = getBusinessUnitsGraphReport(data);
      const report = {
        array,
        businessUnitsNames,
      };
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_SUCCESS,
        payload: report,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_CURRENT_MONTH_DAYS_REPORT_ERROR,
        payload: err,
      });
    }
  };
};

export const fetchAllBusinessUnits24hReport = (timestamp) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ATTEMPT,
      });
      const dayBegin = timestamp
        ? moment(timestamp).tz("Europe/Belgrade").startOf("day").format("x")
        : moment().tz("Europe/Belgrade").startOf("day").format("x");
      const dayEnd = timestamp
        ? moment(timestamp).tz("Europe/Belgrade").endOf("day").format("x")
        : moment().tz("Europe/Belgrade").endOf("day").format("x");
      const { data } = await userReportService.fetchAllBusinessUnitsReport(
        dayBegin,
        dayEnd,
        "hours",
        false
      );
      const { array, businessUnitsNames } = getBusinessUnitsGraphReport(data);
      const report = {
        array,
        businessUnitsNames,
      };
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_24H_REPORT_SUCCESS,
        payload: report,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ALL_BUSINESS_UNITS_24H_REPORT_ERROR,
        payload: err,
      });
    }
  };
};
