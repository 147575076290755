import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { FETCH_TAXPAYERS } from "../../urls";

class TaxpayersService {
  fetchTaxpayers = async (page, perPage, term = "") => {
    try {
      const resposne = await apiBase.get(FETCH_TAXPAYERS(page, perPage, term));
      if (resposne.status !== 200) throw new Error();
      const { data } = resposne.data;
      return { data };
    } catch (err) {
      throw handleError(err, "Error fetching taxpayers");
    }
  };
}

export default new TaxpayersService();
