import {
  FETCH_TAXPAYERS_ATTEMPT,
  FETCH_TAXPAYERS_ERROR,
  FETCH_TAXPAYERS_SUCCESS,
} from "../../actionTypes/actionTypes";

const INIT_STATE = {
  taxpayers: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    page: 1,
    perPage: 15,
    total: null,
    term: "",
  },
};

const taxpayersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TAXPAYERS_ATTEMPT: {
      return {
        ...state,
        taxpayers: {
          ...state.taxpayers,
          attempt: true,
        },
      };
    }
    case FETCH_TAXPAYERS_ERROR: {
      return {
        ...state,
        taxpayers: {
          ...state.taxpayers,
          attempt: false,
          error: action.payload.message,
        },
      };
    }
    case FETCH_TAXPAYERS_SUCCESS: {
      const { page, perPage, search, data, total } = action.payload.data;
      return {
        ...state,
        taxpayers: {
          ...state.taxpayers,
          page: page,
          perPage: perPage,
          search: search,
          data: page === 1 ? [...data] : [...state.taxpayers.data, ...data],
          attempt: false,
          success: true,
          error: false,
          total: total,
        },
      };
    }
    default:
      return state;
  }
};

export default taxpayersReducer;
