import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import styles from "./CompanyInformationScreen.module.scss";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  postCompanyInfoReset,
  postCompanyInformation,
} from "../../store/actions/auth";

const CompanyInformationScreen = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [tin, setTin] = useState("");
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();

  const postCompanyInfo = useSelector((state) => state.auth.postCompanyInfo);
  const { success, attempt, error } = postCompanyInfo || [];

  const validateInput = () => {
    if (name === "") {
      toast.warning("Name cannot be empty");
      return false;
    }
    if (tin === "") {
      toast.warning("PIB cannot be empty");
      return false;
    }
    if (address === "") {
      toast.warning("Address cannot be empty");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      toast.info("Registration successfull");
      dispatch(postCompanyInfoReset());
      history.push("/dashboard");
    }
  }, [success, dispatch]);

  const onSubmit = () => {
    if (!validateInput()) return;
    dispatch(
      postCompanyInformation({
        company: {
          name,
          address: {
            name: address,
          },
          PIB: tin,
          businessUnits: [],
        },
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.gridHalves}>
        <div className={styles.contentWrapper}>
          <p className={styles.title}>Informacije o firmi</p>
          <div className={styles.formWrapper}>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Naziv firme</p>
                <Input name={"Ime"} onChange={setName} value={name} />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>PIB</p>
                <Input name={"Ime"} onChange={setTin} value={tin} />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Adresa</p>
                <Input name={"Ime"} onChange={setAddress} value={address} />
              </div>
            </div>
            <div className={styles.continueButtonWrap} onClick={onSubmit}>
              <Button name="Nastavi" disabled={attempt} />
            </div>
            <p
              className={styles.skip}
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Preskoci
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformationScreen;
