import classNames from "classnames";
import React, { useEffect, useState } from "react";
import fiscalDeviceService from "../../../apis/services/admin/fiscalDeviceService";
import BaseModal from "../baseModal/BaseModal";
import styles from "./DoubleInvoicesModal.module.scss";

const DoubleInvoicesModal = ({ modalOpen, onClose, fiscalDeviceId }) => {
  const [attempt, setAttempt] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [count, setCount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const fetchInvoices = async () => {
    try {
      const { data } =
        await fiscalDeviceService.fetchFiscalDeviceDoubleInvoices(
          fiscalDeviceId,
          7000
        );
      setInvoices(data.groups);
      setTotalAmount(data.totalAmount);
      setCount(data.count);
    } catch (err) {
      console.log("err ", err);
    }
  };

  useEffect(() => {
    if (modalOpen) fetchInvoices();
  }, [modalOpen]);

  const rednerInovoices = () => {
    return invoices?.map((invoice) => {
      const diff =
        new Date(invoice?.prev.createdAt).getTime() -
        new Date(invoice?.next.createdAt).getTime();
      return (
        <div className={styles.groupWrap}>
          <p>{`Razlika u vremenu ${diff / 1000}s`}</p>
          <div className={classNames(styles.invoiceItem, styles.border)}>
            <p>{invoice.prev.invoiceNumber}</p>
            <p>{invoice.prev.createdAt}</p>
            <p>{invoice.prev.totalAmount}</p>
          </div>
          <div className={styles.invoiceItem}>
            <p>{invoice.next.invoiceNumber}</p>
            <p>{invoice.next.createdAt}</p>
            <p>{invoice.next.totalAmount}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <BaseModal modalOpen={modalOpen} onClose={onClose}>
      <p>Dupli računi</p>
      <p>{`Ukupno računa ${count}`}</p>
      <p>{`Ukupan iznos ${totalAmount}`}</p>
      <div className={styles.listWrap}>{rednerInovoices()}</div>
    </BaseModal>
  );
};

export default DoubleInvoicesModal;
