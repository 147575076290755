import classNames from 'classnames';
import React, { useRef } from 'react';
import styles from './Input.module.scss'

const Input = ({ value, placeholder, type, onChange, className, disabled, onFocus }) => {
  const input = useRef();

  const handleChange = event => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div className={classNames(styles.customField)}>
      <input
        type={type}
        ref={input}
        value={value}
        placeholder="&nbsp;"
        onChange={handleChange}
        disabled={disabled}
        onFocus={onFocus}
        className={styles.input}
      />
      <span onClick={() => input.current.focus()} className={styles.placeholder}>
        {placeholder}
      </span>
    </div>
  );
};

export default Input;