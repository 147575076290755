import React from "react";
import styles from "./SelectItem.module.scss";
import { CloseIcon } from "../../assets";

const SelectItem = ({ title, onDelete }) => {
  return (
    <div className={styles.wrap}>
      <p className={styles.title}>{title}</p>
      <img
        src={CloseIcon}
        className={styles.closeIcon}
        alt="close"
        onClick={onDelete}
      />
    </div>
  );
};

export default SelectItem;
