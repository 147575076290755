import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import styles from "./NewPassword.module.scss";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUpdatePasswordInfo,
  updatePassword,
} from "../../../store/actions/auth";
import { useHistory } from "react-router-dom";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [passwrodConfirm, setPasswordConfirm] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const verifyPasswordResetCodeInfo = useSelector(
    (state) => state.auth.verifyPasswordResetCodeInfo
  );
  const updatePasswordInfo = useSelector(
    (state) => state.auth.updatePasswordInfo
  );

  const requestPasswordResetInfo = useSelector(
    (state) => state.auth.requestPasswordResetInfo
  );
  const { email } = requestPasswordResetInfo || [];

  const { code } = verifyPasswordResetCodeInfo || [];
  const { attempt, success, error } = updatePasswordInfo;

  const validateInput = () => {
    if (password === "") {
      toast.warning("Password cannot be empty!");
      return false;
    }
    if (password !== passwrodConfirm) {
      toast.warning("Passwords don't match");
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (!validateInput()) return;
    dispatch(updatePassword({ newPassword: password, code: code + "", email }));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetUpdatePasswordInfo());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (success) {
      toast.info("Password changed");
      dispatch(resetUpdatePasswordInfo());
      history.push("/login");
    }
  }, [success, dispatch, history]);

  return (
    <div className={styles.container}>
      <div className={styles.gridHalves}>
        <div className={styles.contentWrapper}>
          <p className={styles.title}>Unesite novu sifru</p>
          <div className={styles.formWrapper}>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Lozinka</p>
                <Input
                  name={"Lozinka"}
                  onChange={setPassword}
                  type={"password"}
                  value={password}
                />
              </div>
            </div>
            <div className={styles.alignRowJustifyBetween}>
              <div className={styles.inputFullWidthWrapper}>
                <p>Potvrda lozinke</p>
                <Input
                  name={"Potvrda lozinke"}
                  onChange={setPasswordConfirm}
                  type={"password"}
                  value={passwrodConfirm}
                />
              </div>
            </div>
            <div className={styles.continueButtonWrap}>
              <Button name="Nastavi" onClick={onSubmit} disabled={attempt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
